document.addEventListener("DOMContentLoaded", () => {
    const profile = document.getElementById("profile");

    if (!profile) {
        return;
    }

    const descriptionWrapper = profile.querySelector(".description-wrapper");

    if (!descriptionWrapper) {
        return;
    }

    const translate = profile.querySelector(".translate");

    if (!translate) {
        return;
    }

    const buttonTranslate = translate.querySelector("button");
    const spinner = translate.querySelector(".spinner");

    const description = descriptionWrapper.querySelector(".description");
    const descriptionFull =
        descriptionWrapper.querySelector(".description-full");

    buttonTranslate.addEventListener("click", (e) => {
        e.preventDefault();

        if (buttonTranslate.disabled === true) {
            return;
        }

        //show spinner
        spinner.classList.remove("d-none");
        buttonTranslate.disabled = true; // disable button until finished

        const userId = buttonTranslate.dataset.userId;
        const toggleDescription = buttonTranslate.dataset.toggleDescription;
        const toggleDescriptionFull =
            buttonTranslate.dataset.toggleDescriptionFull;

        // toggle translation and original
        if (toggleDescription !== undefined) {
            buttonTranslate.dataset.toggleDescription = description.textContent;
            buttonTranslate.dataset.toggleDescriptionFull =
                descriptionFull.textContent;

            description.textContent = toggleDescription;
            descriptionFull.textContent = toggleDescriptionFull;

            buttonTranslate
                .querySelector(".text-translate")
                .classList.toggle("d-none");
            buttonTranslate
                .querySelector(".text-original")
                .classList.toggle("d-none");
            translate.querySelector(".disclaimer").classList.toggle("d-none");

            spinner.classList.add("d-none");
            buttonTranslate.disabled = false;

            return;
        }

        fetch("/website-api/user/" + userId + "/description/translate/", {
            credentials: "same-origin",
            method: "get",
        })
            .then((res) => res.json())
            .then(function (json) {
                if (json.metadata.code === 406) {
                    // Target language invalid
                    alert(json.metadata.error_message);
                    return;
                }

                // Store original descriptions in dataset
                buttonTranslate.dataset.toggleDescription =
                    description.textContent;
                buttonTranslate.dataset.toggleDescriptionFull =
                    descriptionFull.textContent;

                description.textContent =
                    json.data.translated_description_truncated;
                descriptionFull.textContent = json.data.translated_description;

                buttonTranslate
                    .querySelector(".text-translate")
                    .classList.toggle("d-none");
                buttonTranslate
                    .querySelector(".text-original")
                    .classList.toggle("d-none");
                translate
                    .querySelector(".disclaimer")
                    .classList.remove("d-none");

                spinner.classList.add("d-none");
                buttonTranslate.disabled = false;
            });
    });
});
