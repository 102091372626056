import {EventSourcePolyfill} from 'event-source-polyfill';
import jwt_decode from "jwt-decode";

document.addEventListener('DOMContentLoaded', () => {
    const conversation = document.getElementById('conversation');

    if (!conversation) {
        return;
    }

    const messageListsScrollContent = conversation.querySelector(
        ".message-list .scroll-content"
    );

    if (!messageListsScrollContent) {
        return;
    }

    const submitMessageForm = document.getElementById("submit-message-form");
    const textarea = submitMessageForm.querySelector("textarea");
    const messagesWrapper = document.querySelector(".messages-wrapper");
    const scrollElement = document.querySelector(".scroll-content");

    let isAbortedConnection = false;
    let evtSource = null;

    const subscribeURL = new URL(APP_PROPS.mercure_public_url);
    const subscribeToken = APP_PROPS.subscribe_key;
    subscribeURL.searchParams.append("topic", submitMessageForm.dataset.subscribeTopic);

    // Restore user typed text if the page has been reloaded.
    if (localStorage.messageContent) {
        textarea.value = window.localStorage.messageContent;
        localStorage.removeItem("messageContent");
    }

    // Start connection.
    subscribeToTopic();

    function subscribeToTopic() {
        evtSource = new EventSourcePolyfill(subscribeURL, {
            headers: {
                'Authorization': 'Bearer ' + subscribeToken,
            }
        });

        evtSource.onopen = function () {
            if (isAbortedConnection === true) {
                // Save user text before the page is reloaded.
                localStorage.messageContent = textarea.value;

                location.reload();
            }
        };

        evtSource.onmessage = ({data}) => {
            const parsed = JSON.parse(data);

            setTimeout(() => {
                // Check if Ajax already delivered this message
                if (hasAlreadyMessage(parsed.message_id)) {
                    return;
                }

                fetchAndRenderMessages(parsed.message_id);
            }, 3000);
        };

        evtSource.onerror = (e) => {
            evtSource.close();

            const {exp} = jwt_decode(subscribeToken);
            const currentTime = new Date().getTime() / 1000;

            //Token is expired
            if (currentTime > exp) {
                location.reload();

                return;
            }

            //Start reconnect attempts
            startResubscribing();
        };
    }

    function startResubscribing() {
        isAbortedConnection = true;

        setInterval(() => {
            if (evtSource.readyState === EventSource.CLOSED) {
                console.log("Reconnecting...");
                subscribeToTopic();
            }
        }, 60000); // Try to reconnect after every 60 seconds.
    }

    function fetchAndRenderMessages(messageId) {
        fetch(`/website-api/inbox/message/${messageId}/`, {
            credentials: "same-origin",
            method: "get",
        })
            .then((res) => res.json())
            .then(function (json) {
                if (json.metadata.code !== 200) {
                    // Target language invalid
                    alert(json.metadata.error_message);
                    return;
                }

                if (hasAlreadyMessage(messageId)) {
                    return;
                }

                messagesWrapper.insertAdjacentHTML(
                    "afterbegin",
                    json.data.rendered
                );
                scrollElement.scrollTop = scrollElement.scrollHeight;
            });
    }

    function hasAlreadyMessage(messageId) {
        return messagesWrapper.querySelector(
            ".message-container[data-id='" + messageId + "']"
        );
    }

    // Close connection when user leaves the page
    window.addEventListener(
        "beforeunload",
        function (e) {
            evtSource.close();
        },
        false
    );
});
