const getParentElement = () => {
    const sitterWizard = document.getElementById('sitter-wizard');

    if (sitterWizard) {
        return sitterWizard;
    }

    return document.getElementById('parent-wizard');
}

const showLoader = (parentElement) => {

    const nextStepText = parentElement.querySelector('.nextStepText');
    const nextStepLoader = parentElement.querySelector('.nextStepLoader');

    if (nextStepText) {
        nextStepText.classList.add('d-none');
    }

    if (nextStepLoader) {
        nextStepLoader.classList.remove('d-none');
    }
}

const hideLoader = (parentElement) => {

    const nextStepText = parentElement.querySelector('.nextStepText');
    const nextStepLoader = parentElement.querySelector('.nextStepLoader');

    if (nextStepText) {
        nextStepText.classList.remove('d-none');
    }

    if (nextStepLoader) {
        nextStepLoader.classList.add('d-none');
    }
}

export const sendData = (url, formData, onSuccess, onError) => {

    const parentElement = getParentElement();

    showLoader(parentElement);

    fetch(url, {
        credentials: "same-origin",
        method: "post",
        body: formData,
    })
        .then((response) => {

            hideLoader(parentElement);

            if (response.status === 429) {
                alert("We're sorry, but you have sent too many requests to us recently. Please try again later.");

                throw new Error("Too many requests");
            }

            return response.json();

        })
        .then(function (data) {

            if (data.metadata.code !== 200 && data.metadata.code !== 201) {
                alert(data.metadata.error_message);

                if (onError !== undefined) {
                    onError();
                }

                return;
            }

            if (onSuccess !== undefined) {
                onSuccess();
            }
        });
}

export const goToStep = (stepUrl) => {
    const parentElement = getParentElement();

    const nextStepText = parentElement.querySelector('.nextStepText');
    const nextStepLoader = parentElement.querySelector('.nextStepLoader');

    if (nextStepText) {
        nextStepText.classList.add('d-none');
    }

    if (nextStepLoader) {
        nextStepLoader.classList.remove('d-none');
    }

    window.location.href = stepUrl;
}

export const showError = () => {
    const parentElement = getParentElement();
    const error = parentElement.querySelector('.wizard-error');

    if (error) {
        error.classList.remove('d-none');
    }

    /* Scroll to top of document */
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
}
export const showGroupError = (groupParentElement) => {
    const errorWrapper = groupParentElement.querySelector('.wizard-group-error');

    const toElement = groupParentElement.offsetTop - 100;

    window.scrollTo({
        top: toElement,
        left: 0,
        behavior: "smooth",
    });

    errorWrapper.classList.remove('d-none');
}

export const hideAllErrors = () => {
    const parentElement = getParentElement();
    const error = parentElement.querySelector('.wizard-error');
    const groupErrors = parentElement.querySelectorAll('.wizard-group-error');

    if (error) {
        error.classList.add('d-none');
    }

    groupErrors.forEach((errorWrapper) => {
        errorWrapper.classList.add('d-none');
    })
}
