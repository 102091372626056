import dayjs from "dayjs";

document.addEventListener("DOMContentLoaded", () => {
    const conversation = document.getElementById("conversation");

    if (!conversation) {
        return;
    }

    /**
     * Conversation message:
     * - if the user viewed the message today (local time), than display the time the user viewed the message
     * - if the user viewed the message 6 days or less ago, than display the day the user viewed the message
     * - if longer ago: display the date
     *
     * All datetimes will be displayed in browser local time
     */
    const readTimes = conversation.querySelectorAll(
        ".message-list .message-status .read-time",
    );

    readTimes.forEach((readTime) => {
        const datetime = readTime.getAttribute("datetime");

        const dayJsDatetime = dayjs(datetime);
        const dayJsToday = dayjs().startOf("day");

        if (dayJsDatetime >= dayJsToday) {
            const intlDateTime = new Intl.DateTimeFormat(undefined, {
                timeStyle: "short",
            });

            readTime.querySelector(".displayed-time").innerHTML =
                intlDateTime.format(dayJsDatetime.toDate()); //example 8.02 PM

            return;
        }

        const dayjs6DaysAgo = dayjs().subtract(6, "day");

        if (dayJsDatetime >= dayjs6DaysAgo) {
            const intlDateTime = new Intl.DateTimeFormat(undefined, {
                weekday: "long",
            });

            readTime.querySelector(".displayed-time").innerHTML =
                intlDateTime.format(dayJsDatetime.toDate()); // example: monday

            return;
        }

        readTime.querySelector(".displayed-time").innerHTML =
            new Intl.DateTimeFormat(undefined, { dateStyle: "short" }).format(
                dayJsDatetime.toDate(),
            ); // example: 09/04/1986
    });
});
