document.addEventListener("DOMContentLoaded", () => {
    const search = document.getElementById("search");

    if (!search) {
        return;
    }

    const specialNeedsInputAll = search.querySelectorAll(
        ".special-needs input"
    );
    const specialNeedsTypesAll = search.querySelectorAll(
        ".special-needs-types"
    );

    specialNeedsInputAll.forEach(function (
        specialNeedsInput
    ) {
        specialNeedsInput.addEventListener("change", (event) => {
            if (specialNeedsInput.checked) {
                specialNeedsTypesAll.forEach(function (specialNeedsTypes) {
                    specialNeedsTypes.classList.remove("d-none");
                });

                return;
            }

            const queryBuilder = new URLSearchParams(
                window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D")
            );

            /* If any special need type is selected, than return here to prevent from hiding all special need types */
            if (queryBuilder.get("special_needs_types[]")) {
                return;
            }

            specialNeedsTypesAll.forEach(function (specialNeedsTypes) {
                specialNeedsTypes.classList.add("d-none");
            });
        });
    });
});
