document.addEventListener("DOMContentLoaded", () => {
    const signupComplete = document.getElementById("signup-complete");

    if (!signupComplete) {
        return;
    }

    const buttonGoToStep2 = signupComplete.querySelector(".go-to-step-2");

    buttonGoToStep2.addEventListener("click", (e) => {
        const step1 = signupComplete.querySelector(".step-1");
        const step2 = signupComplete.querySelector(".step-2");
        const isChecked = document.getElementById(
            "notification-promotional"
        ).checked;

        if (isChecked) {
            let body = "";
            const notificationTypes = [
                "message_email",
                "message_push",
                "reminder_email",
                "reminder_push",
                "promotion_email",
                "promotion_push",
                "support_push",
            ];

            notificationTypes.forEach((notificationType) => {
                body += "notification_types[]=" + notificationType + "&";
            });

            fetch("/website-api/account/notifications/", {
                credentials: "same-origin",
                method: "put",
                body: body,
                headers: {
                    "Content-Type":
                        "application/x-www-form-urlencoded; charset=UTF-8",
                },
            })
                .then((response) => response.json())
                .then(function (json) {});
        }

        step1.classList.add("d-none");
        step2.classList.remove("d-none");

        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    });
});
