document.addEventListener("DOMContentLoaded", () => {
    const toggleElement = document.querySelector("#header .navbar-toggle");
    const maskElement = document.querySelector("#header .nav-mask");
    const btnClose = document.querySelector("#header .button-close");

    if (!toggleElement || !maskElement || !btnClose) {
        return;
    }

    toggleElement.addEventListener("click", navBarToggle);

    function navBarToggle(e) {
        e.preventDefault();

        document.body.classList.add("navbarvisible");
    }

    maskElement.addEventListener("click", navMaskClick);
    btnClose.addEventListener("click", navMaskClick);

    function navMaskClick(e) {
        e.preventDefault();

        document.body.classList.remove("navbarvisible");
        document.body.classList.add("navbarhidden");

        // For smooth transitions between screensizes remove narbarhidden class after 0.4 seconds
        setTimeout(function () {
            document.body.classList.remove("navbarhidden");
        }, 400);
    }

});
