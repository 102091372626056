document.addEventListener("DOMContentLoaded", () => {
    const profile = document.getElementById("profile");

    if (!profile) {
        return;
    }

    const pushContactReminders = profile.querySelectorAll(".push-contact-reminder");

    if (!pushContactReminders) {
        return;
    }

    /**
     * If a user clicks on this button, we will add a record to our database.
     * After 24 hours the user receives an email about Upgrading to Premium
     */
    pushContactReminders.forEach((pushContactReminder) => {
        pushContactReminder.addEventListener("click", (e) => {
            fetch("/website-api/user/contact-reminder/", {
                credentials: "same-origin",
                method: "post"
            });
        });
    });
});
