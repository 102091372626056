document.addEventListener("DOMContentLoaded", () => {
    /**
     * Show a "Ready to book" alert below the message-input if a user enters in the conversation numbers, the @-sign or any of the words below
     */
    const conversation = document.getElementById("conversation");

    if (!conversation) {
        return;
    }

    const textarea = conversation.querySelector(".message-form textarea");

    if (!textarea) {
        return;
    }

    if (textarea) {
        textarea.addEventListener("input", (e) => {
            const userInput = e.target.value;

            if (
                userInput.match(
                    /([\d@]|skype|facebook|instagram|twitter|whatsap|viber|telegram|wechat|snapchat|kakao|messenger|hangouts|voxer|heytell|slack)/i
                )
            ) {
                const alertReadyToBook = conversation.querySelector(
                    ".alert-ready-to-book"
                );
                const informationCard = conversation.querySelector(
                    ".bookings-information .card"
                );

                alertReadyToBook.classList.remove("d-none");

                if (informationCard) {
                    informationCard.classList.remove("border-light");
                    informationCard.classList.add(
                        "border",
                        "border-success",
                        "shadow"
                    );
                }
            }
        });
    }
});
