document.addEventListener("DOMContentLoaded", () => {
    const profileContact = document.getElementById("profile-contact");

    if (!profileContact) {
        return;
    }

    const viewExamples = profileContact.querySelector(
        ".viewExamples"
    );

    const examples = profileContact.querySelector(
        ".examples"
    );

    viewExamples.addEventListener("click", (e) => {
        examples.classList.toggle("d-none");

        if (!examples.classList.contains("d-none")) {
            viewExamples.scrollIntoView({block: "center"});
        }
    });
});
