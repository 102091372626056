import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";
import { showElement, hideElement } from "./utils";

export default class extends Controller {
    static values = {
        country: String,
        lat: String,
        lng: String,
        user: String,
        selected: String,
    };
    static targets = [
        "input",
        "loader",
        "error",
        "resultsContainer",
        "addressResults",
        "addressEditBtn",
        "cityEditBtn",
        "addressEditCancelBtn",
    ];

    edit() {
        this.inputTarget.disabled = false;
        this.inputTarget.value = "";

        showElement(this.addressEditCancelBtnTarget);
        hideElement(this.addressEditBtnTarget);
        hideElement(this.cityEditBtnTarget);
    }

    cancel() {
        this.inputTarget.disabled = true;
        this.inputTarget.value = this.selectedValue;
        this.inputTarget.classList.remove("error-displayed");

        hideElement(this.errorTarget);

        hideElement(this.addressEditCancelBtnTarget);
        showElement(this.addressEditBtnTarget);
        showElement(this.cityEditBtnTarget);
    }

    search(e) {
        const query = e.currentTarget.value;
        const controller = new AbortController();
        const signal = controller.signal;

        if (!query || !query.trim()) {
            return;
        }

        if (this.prevController) {
            this.prevController.abort();
        }

        const url = `/website-api/area/address/?q=${query}&proximity=${
            this.latValue + "," + this.lngValue
        }&country_iso=${this.countryValue}`;
        this.prevController = controller;

        showElement(this.loaderTarget);

        let debounceFetch = debounce(this.fetchData, 400);

        debounceFetch(url, signal, this);
    }

    addAddress(e) {
        e.preventDefault();

        hideElement(this.resultsContainerTarget);
        showElement(this.loaderTarget);

        const formData = new FormData();
        formData.append("feature_name", e.target.dataset.title);
        formData.append("feature_id", e.target.dataset.id);
        formData.append("provider_name", e.target.dataset.provider);
        formData.append("view_id", this.userValue);

        fetch("/website-api/user/address/", {
            credentials: "same-origin",
            method: "post",
            body: formData,
        })
            .then((response) => {
                if (response.status === 401) {
                    window.location.href = "/";
                }
                if (response.status === 403) {
                    throw new Error(
                        "Request forbidden. If you are using an anonymous proxy or VPN you'll need to disable it to fully access Babysits."
                    );
                }

                if (response.status === 429) {
                    throw new Error(
                        "Too many requests. Please try again later."
                    );
                }

                if (response.status === 500) {
                    throw new Error(
                        "Service unavailable. Please try again or contact customer service."
                    );
                }

                return response.json();
            })
            .then((data) => {

                if (data.metadata.code === 400) {
                    hideElement(this.loaderTarget);
                    // HTTP_BAD_REQUEST: something went wrong geocoding
                    alert(data.metadata.error_message);

                    return;
                }

                if (data.metadata.code === 422) {
                    hideElement(this.loaderTarget);
                    // Feature id does not have city or country.
                    alert("Input errors.");

                    return;
                }

                if (data.metadata.code === 200) {
                    hideElement(this.errorTarget);

                    fetch(
                        "/website-api/user/address/?view_id=" + this.userValue,
                        {
                            credentials: "same-origin",
                            method: "get",
                        }
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            hideElement(this.loaderTarget);
                            if (data.metadata.code === 401) {
                                window.location.href = "/";
                            }

                            if (data.metadata.code === 200) {
                                this.inputTarget.value = data.data.address_title
                                this.inputTarget.disabled = true;
                                this.inputTarget.dataset.id =
                                    e.target.dataset.id;
                                this.inputTarget.classList.remove(
                                    "error-displayed"
                                );

                                this.selectedValue = this.inputTarget.value;

                                hideElement(this.addressEditCancelBtnTarget);
                                showElement(this.addressEditBtnTarget);
                                showElement(this.cityEditBtnTarget);

                                this.countryValue = data.data.address_country;

                                this.manageCountrySpecificElements(
                                    data.data.address_country
                                );

                                this.manageCity(
                                    data.data.city_title,
                                    data.data.address_country
                                );
                            }
                        });
                }
            })
            .catch((error) => {
                alert(error);
            });
    }

    hideResults(e) {
        if (e.relatedTarget && e.relatedTarget.dataset.type === "href-action") {
            return;
        }

        hideElement(this.resultsContainerTarget);
        showElement(this.errorTarget);
    }

    showResults(data) {
        showElement(this.resultsContainerTarget);

        let linkCollections = "";

        for (const address of data) {
            /* Add tabindex to a element to make relatedTarget work in Safari */
            linkCollections += `<a data-id="${address.id}" data-title="${address.title}" data-provider="${address.provider}" data-type="href-action" data-action="click->address#addAddress" tabindex="0">${address.title}</a>`;
        }

        this.addressResultsTarget.innerHTML = linkCollections;
    }

    toggleCity(e) {
        const babysitsCityBlock = document.getElementById(
            "babysits-city-block"
        );

        babysitsCityBlock.classList.toggle("d-none");
    }

    showCity(e) {
        const babysitsCityBlock = document.getElementById(
            "babysits-city-block"
        );

        babysitsCityBlock.classList.remove("d-none");
    }

    hideCity(e) {
        const babysitsCityBlock = document.getElementById(
            "babysits-city-block"
        );

        babysitsCityBlock.classList.add("d-none");
    }

    fetchData(url, signal, self) {
        //hideElement(self.resultsContainerTarget);

        fetch(url, { signal })
            .then((response) => {
                if (response.status === 401) {
                    window.location.href = "/";
                }
                if (response.status === 429) {
                    hideElement(self.loaderTarget);
                    alert("We're sorry, but you have sent too many requests to us recently. Please try again later.");

                    throw new Error("Too many requests");
                }
                if (response.status === 403) {
                    hideElement(self.loaderTarget);
                    alert("Request forbidden. If you are using an anonymous proxy or VPN you'll need to disable it to fully access Babysits.");

                    throw new Error("Anonymous proxy or VPN");
                }

                if (response.status >= 500) {
                    hideElement(self.loaderTarget);
                    alert("We ran into a problem. Please try again.");

                    throw new Error("500 problem.");
                }

                return response.json();
            })
            .then((json) => {
                hideElement(self.loaderTarget);

                if (!json.data.length) {
                    return;
                }

                self.showResults(json.data);
            })
            .catch((e) => {});
    }

    getCityController() {
        return this.application.getControllerForElementAndIdentifier(
            document.querySelector(".edit-city-wrapper"),
            "city"
        );
    }

    manageCity(cityTitle, addressCountry) {
        const cityController = this.getCityController();

        cityController.inputTarget.classList.remove("error-displayed");
        cityController.countryValue = addressCountry;

        if (!cityTitle) {
            if (this.hasCityEditBtnTarget) {
                hideElement(this.cityEditBtnTarget);
            }

            cityController.selectedValue = "";
            cityController.inputTarget.dataset.id = "";

            cityController.edit();

            this.showCity();

            return;
        }

        this.hideCity();

        cityController.selectedValue = cityTitle;
        cityController.inputTarget.value = cityTitle;
        cityController.inputTarget.disabled = true;

        cityController.cancel();
    }

    manageCountrySpecificElements(addressCountry) {
        /* Hide any country specific elements */
        const elementsForAllCountries = document.querySelectorAll(
            ".element-for-country"
        );
        elementsForAllCountries.forEach((elementForCountry) => {
            elementForCountry.style.display = "none";
        });

        /* Show element for this specific country */
        const elementsForCountry = document.querySelectorAll(
            ".element-for-country-" + addressCountry.toLowerCase()
        );
        elementsForCountry.forEach((elementForCountry) => {
            elementForCountry.style.display = "block";
        });
    }
}
