document.addEventListener("DOMContentLoaded", () => {
    const cookieConsentBanner = document.getElementById(
        "cookie-consent-banner",
    );

    if (!cookieConsentBanner) {
        return;
    }

    const accept = cookieConsentBanner.querySelector(".accept");
    const decline = cookieConsentBanner.querySelector(".decline");

    accept.addEventListener("click", (e) => {
        const hostnameBase = window.location.hostname.replace(/^[^.]*./, "");

        document.cookie =
            "cookie_consent=true; max-age=31536000; path=/; domain=" +
            hostnameBase +
            "; secure; samesite=lax";

        cookieConsentBanner.style.display = "none";

        // Give consent to the facebook/meta pixel if defined
        try {
            fbq("consent", "grant");
        } catch (e) {}

        // Give consent to google ads if defined
        try {
            window.dataLayer = window.dataLayer || [];
            /**
             * This is a custom event which was setup through tagmanager.google.com
             * https://tagmanager.google.com/#/container/accounts/6006110073/containers/60006331/workspaces/33/triggers
             */
            dataLayer.push({ event: "cookie-consent" });
        } catch (e) {}
    });
    decline.addEventListener("click", (e) => {
        const hostnameBase = window.location.hostname.replace(/^[^.]*./, "");

        document.cookie =
            "cookie_consent_declined=true; max-age=31536000; path=/; domain=" +
            hostnameBase +
            "; secure; samesite=lax";

        cookieConsentBanner.style.display = "none";

        // Revoke consent to the facebook/meta pixel if defined
        try {
            fbq("consent", "revoke");
        } catch (e) {}

        // Decline consent to google ads if defined
        try {
            window.dataLayer = window.dataLayer || [];
            /**
             * This is a custom event which was setup through tagmanager.google.com
             * https://tagmanager.google.com/#/container/accounts/6006110073/containers/60006331/workspaces/33/triggers
             */
            dataLayer.push({ event: "cookie-consent-decline" });
        } catch (e) {}
    });
});
