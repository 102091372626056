import autosize from "autosize";

document.addEventListener("DOMContentLoaded", () => {
    const conversation = document.getElementById("conversation");

    if (!conversation) {
        return;
    }

    const cannedResponses = conversation.querySelectorAll(".canned-responses button");

    if (!cannedResponses) {
        return;
    }

    const submitMessageForm = document.getElementById("submit-message-form");
    const textarea = submitMessageForm.querySelector("textarea");

    cannedResponses.forEach((cannedResponse) => {
        cannedResponse.addEventListener("click", (e) => {
            textarea.value = cannedResponse.dataset.content;

            autosize.update(textarea);

            cannedResponses.forEach((cannedResponse) => {
                cannedResponse.classList.remove('active');
            });

            cannedResponse.classList.add('active');
        });
    });

});
