document.addEventListener("DOMContentLoaded", () => {
    /* START General test which gives 50% of the users 1 variant and the other 50% the other variant*/
    const hostnameBase = window.location.hostname.replace(/^[^.]*./, "");

    // Check if test cookie is already set
    let setABTestBoolean = document.cookie
        .split("; ")
        .find((row) => row.startsWith("ab_test_boolean="))
        ?.split("=")[1];

    // If no test cookie set random true or false
    if (!setABTestBoolean) {
        setABTestBoolean = Math.round(Math.random()) === 1 ? 'true' : 'false';
    }

    if (setABTestBoolean === 'true')
    {
        document.cookie =
            "ab_test_boolean=true; max-age=2592000; path=/; domain=" +
            hostnameBase +
            "; secure; samesite=lax";

        const abTestsBoolean = document.querySelectorAll(".ab-test-boolean");

        abTestsBoolean.forEach((abTestBoolean) => {
            abTestBoolean.classList.remove('d-none');
        });
    } else {
        document.cookie =
            "ab_test_boolean=false; max-age=2592000; path=/; domain=" +
            hostnameBase +
            "; secure; samesite=lax";
    }
    /* END General test which gives 50% of the users 1 variant and the other 50% the other variant*/

});
