document.addEventListener("DOMContentLoaded", () => {
    const drivingLicense = document.getElementById('driving-license');
    const car = document.getElementById('car');
    const carWrapper = document.getElementById('car-wrapper');

    if (!drivingLicense || !car || !carWrapper) {
        return;
    }

    drivingLicense.addEventListener('click', () => {
        if (drivingLicense.checked) {
            carWrapper.classList.remove('d-none');
            car.disabled = false;
        } else {
            carWrapper.classList.add('d-none');
            car.disabled = true;
            car.checked = false;
        }
    });

});
