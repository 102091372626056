document.addEventListener("DOMContentLoaded", () => {
    const documentForms = document.querySelectorAll(
        ".document-form"
    );

    documentForms.forEach((documentForm) => {
        const buttonDelete = documentForm.querySelector(".button-delete");

        buttonDelete.addEventListener("click", function (e) {
            const userId = buttonDelete.dataset.userId;
            const documentId = buttonDelete.dataset.documentId;
            const documentAdd = documentForm.querySelector(".document-add");
            const documentInReview = documentForm.querySelector(".document-in-review");

            const spinner = buttonDelete.querySelector(".spinner");
            spinner.classList.remove("d-none");

            fetch(`/website-api/users/${userId}/documents/${documentId}/`, {
                credentials: "same-origin",
                method: "delete",
            })
                .then((response) => {
                    if (response.status === 401) {
                        throw new Error("Unauthorized");
                    }
                    if (response.status === 429) {
                        throw new Error("We're sorry, but you have sent too many requests to us recently. Please try again later.");
                    }
                    if (response.status === 500) {
                        throw new Error("Service is unavailable");
                    }

                    return response.json();
                })
                .then(function (jsonResponse) {
                    if (jsonResponse.metadata.code >= 400) {
                        if (jsonResponse.metadata.errors) {
                            alert(jsonResponse.metadata.errors[0].error);

                            return;
                        }

                        if (jsonResponse.metadata.error_message) {
                            alert(jsonResponse.metadata.error_message);

                            return;
                        }

                        alert(
                            "Something went wrong. Please contact customer service."
                        );

                        return;
                    }

                    documentAdd.style.display = "block";
                    documentInReview.style.display = "none";

                    spinner.classList.add("d-none");
                })
                .catch((error) => {
                    spinner.classList.add("d-none");
                    alert(error);
                });

        }, false);

    });
});
