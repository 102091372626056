document.addEventListener("DOMContentLoaded", () => {
    const homepage = document.getElementById("homepage");

    if (!homepage) {
        return;
    }

    const bodyTop = document.getElementById("body-top");
    const header = document.getElementById("header");
    const searchExpandable = header.querySelector(".search-expandable");
    const homepageTopMenu = header.querySelector(".homepage-top-menu");

    function handleIntersect(entries, observer) {
        // no intersection with screen
        if (entries[0].intersectionRatio === 0) {
            searchExpandable.classList.remove("d-none");
            homepageTopMenu.classList.add("d-none");

            // fully intersects with screen
        } else if (entries[0].intersectionRatio === 1) {
            homepageTopMenu.classList.remove("d-none");
            searchExpandable.classList.add("d-none");
        }
    }

    let observer = new IntersectionObserver(handleIntersect);

    observer.observe(bodyTop);
});
