document.addEventListener("DOMContentLoaded", () => {
    const conversation = document.getElementById("conversation");

    if (!conversation) {
        return;
    }

    const submitMessageForm = document.getElementById("submit-message-form");
    const textarea = submitMessageForm.querySelector("textarea");
    const inboxId = conversation.dataset.inboxId;

    const textareaCookieValue = document.cookie
        .split("; ")
        .find((row) => row.startsWith("conversation-" + inboxId + "-textarea="))
        ?.split("=")[1];

    /**
     * Display stored textarea value
     */
    if (textareaCookieValue && !textarea.value) {
        textarea.value = textareaCookieValue;
    }

    /**
     * Save textarea value to cookie, so we can put it back when the user returns to this page
     */
    const hostnameBase = window.location.hostname.replace(/^[^.]*./, "");
    textarea.addEventListener("keyup", (event) => {
        document.cookie =
            "conversation-" +
            inboxId +
            "-textarea=" +
            textarea.value +
            "; max-age=86400; path=/; domain=" +
            hostnameBase +
            "; secure; samesite=lax";
    });
});
