document.addEventListener("DOMContentLoaded", () => {
    /**
     * Format <time></time> HTML title to browser local time
     */
    const timeElements = document.querySelectorAll("time");

    timeElements.forEach((element) => {
        const dateTimeStr = element.getAttribute("datetime");
        const dateTime = new Date(dateTimeStr);

        const dateFormat = new Intl.DateTimeFormat(undefined, {
            dateStyle: "full",
            timeStyle: "short",
        }).format(dateTime);

        element.setAttribute("title", dateFormat);
    });
});
