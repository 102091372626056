document.addEventListener("DOMContentLoaded", () => {
    const conversation = document.getElementById("conversation");

    if (!conversation) {
        return;
    }

    const premiumOrFreemiumSelector = conversation.querySelector(
        ".premium-or-freemium-selector",
    );

    if (!premiumOrFreemiumSelector) {
        return;
    }

    const buttonPremium =
        premiumOrFreemiumSelector.querySelector(".button-premium");
    const buttonWatchAds =
        premiumOrFreemiumSelector.querySelector(".button-watch-ads");

    const radioConversationOptions = premiumOrFreemiumSelector.querySelectorAll(
        'input[name="conversation_option"]',
    );

    radioConversationOptions.forEach((radioConversationOption) => {
        radioConversationOption.addEventListener("change", (e) => {
            const selectedValue = premiumOrFreemiumSelector.querySelector(
                'input[name="conversation_option"]:checked',
            ).value;

            if (selectedValue === "premium") {
                buttonPremium.classList.remove("d-none");
                buttonWatchAds.classList.add("d-none");
            } else {
                buttonPremium.classList.add("d-none");
                buttonWatchAds.classList.remove("d-none");
            }
        });
    });

    const dialogUnlockFreemium = conversation.querySelector(
        ".dialog-unlock-freemium",
    );

    const ads = dialogUnlockFreemium.querySelector(".ads");
    const premiumPromotion =
        dialogUnlockFreemium.querySelector(".premium-promotion");
    const freemiumContact =
        dialogUnlockFreemium.querySelector(".freemium-contact");

    const adBabysitsPremium = ads.querySelector(".ad-babysits-premium");

    let timeoutNextScreen;

    buttonWatchAds.addEventListener("click", (e) => {
        ads.classList.remove("d-none");
        adBabysitsPremium.play();

        // Show continue button after timeout
        setTimeout(() => {
            const buttonContinue = ads.querySelector(".button-continue");
            buttonContinue.classList.remove("d-none");
        }, 16000);

        // Go to next view after timeout
        timeoutNextScreen = setTimeout(() => {
            adBabysitsPremium.pause();
            ads.classList.add("d-none");
            premiumPromotion.classList.remove("d-none");
        }, 20000);
    });

    const buttonContinuePremiumSellingpoints = ads.querySelector(
        ".button-continue-premium-sellingpoints",
    );

    buttonContinuePremiumSellingpoints.addEventListener("click", (e) => {
        clearTimeout(timeoutNextScreen);
        adBabysitsPremium.pause();
        ads.classList.add("d-none");
        premiumPromotion.classList.remove("d-none");
    });
});
