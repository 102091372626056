document.addEventListener("DOMContentLoaded", () => {
    const dialogAvailability = document.getElementById("dialog-availability");

    if (!dialogAvailability) {
        return;
    }

    const buttonSaveAvailability = dialogAvailability.querySelector('button.save-availability');
    const spinner = buttonSaveAvailability.querySelector('.spinner');
    const success = buttonSaveAvailability.querySelector('.success');

    buttonSaveAvailability.addEventListener('click', (event) => {
        event.preventDefault();

        buttonSaveAvailability.disabled = true;
        spinner.classList.remove('d-none');

        const availabilities = dialogAvailability.querySelectorAll('.availability input');
        const formData = new FormData();

        availabilities.forEach((checkbox) => {
            formData.append(checkbox.name, checkbox.checked);
        });

        fetch('/website-api/users/availability/', {
            credentials: "same-origin",
            method: "post",
            body: formData,
        })
            .then((response) => {
                buttonSaveAvailability.disabled = false;
                spinner.classList.add('d-none');

                if (response.status === 401) {
                    throw new Error("Unauthorized");
                }
                if (response.status === 429) {
                    throw new Error("We're sorry, but you have sent too many requests to us recently. Please try again later.");
                }
                if (response.status === 500) {
                    throw new Error("Service is unavailable");
                }

                return response.json();
            })
            .then(function (jsonResponse) {
                if (jsonResponse.metadata.code >= 400) {
                    if (jsonResponse.metadata.errors) {
                        alert(jsonResponse.metadata.errors[0].error);

                        return;
                    }

                    if (jsonResponse.metadata.error_message) {
                        alert(jsonResponse.metadata.error_message);

                        return;
                    }

                    alert(
                        "Something went wrong. Please contact customer service."
                    );

                    return;
                }

                success.classList.remove('d-none');

                setTimeout(function () {
                    dialogAvailability.close();
                }, 400);

            })
            .catch((error) => {
                alert(error);
            });
    });

});
