import {
    goToStep,
    hideAllErrors,
    sendData,
    showError,
    showGroupError,
} from "./wizzard-utils";

document.addEventListener("DOMContentLoaded", () => {
    const parentWizard = document.getElementById("parent-wizard");

    if (!parentWizard) {
        return;
    }

    const nextBtn = parentWizard.querySelector(".next-step-btn");

    const aboutYourFamily = parentWizard.querySelector(
        "#parent-wizard-about-your-family",
    );
    const babysittingNeeds = parentWizard.querySelector(
        "#parent-wizard-babysitting-needs",
    );
    const moreAboutYou = parentWizard.querySelector(
        "#parent-wizard-more-about-you",
    );
    const photo = parentWizard.querySelector("#parent-wizard-photo");
    const yourPreferences = parentWizard.querySelector(
        "#parent-wizard-your-preferences",
    );
    const suggestions = parentWizard.querySelector(
        "#sitters-wizard-suggestions",
    );

    if (suggestions) {
        const inactiveFavorites =
            suggestions.querySelectorAll(".favorite.inactive");
        inactiveFavorites.forEach((inactiveFavorite) => {
            const defaultIcon = inactiveFavorite.querySelector(".icon");
            const spinner = inactiveFavorite.querySelector(".spinner");

            defaultIcon.classList.add("d-none");
            spinner.classList.remove("d-none");

            setTimeout(function () {
                defaultIcon.classList.remove("d-none");
                spinner.classList.add("d-none");
            }, 5000);
        });
    }

    if (aboutYourFamily) {
        const groupWrapper = aboutYourFamily.querySelector(
            ".age-groups-wrapper",
        );

        nextBtn.addEventListener("click", (event) => {
            event.preventDefault();

            hideAllErrors();

            const firstName = parentWizard.querySelector("#first_name").value;

            if (!firstName) {
                showError();

                return;
            }

            const selectedAddress = aboutYourFamily
                .querySelector(".edit-address-wrapper")
                .getAttribute("data-address-selected-value");
            const selectedCity = document
                .getElementById("city-input")
                .getAttribute("data-city-selected-value");

            if (selectedAddress.length === 0) {
                showError();

                return;
            }

            if (selectedCity.length === 0) {
                showError();

                return;
            }

            const childrenAmount =
                aboutYourFamily.querySelector("#children_amount");
            const ageGroupCheckboxes = aboutYourFamily.querySelectorAll(
                ".age-groups input[type=checkbox]",
            );

            const formData = new FormData();

            let atLeastOneChecked = false;

            formData.append("children_amount", childrenAmount.value);

            ageGroupCheckboxes.forEach((checkbox) => {
                if (checkbox.checked) {
                    formData.append("age_groups[]", checkbox.value);
                    atLeastOneChecked = true;
                }
            });

            if (!atLeastOneChecked) {
                showGroupError(groupWrapper);
                return;
            }

            const characteristicsCheckboxes = aboutYourFamily.querySelectorAll(
                ".characteristics input[type=checkbox]:checked",
            );

            if (characteristicsCheckboxes.length < 3) {
                const characteristicsWrapper = aboutYourFamily.querySelector(
                    "#profile-edit-characteristics",
                );
                showGroupError(characteristicsWrapper);

                return;
            }

            characteristicsCheckboxes.forEach((checkbox) => {
                formData.append("characteristics[]", checkbox.value);
            });

            formData.append("first_name", firstName);

            sendData("/website-api/profile/", formData, () => {
                goToStep(nextBtn.dataset.url);
            });
        });
    }

    if (babysittingNeeds) {
        const comfortableWithButtons =
            babysittingNeeds.querySelectorAll(".comfortable-with");
        const atFamilyBtn = document.getElementById(
            "babysits-location-at-family",
        );
        const atMyPlace = document.getElementById(
            "babysits-location-at-my-place",
        );

        nextBtn.addEventListener("click", (event) => {
            event.preventDefault();

            hideAllErrors();

            const formData = new FormData();

            //Location
            const locationValue = atFamilyBtn.checked
                ? atFamilyBtn.dataset.type
                : atMyPlace.dataset.type;
            formData.append("babysit_location", locationValue);

            //Type babysits
            const typeBabysitCheckboxes = babysittingNeeds.querySelectorAll(
                ".type-babysit input[type=checkbox]",
            );
            let atLeastOneTypeBabysitChecked = false;

            typeBabysitCheckboxes.forEach((checkbox) => {
                if (checkbox.checked) {
                    formData.append("type_babysit[]", checkbox.value);
                    atLeastOneTypeBabysitChecked = true;
                }
            });

            if (!atLeastOneTypeBabysitChecked) {
                showError();

                return;
            }

            //Special needs
            const hasSpecialNeedsBtn =
                document.getElementById("special-needs-btn");
            formData.append("special_needs", hasSpecialNeedsBtn.checked);

            const specialNeedsTypes = babysittingNeeds.querySelectorAll(
                ".special-needs-types input[type=checkbox]",
            );

            specialNeedsTypes.forEach((checkbox) => {
                if (checkbox.checked) {
                    formData.append("special_needs_types[]", checkbox.value);
                }
            });

            //Availability
            const availabilities = babysittingNeeds.querySelectorAll(
                ".availability input",
            );
            let atLeastOneDayChecked = false;

            availabilities.forEach((checkbox) => {
                if (checkbox.checked) {
                    atLeastOneDayChecked = true;
                }
            });

            if (!atLeastOneDayChecked) {
                showError();
                return;
            }

            //Rate
            const rateInput = babysittingNeeds.querySelector("#rate_amount");

            if (!rateInput.value) {
                showError();

                return;
            }

            comfortableWithButtons.forEach((button) => {
                formData.append(button.dataset.type, button.checked);
            });

            const availabilityFormData = new FormData();
            availabilities.forEach((checkbox) => {
                if (checkbox.checked) {
                    availabilityFormData.append(
                        checkbox.name,
                        checkbox.checked,
                    );
                }
            });

            const rateFormData = new FormData();
            rateFormData.append("rate_amount", rateInput.value);

            sendData("/website-api/profile/", formData, () => {
                sendData(
                    "/website-api/users/availability/",
                    availabilityFormData,
                    () => {
                        sendData(
                            "/website-api/users/rate/",
                            rateFormData,
                            () => {
                                goToStep(nextBtn.dataset.url);
                            },
                        );
                    },
                );
            });
        });
    }

    if (moreAboutYou) {
        nextBtn.addEventListener("click", (event) => {
            event.preventDefault();

            hideAllErrors();

            const descriptionInput = document.getElementById("description");
            const value = descriptionInput.value.trim();
            const formData = new FormData();

            if (value.length < 200) {
                showError();
                return;
            }

            formData.append("description", value);

            sendData("/website-api/profile/", formData, () => {
                goToStep(nextBtn.dataset.url);
            });
        });
    }

    if (photo) {
        nextBtn.addEventListener("click", (event) => {
            event.preventDefault();

            hideAllErrors();

            const profilePhotoAdded =
                document.getElementById("profilePhotoSelect").dataset
                    .profilePhotoAdded;

            if (profilePhotoAdded === "false") {
                showError();

                return;
            }

            goToStep(nextBtn.dataset.url);
        });
    }

    if (yourPreferences) {
        nextBtn.addEventListener("click", (event) => {
            event.preventDefault();

            hideAllErrors();

            const prevStepBtn = yourPreferences.querySelector(".prev-step-btn");

            //Notifications
            const notifyYes = yourPreferences.querySelector("#notify-yes");
            const notifyNo = yourPreferences.querySelector("#notify-no");

            // Visibility
            const publicBtn =
                yourPreferences.querySelector("#visibility-public");
            const platformBtn = yourPreferences.querySelector(
                "#visibility-platform",
            );

            if (!notifyYes.checked && !notifyNo.checked) {
                showError();

                return;
            }

            if (!publicBtn.checked && !platformBtn.checked) {
                showError();

                return;
            }

            const formDataVisibility = new FormData();
            formDataVisibility.append(
                "visibility",
                publicBtn.checked ? "public" : "platform",
            );

            sendData(
                "/website-api/users/visibility/",
                formDataVisibility,
                () => {
                    if (notifyYes.checked) {
                        // Check if a user has already persisted saved search
                        fetch("/website-api/saved-search/", {
                            credentials: "same-origin",
                            method: "get",
                        })
                            .then((res) => res.json())
                            .then(function (json) {
                                if (json.metadata.code !== 200) {
                                    goToStep(nextBtn.dataset.url);

                                    return;
                                }

                                if (json.data.length > 0) {
                                    goToStep(nextBtn.dataset.url);

                                    return;
                                }

                                // User does not have persisted savedSearch.
                                const formDataNotifications = new FormData();
                                formDataNotifications.append(
                                    "saved_search_for_parents",
                                    !!notifyYes.checked,
                                );

                                sendData(
                                    "/website-api/users/saved-search/",
                                    formDataNotifications,
                                    () => {
                                        goToStep(nextBtn.dataset.url);
                                    },
                                );
                            });
                    } else {
                        goToStep(nextBtn.dataset.url);
                    }
                },
                () => {
                    goToStep(prevStepBtn.href);
                },
            );
        });
    }

    const toggleVideos = parentWizard.querySelectorAll(".toggle-video");

    toggleVideos.forEach(function (toggleVideo) {
        manageToggleVideo(toggleVideo);
    });

    function manageToggleVideo(toggleVideo) {
        toggleVideo.addEventListener("click", () => {
            const videoId = toggleVideo.dataset.videoId;
            const video = document.getElementById(videoId);

            const iconPlay = toggleVideo.querySelector(".icon-play");
            const iconPause = toggleVideo.querySelector(".icon-pause");

            if (video.paused) {
                video.play();
                video.style.display = "block";

                iconPlay.style.display = "none";
                iconPause.style.display = "block";
            } else {
                video.pause();
                video.style.display = "none";

                iconPlay.style.display = "block";
                iconPause.style.display = "none";
            }

            video.onended = function () {
                iconPlay.style.display = "block";
                iconPause.style.display = "none";
            };
        });
    }
});
