document.addEventListener("DOMContentLoaded", () => {
    const documentForms = document.querySelectorAll(
        ".document-form"
    );

    documentForms.forEach((documentForm) => {
        const documentAdd = documentForm.querySelector(".document-add");
        const buttonDelete = documentForm.querySelector(".button-delete");
        const documentSelect = documentForm.querySelector(".document-select");
        const documentInput = documentForm.querySelector(".document-input");
        const documentInReview = documentForm.querySelector(".document-in-review");

        if (documentSelect && documentInput && documentInReview) {
            documentSelect.addEventListener(
                "click",
                function (e) {
                    documentInput.click();
                },
                false
            );

            documentInput.addEventListener(
                "change",
                function (e) {
                    const file = this.files[0];

                    uploadFile(file);

                    this.value = '';
                },
                false
            );

            documentSelect.addEventListener("dragenter", dragenter, false);
            documentSelect.addEventListener("dragleave", dragleave, false);
            documentSelect.addEventListener("dragover", dragover, false);
            documentSelect.addEventListener("drop", drop, false);

            function dragenter(e) {
                documentSelect.classList.add("active");

                e.stopPropagation();
                e.preventDefault();
            }

            function dragleave(e) {
                documentSelect.classList.remove("active");

                e.stopPropagation();
                e.preventDefault();
            }

            function dragover(e) {
                documentSelect.classList.add("active");

                e.stopPropagation();
                e.preventDefault();
            }

            function drop(e) {
                documentSelect.classList.remove("active");
                e.stopPropagation();
                e.preventDefault();

                const dt = e.dataTransfer;
                const files = dt.files;

                const file = files[0];

                if (
                    !file.type.startsWith("image/") &&
                    !file.type.startsWith("application/pdf")
                ) {
                    alert(
                        "Sorry, that's an invalid file type. Only PDF, JPG and PNG allowed."
                    );
                    return false;
                }

                uploadFile(file);
            }

            function uploadFile(file) {
                const userId = documentSelect.dataset.userId;

                const uri = `/website-api/users/${userId}/documents/`;
                const xhr = new XMLHttpRequest();
                const fd = new FormData();

                const buttonAdd = documentSelect.querySelector(".button-add");
                const description =
                    documentSelect.querySelector(".description");
                const uploadStatusPercentage = documentSelect.querySelector(
                    ".upload-status-percentage"
                );
                const uploadResponseSpinner = documentSelect.querySelector(
                    ".upload-response-spinner"
                );

                uploadStatusPercentage.textContent = "";

                buttonAdd.style.display = "none";
                description.style.display = "none";
                uploadStatusPercentage.style.display = "block";
                uploadResponseSpinner.style.display = "none";

                xhr.upload.addEventListener(
                    "progress",
                    function (e) {
                        if (e.lengthComputable) {
                            const percentage = Math.round(
                                (e.loaded * 100) / e.total
                            );

                            uploadStatusPercentage.textContent =
                                percentage + "%";

                            if (percentage === 100) {
                                uploadStatusPercentage.style.display = "none";
                                uploadResponseSpinner.style.display = "block";
                            }
                        }
                    },
                    false
                );

                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4 && xhr.status === 413) {
                        documentInReview.style.display = "none";

                        buttonAdd.style.display = "block";
                        description.style.display = "block";
                        uploadStatusPercentage.style.display = "none";
                        uploadResponseSpinner.style.display = "none";

                        alert(
                            "Sorry, the file you are trying to add is too large. Upload a file less than 5Mb."
                        );

                        return;
                    }

                    if (xhr.readyState === 4 && xhr.status === 422) {
                        documentInReview.style.display = "none";

                        buttonAdd.style.display = "block";
                        description.style.display = "block";
                        uploadStatusPercentage.style.display = "none";
                        uploadResponseSpinner.style.display = "none";

                        const jsonResponse = JSON.parse(xhr.response);

                        alert(jsonResponse.metadata.errors[0].error);

                        return;
                    } else if (xhr.readyState === 4 && xhr.status >= 400) {
                        documentInReview.style.display = "none";

                        buttonAdd.style.display = "block";
                        description.style.display = "block";
                        uploadStatusPercentage.style.display = "none";
                        uploadResponseSpinner.style.display = "none";

                        const jsonResponse = JSON.parse(xhr.response);

                        alert(jsonResponse.metadata.error_message);

                        return;
                    }

                    if (xhr.readyState === 4 && xhr.status === 201) {
                        buttonAdd.style.display = "block";
                        description.style.display = "block";
                        uploadStatusPercentage.style.display = "none";
                        uploadResponseSpinner.style.display = "none";

                        documentAdd.style.display = "none";

                        documentInReview.style.display = "block";

                        const jsonResponse = JSON.parse(xhr.response);

                        buttonDelete.dataset.documentId = jsonResponse.metadata.id;
                    }
                };

                xhr.open("POST", uri);
                fd.append("file", file);
                fd.append("type", documentInput.dataset.type);
                // Initiate a multipart/form-data upload
                xhr.send(fd);
            }
        }
    });
});
