import Inputmask from "inputmask";

document.addEventListener("DOMContentLoaded", () => {
    const upgradePage = document.getElementById(
        "upgrade-page"
    );

    if (!upgradePage) {
        return;
    }

    const prepaidInput = upgradePage.querySelector(".prepaid-input");

    const im = new Inputmask("A{4,5}-*{4}-*{4}", {casing: "upper",});
    im.mask(prepaidInput);
});
