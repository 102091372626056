document.addEventListener("DOMContentLoaded", () => {
    const homepage = document.getElementById("homepage");

    if (!homepage) {
        return;
    }

    const climateCarousel = homepage.querySelector(".climate-carousel");

    const carouselItems = climateCarousel.querySelectorAll(".carousel-item");


    // Loop through each carouselItem and check which has the largest height
    let minHeight = 0;
    carouselItems.forEach(function (carouselItem) {
        carouselItem.classList.add('active');
        if (carouselItem.clientHeight > minHeight) {
            minHeight = carouselItem.clientHeight;
        }
        carouselItem.classList.remove('active');
    });

    // Set all carouselItems to the maxHeight, so they have equal height
    carouselItems.forEach(function (carouselItem) {
        carouselItem.style.minHeight = minHeight + 'px';
        // Remove active from all carouselItems
        carouselItem.classList.remove('active')
    });

    carouselItems[0].classList.add('active');

});
