import { Controller } from "@hotwired/stimulus";
import { showElement, hideElement } from "./utils";

export default class extends Controller {
    static values = { userId: Number };
    static targets = ["button", "icon", "iconActive", "spinner"];

    favorite(e) {
        const button = this.buttonTarget;
        const favoriteId = this.userIdValue;
        const icon = this.iconTarget;
        const iconActive = this.iconActiveTarget;
        const spinner = this.spinnerTarget;

        //Show spinner
        icon.classList.add("d-none");
        iconActive.classList.add("d-none");
        spinner.classList.remove("d-none");

        if (!button.classList.contains("active")) {
            fetch(`/website-api/favorites/${favoriteId}/`, {
                credentials: "same-origin",
                method: "post",
            })
                .then((response) => response.json())
                .then(function (json) {
                    if (json.metadata.code === 401) {
                        window.location.href = "/";
                    }

                    // Hide spinner
                    spinner.classList.add("d-none");
                    iconActive.classList.remove("d-none");
                    button.classList.add("active");
                });
        } else {
            fetch(`/website-api/user/favorite/${favoriteId}/`, {
                credentials: "same-origin",
                method: "delete",
            })
                .then((response) => response.json())
                .then(function (json) {
                    if (json.metadata.code === 401) {
                        window.location.href = "/";
                    }

                    // Hide spinner
                    spinner.classList.add("d-none");
                    icon.classList.remove("d-none");
                    button.classList.remove("active");
                });
        }
    }
}
