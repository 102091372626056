document.addEventListener("DOMContentLoaded", () => {
    const addReference = document.getElementById("add-reference");

    if (!addReference) {
        return;
    }

    const referencePhotoSelect = document.getElementById("referencePhotoSelect");
    const referencePhotoAdd = document.getElementById("referencePhotoAdd");
    const referencePhotoInput = document.getElementById("referencePhotoInput");

    const referencePhotoPreview = document.getElementById("referencePhotoPreview");

    if (referencePhotoSelect && referencePhotoAdd && referencePhotoInput && referencePhotoPreview) {
        referencePhotoSelect.addEventListener(
            "click",
            function (e) {
                if (referencePhotoInput) {
                    referencePhotoInput.click();
                }
            },
            false
        );
        referencePhotoAdd.addEventListener(
            "click",
            function (e) {
                if (referencePhotoInput) {
                    referencePhotoInput.click();
                }
            },
            false
        );

        referencePhotoInput.addEventListener(
            "change",
            function (e) {
                const file = this.files[0];

                if (
                    !file.type.startsWith("image/jpeg") &&
                    !file.type.startsWith("image/png")
                ) {
                    alert(
                        "Sorry, that's an invalid file type. Only JPG and PNG allowed."
                    );
                    return false;
                }

                previewFile(file);

            },
            false
        );

        referencePhotoSelect.addEventListener("dragenter", dragenter, false);
        referencePhotoSelect.addEventListener("dragleave", dragleave, false);
        referencePhotoSelect.addEventListener("dragover", dragover, false);
        referencePhotoSelect.addEventListener("drop", drop, false);

        function dragenter(e) {
            referencePhotoSelect.classList.add("active");

            e.stopPropagation();
            e.preventDefault();
        }

        function dragleave(e) {
            referencePhotoSelect.classList.remove("active");

            e.stopPropagation();
            e.preventDefault();
        }

        function dragover(e) {
            referencePhotoSelect.classList.add("active");

            e.stopPropagation();
            e.preventDefault();
        }

        function drop(e) {
            referencePhotoSelect.classList.remove("active");
            e.stopPropagation();
            e.preventDefault();

            const dt = e.dataTransfer;
            const files = dt.files;

            const file = files[0];

            if (
                !file.type.startsWith("image/jpeg") &&
                !file.type.startsWith("image/png")
            ) {
                alert(
                    "Sorry, that's an invalid file type. Only JPG and PNG allowed."
                );
                return false;
            }

            previewFile(file);

        }

        function previewFile(file) {
            referencePhotoPreview.style.display = "block"; // After removing a photo it is set to display=none, so make it block here

            referencePhotoPreview.style.objectFit = "cover";
            referencePhotoPreview.style.height = "100%";

            referencePhotoPreview.src = URL.createObjectURL(file);

            referencePhotoPreview.onload = function () {
                URL.revokeObjectURL(this.src);
            };
        }

    }

});
