import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(duration);

document.addEventListener("DOMContentLoaded", () => {
    const bookingCreate = document.getElementById("booking-create");

    if (!bookingCreate) {
        return;
    }

    const startAt = bookingCreate.querySelector(".start_at");
    const duration = bookingCreate.querySelector(".duration");

    const dateSelectorFrom = bookingCreate.querySelector(".date-selector-from");
    const timeSelectorFrom = bookingCreate.querySelector(".time-selector-from");
    const dateSelectorTo = bookingCreate.querySelector(".date-selector-to");
    const timeSelectorTo = bookingCreate.querySelector(".time-selector-to");
    const nextDay = bookingCreate.querySelector(".next-day");

    const submitButton = bookingCreate.querySelector('[type="submit"]');

    const hostnameBase = window.location.hostname.replace(/^[^.]*./, "");

    /* Convert input datetime to utc and fill hidden datetime field */
    function updateBooking() {
        const dateFrom = dateSelectorFrom.value;
        const timeFrom = timeSelectorFrom.value;
        const dateTo = dateSelectorTo.value;
        const timeTo = timeSelectorTo.value;

        const warningMinimalDuration = bookingCreate.querySelector(
            ".warning-minimum-duration",
        );
        warningMinimalDuration.style.display = "none";

        const warningMaximumDuration = bookingCreate.querySelector(
            ".warning-maximum-duration",
        );
        warningMaximumDuration.style.display = "none";

        const warningEndTimeBeforeStartTime = bookingCreate.querySelector(
            ".warning-end-time-before-start-time",
        );
        warningEndTimeBeforeStartTime.style.display = "none";

        const warningStartTimeBeforeNow = bookingCreate.querySelector(
            ".warning-start-time-before-now",
        );
        warningStartTimeBeforeNow.style.display = "none";

        dateSelectorFrom.classList.remove("error-displayed");
        timeSelectorFrom.classList.remove("error-displayed");
        dateSelectorTo.classList.remove("error-displayed");
        timeSelectorTo.classList.remove("error-displayed");

        submitButton.disabled = false;

        if (dateFrom && timeFrom && dateTo && timeTo) {
            const dayjs_dateFrom = dayjs(dateFrom).startOf("date");
            const dayjs_datetimeFrom = dayjs(dateFrom + " " + timeFrom);

            const dayjs_datetimeTo = dayjs(dateTo + " " + timeTo);

            const dayjs_datetime_now = dayjs();

            /* Prevent users from selecting a date in the past */
            if (dayjs_dateFrom.isBefore(dayjs().startOf("date"))) {
                dateSelectorFrom.classList.add("error-displayed");
                submitButton.disabled = true;

                warningStartTimeBeforeNow.style.display = "block";

                return;
            }

            /* Prevent user from selecting datetimeFrom in the past */
            if (dayjs_datetimeFrom < dayjs_datetime_now) {
                /* Mark field red */
                timeSelectorFrom.classList.add("error-displayed");
                submitButton.disabled = true;

                warningStartTimeBeforeNow.style.display = "block";

                return;
            }

            /* Prevent user from selecting datetimeTo in the past */
            if (dayjs_datetimeTo < dayjs_datetime_now) {
                /* Mark field red */
                timeSelectorTo.classList.add("error-displayed");
                submitButton.disabled = true;

                return;
            }

            /* Prevent user from selecting a timeTo similar to or before timeFrom */
            if (dayjs_datetimeTo <= dayjs_datetimeFrom) {
                /* Mark field red */
                timeSelectorTo.classList.add("error-displayed");
                submitButton.disabled = true;

                warningEndTimeBeforeStartTime.style.display = "block";

                return;
            }

            // Make sure to set the startAt time in UTC, so our backend will receive the time in UTC
            startAt.value = dayjs_datetimeFrom.utc().format();

            /* Calculate number of hours */
            const dayjsDuration = dayjs.duration(
                dayjs_datetimeTo.diff(dayjs_datetimeFrom),
            );

            let durationAsMinutes = dayjsDuration.asMinutes();

            /* Do now allow a duration less than 1 hour */
            if (durationAsMinutes < 60) {
                /* Mark to field red */
                timeSelectorTo.classList.add("error-displayed");
                submitButton.disabled = true;

                warningMinimalDuration.style.display = "block";

                return;
            }

            /* Do now allow a duration more than 24 hours */
            if (durationAsMinutes > 1440) {
                /* Mark to field red */
                timeSelectorTo.classList.add("error-displayed");
                submitButton.disabled = true;

                warningMaximumDuration.style.display = "block";

                return;
            }

            duration.value = durationAsMinutes;

            updateTotalAmountCalculation();

            document.cookie =
                "booking-dateSelectorFrom=" +
                dateSelectorFrom.value +
                "; max-age=2592000; path=/; domain=" +
                hostnameBase +
                "; secure; samesite=lax";
            document.cookie =
                "booking-timeSelectorFrom=" +
                timeSelectorFrom.value +
                "; max-age=2592000; path=/; domain=" +
                hostnameBase +
                "; secure; samesite=lax";
            document.cookie =
                "booking-dateSelectorTo=" +
                dateSelectorTo.value +
                "; max-age=2592000; path=/; domain=" +
                hostnameBase +
                "; secure; samesite=lax";
            document.cookie =
                "booking-timeSelectorTo=" +
                timeSelectorTo.value +
                "; max-age=2592000; path=/; domain=" +
                hostnameBase +
                "; secure; samesite=lax";
            document.cookie =
                "booking-startAt=" +
                startAt.value +
                "; max-age=2592000; path=/; domain=" +
                hostnameBase +
                "; secure; samesite=lax";
            document.cookie =
                "booking-duration=" +
                duration.value +
                "; max-age=2592000; path=/; domain=" +
                hostnameBase +
                "; secure; samesite=lax";
        }
    }

    function updateDateSelectorToValueMinAndMax() {
        const fromDate = dayjs(dateSelectorFrom.value);

        dateSelectorTo.min = fromDate.format("YYYY-MM-DD");
        dateSelectorTo.max = fromDate.add(1, "days").format("YYYY-MM-DD");
    }

    function updateTotalAmountCalculation() {
        if (!duration.value) {
            return;
        }

        const dayjsDuration = dayjs.duration(duration.value, "minutes");

        const durationAsHours = dayjsDuration.asHours();
        const durationHours = Math.floor(dayjsDuration.asHours());
        const durationMinutes = dayjsDuration.minutes();

        const calculation = bookingCreate.querySelector(".calculation");
        const calculationHours = calculation.querySelector(".hours");
        const calculationMinutes = calculation.querySelector(".minutes");
        const calculationMinutesLabel =
            calculation.querySelector(".minutes-label");

        const calculationFormattedRateMoney = calculation.querySelector(
            ".formatted-rate-money",
        );
        const calculationFormattedTotalMoney = calculation.querySelector(
            ".formatted-total-money",
        );

        calculationHours.textContent = durationHours;
        calculationMinutes.textContent = durationMinutes;

        calculationMinutes.style.display = "inline-block";
        calculationMinutesLabel.style.display = "inline-block";
        if (durationMinutes === 0) {
            calculationMinutes.style.display = "none";
            calculationMinutesLabel.style.display = "none";
        }

        /* Calculate total money */
        const rateMoneyAmount = calculation.dataset.amount;
        const moneyCurrency = calculation.dataset.currency;

        const totalMoneyAmount = rateMoneyAmount * durationAsHours;

        const locale = document.documentElement.lang;

        const formattedRateMoney = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: moneyCurrency,
        }).format(rateMoneyAmount);
        const formattedTotalMoney = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: moneyCurrency,
        }).format(totalMoneyAmount);

        calculationFormattedRateMoney.textContent = formattedRateMoney;
        calculationFormattedTotalMoney.textContent = formattedTotalMoney;
    }

    /* onchange dateSelectorFrom: set dateSelectorTo to match dateSelectorFrom. Only allow min=to and max=1day */
    dateSelectorFrom.addEventListener("change", () => {
        dateSelectorTo.value = dateSelectorFrom.value;
        updateDateSelectorToValueMinAndMax();

        dateSelectorTo.style.display = "none";
        nextDay.style.display = "block";
    });

    dateSelectorFrom.addEventListener("change", updateBooking);
    timeSelectorFrom.addEventListener("change", updateBooking);
    dateSelectorTo.addEventListener("change", updateBooking);
    timeSelectorTo.addEventListener("change", updateBooking);

    dateSelectorFrom.addEventListener("keyup", updateBooking);
    timeSelectorFrom.addEventListener("keyup", updateBooking);
    dateSelectorTo.addEventListener("keyup", updateBooking);
    timeSelectorTo.addEventListener("keyup", updateBooking);

    /* Set values from cookies */
    const dateSelectorFromCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("booking-dateSelectorFrom="))
        ?.split("=")[1];
    const timeSelectorFromCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("booking-timeSelectorFrom="))
        ?.split("=")[1];
    const dateSelectorToCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("booking-dateSelectorTo="))
        ?.split("=")[1];
    const timeSelectorToCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("booking-timeSelectorTo="))
        ?.split("=")[1];
    const startAtCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("booking-startAt="))
        ?.split("=")[1];
    const durationCookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith("booking-duration="))
        ?.split("=")[1];

    if (
        dateSelectorFromCookie &&
        timeSelectorFromCookie &&
        dateSelectorToCookie &&
        timeSelectorToCookie &&
        startAtCookie &&
        durationCookie
    ) {
        if (timeSelectorFrom && timeSelectorTo) {
            // Set the fields we can always set
            timeSelectorFrom.value = timeSelectorFromCookie;
            timeSelectorTo.value = timeSelectorToCookie;
        }

        const dayjs_start = dayjs(startAtCookie);

        if (!dayjs_start.isBefore(dayjs())) {
            // startAtCookie is in the future: set all other fields as well
            dateSelectorFrom.value = dateSelectorFromCookie;

            dateSelectorTo.value = dateSelectorToCookie;
            updateDateSelectorToValueMinAndMax();

            startAt.value = startAtCookie;
            duration.value = durationCookie;

            updateBooking();
        }

        if (dateSelectorFromCookie !== dateSelectorToCookie) {
            // Show dateSelectorTo if the value is set
            dateSelectorTo.style.display = "block";
            nextDay.style.display = "none";
        }
    }

    /* Toggle Next day */
    nextDay.addEventListener("click", () => {
        if (dateSelectorTo.style.display === "block") {
            dateSelectorTo.style.display = "none";
            nextDay.style.display = "block";

            // Set dateSelectorTo back to dateSelectorFrom
            dateSelectorTo.value = dateSelectorFrom.value;
            updateDateSelectorToValueMinAndMax();

            updateBooking();

            return;
        }

        const fromDate = dayjs(dateSelectorFrom.value);

        dateSelectorTo.value = fromDate.add(1, "days").format("YYYY-MM-DD");
        updateDateSelectorToValueMinAndMax();

        updateBooking();

        dateSelectorTo.style.display = "block";
        nextDay.style.display = "none";
    });

    /* Hide dateSelectorTo if its the same as dateSelectorTo */
    dateSelectorTo.addEventListener("change", () => {
        if (dateSelectorFrom.value === dateSelectorTo.value) {
            dateSelectorTo.style.display = "none";
            nextDay.style.display = "block";
        }
    });
});
