document.addEventListener("DOMContentLoaded", () => {
    const footerFixed = document.getElementById("footer-fixed");

    if (!footerFixed) {
        return;
    }

    let scrollPos = 0;

    window.addEventListener("scroll", (e) => {
        e.preventDefault();

        if (window.pageYOffset < scrollPos || scrollPos < 42) {
            // Scroll UP or top of page
            footerFixed.classList.add("active");
        } else {
            // Scroll DOWN
            footerFixed.classList.remove("active");
        }

        // saves the new position for iteration.
        scrollPos = window.pageYOffset;
    });
});
