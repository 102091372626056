document.addEventListener("DOMContentLoaded", () => {
    const nextSteps = document.getElementById("next-steps");

    if (!nextSteps) {
        return;
    }

    const steps = nextSteps.querySelectorAll(".step");

    steps.forEach(function (step) {
        const button = step.querySelector("button");
        const body = step.querySelector(".body");
        const icon = step.querySelector(".icon");

        button.addEventListener("click", function () {
            body.classList.toggle("d-none");
            icon.classList.toggle("d-none");
        });
    });
});
