document.addEventListener("DOMContentLoaded", () => {
    const shareYourExperience = document.getElementById(
        "share-your-experience"
    );

    if (!shareYourExperience) {
        return;
    }

    const forms = shareYourExperience.querySelectorAll(".story-form");
    forms.forEach(function (form) {
        form.addEventListener("submit", (e) => {
            e.preventDefault();

            const content = form.querySelector("textarea");

            const formData = new FormData();
            formData.append("content", content.value);

            fetch("/website-api/share-your-experience/", {
                credentials: "same-origin",
                method: "post",
                body: formData,
            })
                .then((response) => {
                    if (response.status === 429) {
                        throw new Error(
                            "Too many requests. Please try again later."
                        );
                    }

                    return response.json();
                })
                .then(function (jsonResponse) {
                    if (jsonResponse.metadata.code !== 200) {
                        if (jsonResponse.metadata.error_message) {
                            alert(jsonResponse.metadata.error_message);

                            return;
                        }
                        alert(
                            "Something went wrong. Please contact customer service."
                        );
                    } else {
                        const step1 =
                            shareYourExperience.querySelector(".step-1");
                        const step2a =
                            shareYourExperience.querySelector(".step-2a");
                        const step2b =
                            shareYourExperience.querySelector(".step-2b");
                        const stepSuccess =
                            shareYourExperience.querySelector(".step-success");

                        step1.style.display = "none";
                        step2a.style.display = "none";
                        step2b.style.display = "none";
                        stepSuccess.style.display = "block";
                    }
                })
                .catch((error) => {
                    alert(error);
                });
        });
    });

    const ratingForm = shareYourExperience.querySelector(".rating-form");
    ratingForm.addEventListener("submit", (e) => {
        e.preventDefault();

        const easeOfUse = shareYourExperience.querySelector(
            ".ease-of-use:checked"
        );
        const satisfaction = shareYourExperience.querySelector(
            ".satisfaction:checked"
        );
        const likelyToRecommend = shareYourExperience.querySelector(
            ".likely-to-recommend:checked"
        );

        if (!easeOfUse || !satisfaction || !likelyToRecommend) {
            return;
        }

        const formData = new FormData();
        formData.append("ease_of_use", easeOfUse.value);
        formData.append("satisfaction", satisfaction.value);
        formData.append("likely_to_recommend", likelyToRecommend.value);

        fetch("/website-api/share-your-experience/", {
            credentials: "same-origin",
            method: "post",
            body: formData,
        })
            .then((response) => response.json())
            .then(function (jsonResponse) {
                if (jsonResponse.metadata.code !== 200) {
                    if (jsonResponse.metadata.error_message) {
                        alert(jsonResponse.metadata.error_message);

                        return;
                    }
                    alert(
                        "Something went wrong. Please contact customer service."
                    );
                }
            });

        const totalCount =
            parseInt(easeOfUse.value) +
            parseInt(satisfaction.value) +
            parseInt(likelyToRecommend.value);
        const totalAverageCount = totalCount / 3;

        const step1 = shareYourExperience.querySelector(".step-1");
        const step2a = shareYourExperience.querySelector(".step-2a");
        const step2b = shareYourExperience.querySelector(".step-2b");

        step1.style.display = "none";

        if (totalAverageCount > 4) {
            step2b.style.display = "block";

            return;
        }

        step2a.style.display = "block";
    });

    function manageHoverAndClick(experienceRadio, label) {
        const valueStarred = parseInt(label.dataset.value);
        const valueUnStarred = valueStarred + 1;

        const labelsStarred = experienceRadio.querySelectorAll(
            "label:nth-child(-n+" + valueStarred + ")"
        );
        labelsStarred.forEach(function (labelStarred) {
            const icon = labelStarred.querySelector(".icon");
            const iconActive = labelStarred.querySelector(".iconActive");

            icon.classList.add("d-none");
            iconActive.classList.remove("d-none");
        });

        const labelsUnstarred = experienceRadio.querySelectorAll(
            "label:nth-child(n+" + valueUnStarred + ")"
        );
        labelsUnstarred.forEach(function (labelUnstarred) {
            const icon = labelUnstarred.querySelector(".icon");
            const iconActive = labelUnstarred.querySelector(".iconActive");

            icon.classList.remove("d-none");
            iconActive.classList.add("d-none");
        });
    }

    const experienceRadios =
        shareYourExperience.querySelectorAll(".experience-radio");
    experienceRadios.forEach(function (experienceRadio) {
        const labels = experienceRadio.querySelectorAll("label");
        labels.forEach(function (label) {
            label.addEventListener("mouseleave", () => {
                const selectedLabel =
                    experienceRadio.querySelector("input:checked");

                let valueStarred = 0;

                if (selectedLabel) {
                    valueStarred = selectedLabel.value;
                }

                const valueUnStarred = valueStarred + 1;

                const labelsStarred = experienceRadio.querySelectorAll(
                    "label:nth-child(-n+" + valueStarred + ")"
                );
                labelsStarred.forEach(function (labelStarred) {
                    const icon = labelStarred.querySelector(".icon");
                    const iconActive =
                        labelStarred.querySelector(".iconActive");

                    icon.classList.add("d-none");
                    iconActive.classList.remove("d-none");
                });

                const labelsUnstarred = experienceRadio.querySelectorAll(
                    "label:nth-child(n+" + valueUnStarred + ")"
                );
                labelsUnstarred.forEach(function (labelUnstarred) {
                    const icon = labelUnstarred.querySelector(".icon");
                    const iconActive =
                        labelUnstarred.querySelector(".iconActive");

                    icon.classList.remove("d-none");
                    iconActive.classList.add("d-none");
                });
            });

            label.addEventListener("mouseenter", () => {
                manageHoverAndClick(experienceRadio, label);
            });

            label.addEventListener("click", () => {
                manageHoverAndClick(experienceRadio, label);
            });
        });
    });
});
