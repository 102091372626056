import { Controller } from "@hotwired/stimulus";
import { showElement, hideElement } from "./utils";

export default class extends Controller {
    static targets = ["content"];

    readMore() {
        const content = this.contentTarget;

        content.textContent = content.dataset.content; // this is user content, never output as .html()
    }
}
