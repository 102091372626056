document.addEventListener("DOMContentLoaded", () => {
    const howItWorksStepper = document.getElementById("how-it-works-stepper");

    if (!howItWorksStepper) {
        return;
    }

    function showNextIcon(icons, totalIcons) {
        icons.currentStepIndex = (icons.currentStepIndex + 1) % totalIcons;
        const offset = -icons.currentStepIndex * 100 * (1 / 3);
        icons.style.transform = `translateX(${offset}%)`;
    }

    const icons = howItWorksStepper.querySelector(".icons");
    const totalIcons = icons.querySelectorAll(".icon").length;
    icons.currentStepIndex = 0; // Initialize the step index
    setInterval(() => showNextIcon(icons, totalIcons), 3000);

    function showNextStep(steps) {
        steps.currentStepIndex = (steps.currentStepIndex + 1) % steps.length;
        let stepCount = 0;
        steps.forEach(function (step) {
            step.classList.remove("active");

            if (stepCount === steps.currentStepIndex) {
                step.classList.add("active");
            }

            stepCount++;
        });
    }

    const stepsMd = howItWorksStepper.querySelector(".steps-md");
    const stepsMdSteps = stepsMd.querySelectorAll(".step");
    stepsMdSteps.currentStepIndex = 0; // Initialize the step index
    setInterval(() => showNextStep(stepsMdSteps), 3000);

    const stepsSm = howItWorksStepper.querySelector(".steps-sm");
    const stepsSmSteps = stepsSm.querySelectorAll(".step");
    stepsSmSteps.currentStepIndex = 0; // Initialize the step index
    setInterval(() => showNextStep(stepsSmSteps), 3000);

    const stepsSmContent = howItWorksStepper.querySelector(".steps-sm-content");
    const stepsSmContentSteps = stepsSmContent.querySelectorAll(".step");
    stepsSmContentSteps.currentStepIndex = 0; // Initialize the step index
    setInterval(() => showNextStep(stepsSmContentSteps), 3000);
});
