import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

document.addEventListener("DOMContentLoaded", () => {
    const bookingAddonTime = document.getElementById("booking-addon-time");

    if (!bookingAddonTime) {
        return;
    }

    const addonTimeDuration =
        bookingAddonTime.querySelector(".addonTimeDuration");

    addonTimeDuration.addEventListener("change", (e) => {
        if (!e.target.value) {
            return;
        }

        const dayjsDuration = dayjs.duration(e.target.value, "minutes");

        const durationAsHours = dayjsDuration.asHours();
        const durationHours = Math.floor(dayjsDuration.asHours());
        const durationMinutes = dayjsDuration.minutes();

        const addonTimeCalculation = bookingAddonTime.querySelector(
            ".addonTimeCalculation",
        );
        const calculationHours = addonTimeCalculation.querySelector(".hours");
        const calculationMinutes =
            addonTimeCalculation.querySelector(".minutes");
        const calculationMinutesLabel =
            addonTimeCalculation.querySelector(".minutes-label");

        const calculationFormattedRateMoney =
            addonTimeCalculation.querySelector(".formatted-rate-money");
        const calculationFormattedTotalMoney =
            addonTimeCalculation.querySelector(".formatted-total-money");

        calculationHours.textContent = durationHours;
        calculationMinutes.textContent = durationMinutes;

        calculationMinutes.style.display = "inline-block";
        calculationMinutesLabel.style.display = "inline-block";

        if (durationMinutes === 0) {
            calculationMinutes.style.display = "none";
            calculationMinutesLabel.style.display = "none";
        }

        /* Calculate total money */
        const rateMoneyAmount = addonTimeCalculation.dataset.amount;
        const moneyCurrency = addonTimeCalculation.dataset.currency;

        const totalMoneyAmount = rateMoneyAmount * durationAsHours;

        const locale = document.documentElement.lang;

        const formattedRateMoney = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: moneyCurrency,
        }).format(rateMoneyAmount);

        const formattedTotalMoney = new Intl.NumberFormat(locale, {
            style: "currency",
            currency: moneyCurrency,
        }).format(totalMoneyAmount);

        calculationFormattedRateMoney.textContent = formattedRateMoney;
        calculationFormattedTotalMoney.textContent = formattedTotalMoney;
    });
});
