document.addEventListener("DOMContentLoaded", () => {
    const dialogFreemium = document.querySelector(".dialog-freemium");

    if (!dialogFreemium) {
        return;
    }

    const freemiumContact = dialogFreemium.querySelector(".freemium-contact");

    const viewExamples = freemiumContact.querySelector(".viewExamples");

    const examples = freemiumContact.querySelector(".examples");

    viewExamples.addEventListener("click", (e) => {
        examples.classList.toggle("d-none");

        if (!examples.classList.contains("d-none")) {
            viewExamples.scrollIntoView({ block: "center" });
        }
    });
});
