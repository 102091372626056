document.addEventListener("DOMContentLoaded", () => {
    const savedSearchList = document.getElementById("saved-search-list");

    if (!savedSearchList) {
        return;
    }

    const deleteSavedSearchItems = savedSearchList.querySelectorAll(
        ".delete-saved-search-item"
    );
    deleteSavedSearchItems.forEach(function (deleteSavedSearchItem) {
        deleteSavedSearchItem.addEventListener(
            "click",
            () => {
                handleDelete(deleteSavedSearchItem);
            },
            { passive: true }
        );
    });

    function handleDelete(deleteButton) {
        const deleteAlert = document.querySelector(
            ".saved-search-delete-alert"
        );
        const id = deleteButton.dataset.id;

        deleteButton.classList.add("d-none");
        deleteButton
            .closest("li")
            .querySelector(".spinner")
            .classList.remove("d-none");

        fetch("/website-api/saved-search/" + id + "/", {
            credentials: "same-origin",
            method: "delete",
        })
            .then((response) => response.json())
            .then(function (data) {
                if (data.metadata.code !== 200) {
                    return;
                }

                deleteButton.closest("li").remove();
                deleteAlert.style.display = "block";

                setTimeout(function () {
                    deleteAlert.style.display = "none";
                }, 4000);
            });
    }

    const savedSearchButton = document.getElementById("saved-search-button");
    const savedSearchForm = document.getElementById("saved-search-form");

    if (savedSearchButton && savedSearchForm) {
        savedSearchButton.addEventListener("click", (e) => {
            savedSearchForm.classList.toggle("active");
        });

        savedSearchForm.addEventListener("submit", (e) => {
            e.preventDefault();

            const searchParams = document.getElementById("saved-search-params");
            const savedSearchTitle = savedSearchForm.querySelector(
                ".saved-search-title"
            );

            const title = savedSearchTitle.value;
            const params = JSON.parse(searchParams.value);

            if (!title) {
                return;
            }

            const capitalizedTitle =
                title.charAt(0).toUpperCase() + title.slice(1);
            const formData = new FormData();

            formData.append("title", capitalizedTitle);
            formData.append("search_params", JSON.stringify(params));

            fetch("/website-api/saved-search/", {
                credentials: "same-origin",
                method: "post",
                body: formData,
            })
                .then((response) => response.json())
                .then(function (data) {
                    if (data.metadata.code !== 201) {
                        return;
                    }

                    const link = document.createElement("a");
                    link.href = window.location.pathname + location.search;
                    link.textContent = capitalizedTitle;

                    const deleteButton = document.createElement("button");
                    deleteButton.setAttribute(
                        "class",
                        "delete-saved-search-item"
                    );
                    deleteButton.dataset.id = data.metadata.id;

                    const deleteButtonIcon = document.createElement("i");
                    deleteButtonIcon.setAttribute("class", "fa fa-times");
                    deleteButton.appendChild(deleteButtonIcon);

                    const spinner = document.createElement("div");
                    spinner.setAttribute("class", "spinner d-none");

                    const spinnerIcon = document.createElement("span");
                    spinnerIcon.setAttribute("class", "spinner-border spinner-border-sm");
                    spinner.appendChild(spinnerIcon);

                    const deleteButtonWrapper = document.createElement("div");
                    deleteButtonWrapper.appendChild(deleteButton);
                    deleteButtonWrapper.appendChild(spinner);

                    const listItem = document.createElement("li");
                    listItem.appendChild(link);
                    listItem.appendChild(deleteButtonWrapper);

                    savedSearchList.appendChild(listItem);

                    deleteButton.addEventListener(
                        "click",
                        () => {
                            handleDelete(deleteButton);
                        },
                        { passive: true }
                    );

                    savedSearchForm.classList.remove("active");

                    const button = savedSearchForm.querySelector("button");
                    button.disabled = false;

                    button.innerText = button.dataset.text;

                    const searchFilterDialog = document.getElementById(
                        "searchfilter"
                    );
                    if (searchFilterDialog) {
                        searchFilterDialog.scrollTop = 0;
                    }

                    savedSearchTitle.value = "";
                });
        });
    }
});
