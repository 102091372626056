document.addEventListener("DOMContentLoaded", () => {
    const badges = document.getElementById("badges");

    if (!badges) {
        return;
    }

    const shareFacebookBtn = badges.querySelector(
        ".trophy.share-facebook .btn",
    );
    const shareTwitterBtn = badges.querySelector(".trophy.share-twitter .btn");
    const facebookLikeBtn = badges.querySelector(".trophy.like-facebook .btn");
    const followInstagramBtn = badges.querySelector(
        ".trophy.follow-instagram .btn",
    );
    const followTiktokBtn = badges.querySelector(".trophy.follow-tiktok .btn");
    const followLinkedinBtn = badges.querySelector(
        ".trophy.follow-linkedin .btn",
    );
    const reviewFacebookBtn = badges.querySelector(
        ".trophy.review-facebook .btn",
    );
    const reviewGoogleBtn = badges.querySelector(".trophy.review-google .btn");
    const reviewTrustpilotBtn = badges.querySelector(
        ".trophy.review-trustpilot .btn",
    );

    const posterBtn = badges.querySelector(".trophy.poster .btn");

    shareFacebookBtn.addEventListener("click", (e) => {
        shareFacebookBtn.innerHTML =
            '<span role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">...</span></span>';
        send(
            "/website-api/badges/facebook_shared/",
            badges.querySelector(".trophy.share-facebook"),
        );
    });

    shareTwitterBtn.addEventListener("click", (e) => {
        shareTwitterBtn.innerHTML =
            '<span role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">...</span></span>';
        send(
            "/website-api/badges/twitter_shared/",
            badges.querySelector(".trophy.share-twitter"),
        );
    });

    facebookLikeBtn.addEventListener("click", (e) => {
        facebookLikeBtn.innerHTML =
            '<span role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">...</span></span>';
        send(
            "/website-api/badges/facebook_liked/",
            badges.querySelector(".trophy.like-facebook"),
        );
    });

    followInstagramBtn.addEventListener("click", (e) => {
        followInstagramBtn.innerHTML =
            '<span role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">...</span></span>';
        send(
            "/website-api/badges/instagram_followed/",
            badges.querySelector(".trophy.follow-instagram"),
        );
    });

    followTiktokBtn.addEventListener("click", (e) => {
        followTiktokBtn.innerHTML =
            '<span role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">...</span></span>';
        send(
            "/website-api/badges/tiktok_followed/",
            badges.querySelector(".trophy.follow-tiktok"),
        );
    });

    followLinkedinBtn.addEventListener("click", (e) => {
        followLinkedinBtn.innerHTML =
            '<span role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">...</span></span>';
        send(
            "/website-api/badges/linkedin_followed/",
            badges.querySelector(".trophy.follow-linkedin"),
        );
    });
    reviewFacebookBtn.addEventListener("click", (e) => {
        reviewFacebookBtn.innerHTML =
            '<span role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">...</span></span>';
        send(
            "/website-api/badges/review_facebook/",
            badges.querySelector(".trophy.review-facebook"),
        );
    });
    reviewGoogleBtn.addEventListener("click", (e) => {
        reviewGoogleBtn.innerHTML =
            '<span role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">...</span></span>';
        send(
            "/website-api/badges/review_google/",
            badges.querySelector(".trophy.review-google"),
        );
    });
    reviewTrustpilotBtn.addEventListener("click", (e) => {
        reviewTrustpilotBtn.innerHTML =
            '<span role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">...</span></span>';
        send(
            "/website-api/badges/review_trustpilot/",
            badges.querySelector(".trophy.review-trustpilot"),
        );
    });

    posterBtn.addEventListener("click", (e) => {
        posterBtn.innerHTML =
            '<span role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">...</span></span>';
    });

    function send(url, element) {
        fetch(url, {
            credentials: "same-origin",
            method: "post",
        })
            .then((response) => response.json())
            .then(function (data) {
                if (data.metadata.code === 200) {
                    element.classList.add("active");
                }
                if (data.metadata.code === 401) {
                    window.location.href = "/";
                }

                if (data.metadata.code === 409) {
                    // conflict, already unlocked
                }
            });
    }
});
