import dialogPolyfill from "dialog-polyfill";
import maplibregl from "maplibre-gl";
import debounce from "lodash/debounce";

document.addEventListener("DOMContentLoaded", () => {
    const search = document.getElementById("search");

    if (!search) {
        return;
    }

    maplibregl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        true,
    );

    const filterBars = search.querySelectorAll(".filter-bar");

    const mapMarkerWidth = 40 + "px";
    const mapMarkerHeight = 40 + "px";
    // Active icons 10% larger
    const mapMarkerActiveWidth = 44 + "px";
    const mapMarkerActiveHeight = 44 + "px";

    document.addEventListener("click", (e) => {
        const currentElement = e.target;
        const currentFilterBar = currentElement.closest(".filter-bar");
        if (currentFilterBar) {
            return;
        }

        filterBars.forEach(function (filterBar) {
            filterBar.classList.remove("active");
        });
    });

    filterBars.forEach(function (filterBar) {
        const buttonOpenTooltip = filterBar.querySelector(
            "button.open-tooltip",
        );

        buttonOpenTooltip.addEventListener("click", () => {
            /* First close any open filterbars */
            filterBars.forEach(function (filterBarToCheck) {
                /* Do not close current filterBar*/
                if (filterBarToCheck === filterBar) {
                    return;
                }
                filterBarToCheck.classList.remove("active");
            });

            /* Than toggle this filterbar */
            filterBar.classList.toggle("active");
        });
    });

    const toggleVideos = search.querySelectorAll(".toggle-video");
    toggleVideos.forEach(function (toggleVideo) {
        manageToggleVideo(toggleVideo);
    });

    function manageToggleVideo(toggleVideo) {
        toggleVideo.addEventListener("click", () => {
            const videoId = toggleVideo.dataset.videoId;
            const video = document.getElementById(videoId);

            const iconPlay = toggleVideo.querySelector(".icon-play");
            const iconPause = toggleVideo.querySelector(".icon-pause");

            if (video.paused) {
                video.play();
                video.style.display = "block";

                iconPlay.style.display = "none";
                iconPause.style.display = "block";
            } else {
                video.pause();
                video.style.display = "none";

                iconPlay.style.display = "block";
                iconPause.style.display = "none";
            }

            video.onended = function () {
                iconPlay.style.display = "block";
                iconPause.style.display = "none";
            };
        });
    }

    const paginationPages = search.querySelectorAll(".pagination a");
    paginationPages.forEach(function (paginationPage) {
        managePaginationPage(paginationPage);
    });

    function managePaginationPage(paginationPage) {
        paginationPage.addEventListener("click", (e) => {
            e.preventDefault();

            const deeplinkUrl = paginationPage.getAttribute("href");
            searchAction(deeplinkUrl, false);
        });
    }

    const profileCards = search.querySelectorAll(".profile-card");
    profileCards.forEach(function (profileCard) {
        manageProfileCards(profileCard);
    });

    function manageProfileCards(profileCard) {
        profileCard.addEventListener("mouseenter", () => {
            const geohash = profileCard.dataset.geohash;

            markersList.forEach((marker) => {
                // First set all markers to the default markerIcon
                marker._element.style.backgroundImage =
                    "url(" + marker._element.markerIcon + ")";
                marker._element.style.width = mapMarkerWidth;
                marker._element.style.height = mapMarkerHeight;
                marker._element.style.zIndex = 0;
                // set this profile geohash marker to active
                if (marker._element.geohash === geohash) {
                    marker._element.style.backgroundImage =
                        "url(" + marker._element.markerIconActive + ")";
                    marker._element.style.width = mapMarkerActiveWidth;
                    marker._element.style.height = mapMarkerActiveHeight;
                    marker._element.style.zIndex = 1;
                }
            });
        });

        profileCard.addEventListener(
            "touchstart",
            () => {
                const geohash = profileCard.dataset.geohash;

                markersList.forEach((marker) => {
                    // First set all markers to the default markerIcon
                    marker._element.style.backgroundImage =
                        "url(" + marker._element.markerIcon + ")";
                    marker._element.style.width = mapMarkerWidth;
                    marker._element.style.height = mapMarkerHeight;
                    marker._element.style.zIndex = 0;
                    // set this profile geohash marker to active
                    if (marker._element.geohash === geohash) {
                        marker._element.style.backgroundImage =
                            "url(" + marker._element.markerIconActive + ")";
                        marker._element.style.width = mapMarkerActiveWidth;
                        marker._element.style.height = mapMarkerActiveHeight;
                        marker._element.style.zIndex = 1;
                    }
                });
            },
            { passive: true },
        );

        profileCard.addEventListener("mouseleave", () => {
            const geohash = profileCard.dataset.geohash;

            markersList.forEach((marker) => {
                if (marker._element.geohash === geohash) {
                    marker._element.style.backgroundImage =
                        "url(" + marker._element.markerIcon + ")";
                    marker._element.style.width = mapMarkerWidth;
                    marker._element.style.height = mapMarkerHeight;
                    marker._element.style.zIndex = 0;
                }
            });
        });
    }

    const body = document.querySelector("body");

    const buttonViewResults = search.querySelector("button.view-results");
    buttonViewResults.addEventListener("click", (event) => {
        const searchfilter = document.getElementById("searchfilter");
        dialogPolyfill.registerDialog(searchfilter);

        searchfilter.close();
    });

    const buttonMapShow = search.querySelector("button.map-show");
    if (buttonMapShow) {
        buttonMapShow.addEventListener("click", (event) => {
            body.classList.add("mapvisible");
            initialize_maps(); // Load maps
        });
    }

    const buttonMapHide = search.querySelector("button.map-hide");
    if (buttonMapHide) {
        buttonMapHide.addEventListener("click", (event) => {
            body.classList.remove("mapvisible");
            body.classList.add("maphidden");

            // For smooth transitions between screensizes remove maphidden class after 0,5 seconds
            setTimeout(function () {
                body.classList.remove("maphidden");
            }, 400);
        });
    }

    function searchAction(deeplinkUrl, updateMarkers, updatePushState) {
        /* Default parameter values, IE11 and Edge tested */
        updateMarkers =
            typeof updateMarkers !== "undefined" ? updateMarkers : true;
        updatePushState =
            typeof updatePushState !== "undefined" ? updatePushState : true;

        const viewResultsButton = search.querySelector("button.view-results");

        viewResultsButton.innerHTML =
            '<span role="status" class="spinner-border spinner-border-sm"><span class="visually-hidden">...</span></span>';

        if (deeplinkUrl.includes("geohash")) {
            body.classList.add("geohashsearch");
        } else {
            body.classList.remove("geohashsearch");
        }

        const loading = search.querySelector(".profile-cards .loading");
        const loadingSpinner = search.querySelector(
            ".profile-cards .loading-spinner",
        );

        /* Scroll to top of document */
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        /* Grey out result list */
        setTimeout(function () {
            loading.style.display = "block";
        }, 400);

        /* After 400 milsec add loadingSpinner */
        setTimeout(function () {
            loadingSpinner.style.display = "block";
        }, 2000);

        if (updatePushState) {
            history.pushState({}, "", deeplinkUrl);
        }

        debounceGetSearchListResults(deeplinkUrl);

        if (window.mapSearch && updateMarkers) {
            setMarkers(); // load new markers
        }
    }

    const debounceGetSearchListResults = debounce(getSearchListResults, 500);

    function getSearchListResults(deeplinkUrl) {
        const viewResultsButton = search.querySelector("button.view-results");
        const loading = search.querySelector(".profile-cards .loading");
        const loadingSpinner = search.querySelector(
            ".profile-cards .loading-spinner",
        );

        fetch(deeplinkUrl, {
            credentials: "same-origin",
            method: "get",
        })
            .then((response) => response.text())
            .then(function (responseHtmlText) {
                const parser = new DOMParser();
                const responseHtml = parser.parseFromString(
                    responseHtmlText,
                    "text/html",
                );

                const pageH1 = search.querySelector(".results h1");
                pageH1.textContent =
                    responseHtml.querySelector(".results h1").textContent;

                const pageLocationDescription = search.querySelector(
                    ".results .location-description",
                );
                pageLocationDescription.textContent =
                    responseHtml.querySelector(
                        ".results .location-description",
                    ).textContent;

                const pageLocationPayoff = search.querySelector(
                    ".results .location-payoff",
                );
                if (pageLocationPayoff) {
                    pageLocationPayoff.textContent = ""; // do not show payoff when continue searching
                }

                const pageIntro = search.querySelector(".results .page-intro");
                pageIntro.innerHTML = responseHtml.querySelector(
                    ".results .page-intro",
                ).innerHTML;

                const resultList = search.querySelector(
                    ".results .result-list",
                );
                resultList.innerHTML = responseHtml.querySelector(
                    ".results .result-list",
                ).innerHTML;

                const trimmedMean = search.querySelector(".trimmed-mean");
                trimmedMean.innerHTML =
                    responseHtml.querySelector(".trimmed-mean").innerHTML;

                /* Start Filters count */
                const activeFiltersCount = search.querySelector(
                    ".active-filters-count",
                );
                if (activeFiltersCount) {
                    const activeFiltersCountNew = responseHtml.querySelector(
                        ".active-filters-count",
                    );
                    activeFiltersCount.textContent =
                        activeFiltersCountNew.textContent;

                    if (parseInt(activeFiltersCountNew.dataset.count) === 0) {
                        activeFiltersCount.classList.add("d-none");
                    } else {
                        activeFiltersCount.classList.remove("d-none");
                    }
                }
                /* End Filters count */

                /* Start More Filters count */
                const activeMoreFiltersCount = search.querySelector(
                    ".active-more-filters-count",
                );
                if (activeMoreFiltersCount) {
                    const activeMoreFiltersCountNew =
                        responseHtml.querySelector(
                            ".active-more-filters-count",
                        );
                    activeMoreFiltersCount.textContent =
                        activeMoreFiltersCountNew.textContent;

                    if (
                        parseInt(activeMoreFiltersCountNew.dataset.count) === 0
                    ) {
                        activeMoreFiltersCount.classList.add("d-none");
                    } else {
                        activeMoreFiltersCount.classList.remove("d-none");
                    }
                }
                /* End More Filters count */

                /* Start Verification Filters count */
                const activeVerificationFiltersCount = search.querySelector(
                    ".active-verification-filters-count",
                );
                if (activeVerificationFiltersCount) {
                    const activeVerificationFiltersCountNew =
                        responseHtml.querySelector(
                            ".active-verification-filters-count",
                        );
                    activeVerificationFiltersCount.textContent =
                        activeVerificationFiltersCountNew.textContent;

                    if (
                        parseInt(
                            activeVerificationFiltersCountNew.dataset.count,
                        ) === 0
                    ) {
                        activeVerificationFiltersCount.classList.add("d-none");
                    } else {
                        activeVerificationFiltersCount.classList.remove(
                            "d-none",
                        );
                    }
                }
                /* End Verification Filters count */

                /* Start Children Filters count */
                const activeChildrenFiltersCount = search.querySelector(
                    ".active-children-filters-count",
                );
                if (activeChildrenFiltersCount) {
                    const activeChildrenFiltersCountNew =
                        responseHtml.querySelector(
                            ".active-children-filters-count",
                        );
                    activeChildrenFiltersCount.textContent =
                        activeChildrenFiltersCountNew.textContent;

                    if (
                        parseInt(
                            activeChildrenFiltersCountNew.dataset.count,
                        ) === 0
                    ) {
                        activeChildrenFiltersCount.classList.add("d-none");
                    } else {
                        activeChildrenFiltersCount.classList.remove("d-none");
                    }
                }
                /* End Children Filters count */

                /* Start UserType Filters count */
                const activeUserTypeFiltersCount = search.querySelector(
                    ".active-user-type-filters-count",
                );
                if (activeUserTypeFiltersCount) {
                    const activeUserTypeFiltersCountNew =
                        responseHtml.querySelector(
                            ".active-user-type-filters-count",
                        );
                    activeUserTypeFiltersCount.textContent =
                        activeUserTypeFiltersCountNew.textContent;

                    if (
                        parseInt(
                            activeUserTypeFiltersCountNew.dataset.count,
                        ) === 0
                    ) {
                        activeUserTypeFiltersCount.classList.add("d-none");
                    } else {
                        activeUserTypeFiltersCount.classList.remove("d-none");
                    }
                }
                /* End UserType Filters count */

                /* Start TypeBabysit Filters count */
                const activeTypeBabysitFiltersCount = search.querySelector(
                    ".active-type-babysit-filters-count",
                );
                if (activeTypeBabysitFiltersCount) {
                    const activeTypeBabysitFiltersCountNew =
                        responseHtml.querySelector(
                            ".active-type-babysit-filters-count",
                        );
                    activeTypeBabysitFiltersCount.textContent =
                        activeTypeBabysitFiltersCountNew.textContent;

                    if (
                        parseInt(
                            activeTypeBabysitFiltersCountNew.dataset.count,
                        ) === 0
                    ) {
                        activeTypeBabysitFiltersCount.classList.add("d-none");
                    } else {
                        activeTypeBabysitFiltersCount.classList.remove(
                            "d-none",
                        );
                    }
                }
                /* End TypeBabysit Filters count */

                /* Start Experience Filters count */
                const activeExperienceFiltersCount = search.querySelector(
                    ".active-experience-filters-count",
                );
                if (activeExperienceFiltersCount) {
                    const activeExperienceFiltersCountNew =
                        responseHtml.querySelector(
                            ".active-experience-filters-count",
                        );
                    activeExperienceFiltersCount.textContent =
                        activeExperienceFiltersCountNew.textContent;

                    if (
                        parseInt(
                            activeExperienceFiltersCountNew.dataset.count,
                        ) === 0
                    ) {
                        activeExperienceFiltersCount.classList.add("d-none");
                    } else {
                        activeExperienceFiltersCount.classList.remove("d-none");
                    }
                }
                /* End Experience Filters count */

                const savedSearchParams = document.getElementById(
                    "saved-search-params",
                );
                if (savedSearchParams) {
                    savedSearchParams.value = responseHtml.getElementById(
                        "saved-search-params",
                    ).value;
                }

                const toggleVideos = search.querySelectorAll(".toggle-video");
                toggleVideos.forEach(function (toggleVideo) {
                    manageToggleVideo(toggleVideo);
                });

                const paginationPages =
                    search.querySelectorAll(".pagination a");
                paginationPages.forEach(function (paginationPage) {
                    managePaginationPage(paginationPage);
                });

                const profileCards = search.querySelectorAll(".profile-card");
                profileCards.forEach(function (profileCard) {
                    manageProfileCards(profileCard);
                });

                viewResultsButton.innerHTML = responseHtml.querySelector(
                    "button.view-results",
                ).innerHTML;

                /* Send pageview to Google Analytics: https://developers.google.com/analytics/devguides/collection/gtagjs/pages */
                gtag("event", "page_view", {
                    page_path:
                        window.location.pathname + window.location.search,
                    send_to: "UA-4847692-5",
                });
            })
            .catch((error) => {
                loading.style.display = "none";
                loadingSpinner.style.display = "none";
            });
    }

    /* If users pushes the browser backbutton (popstate trigger), do a new search with pushstate=false */
    window.addEventListener("popstate", function (e) {
        const deeplinkUrl = window.location.href;
        searchAction(deeplinkUrl, true, false);
    });

    const filterDistances = document.querySelectorAll(
        "#searchfilter .distance .distance-option",
    );
    filterDistances.forEach(function (filterDistance) {
        filterDistance.addEventListener("click", (event) => {
            const selectedDistanceValue = document.querySelector(
                "#searchfilter .distance .value",
            );
            selectedDistanceValue.textContent = filterDistance.innerText;

            const queryBuilder = new URLSearchParams(
                window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D"),
            );

            const filterType = filterDistance.dataset.filterType.trim();
            const filterValue = filterDistance.dataset.filterValue.trim();

            queryBuilder.set(filterType, filterValue);

            queryBuilder.delete("page");

            const pathname = window.location.pathname;
            const deeplinkUrl = pathname + "?" + queryBuilder.toString(); // Load result page
            return searchAction(deeplinkUrl);
        });
    });

    const sortOrders = document.querySelectorAll(
        "#searchfilter .sort-order-filter .sort-option",
    );
    sortOrders.forEach(function (sortOrder) {
        sortOrder.addEventListener("click", (event) => {
            const selectedSortValue = document.querySelector(
                "#searchfilter .sort-order-filter .value",
            );
            selectedSortValue.textContent = sortOrder.innerText;

            const queryBuilder = new URLSearchParams(
                window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D"),
            );

            const filterType = sortOrder.dataset.filterType.trim();
            const filterValue = sortOrder.dataset.filterValue.trim();

            queryBuilder.set(filterType, filterValue);

            queryBuilder.delete("page");

            const pathname = window.location.pathname;
            const deeplinkUrl = pathname + "?" + queryBuilder.toString(); // Load result page
            return searchAction(deeplinkUrl);
        });
    });

    const filterCheckboxes = document.querySelectorAll(
        "#search .checkbox-container input",
    );
    filterCheckboxes.forEach(function (filterCheckbox) {
        filterCheckbox.addEventListener("change", (event) => {
            const queryBuilder = new URLSearchParams(
                window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D"),
            );

            const filterType = filterCheckbox
                .closest(".checkbox-container")
                .dataset.filterType.trim();
            const filterValue = filterCheckbox
                .closest(".checkbox-container")
                .dataset.filterValue.trim();

            /* If filterType user_type, than set default values */
            if (
                filterType === "user_type[]" &&
                !queryBuilder.has("user_type[]")
            ) {
                queryBuilder.append("user_type[]", "babysitter");
                queryBuilder.append("user_type[]", "nanny");
                queryBuilder.append("user_type[]", "childminder");
            }

            /* If filterType type_babysit, than set user_type to parent */
            if (filterType === "type_babysit[]") {
                if (event.target.checked) {
                    queryBuilder.delete("user_type[]");
                    queryBuilder.append("user_type[]", "parent");
                } else {
                    queryBuilder.delete("user_type[]");
                }
            }

            // If filterValue is true, than this filter type is a boolean
            if (filterValue === "true" || filterValue === "false") {
                queryBuilder.delete(filterType);
                if (event.target.checked) {
                    queryBuilder.set(filterType, filterValue);
                }

                queryBuilder.delete("page");

                const pathname = window.location.pathname;
                const deeplinkUrl = pathname + "?" + queryBuilder.toString(); // Load result page

                return searchAction(deeplinkUrl);
            }

            var currentFilterValues = queryBuilder.getAll(filterType);

            currentFilterValues = currentFilterValues.filter(
                (item) => item !== filterValue,
            ); // removes filterValue from array
            if (event.target.checked) {
                currentFilterValues.push(filterValue);
            }

            queryBuilder.delete(filterType);
            if (currentFilterValues) {
                currentFilterValues.forEach(function (value) {
                    queryBuilder.append(filterType, value);
                });
            }

            queryBuilder.delete("page");

            const pathname = window.location.pathname;
            const deeplinkUrl = pathname + "?" + queryBuilder.toString(); // Load result page

            return searchAction(deeplinkUrl);
        });
    });

    var setMarkerForSearchLocation = function (mapSearch, lat, lng) {
        /* Set user home address on the map */

        if (!lng || !lat) {
            return;
        }

        // create a DOM element for the marker
        var el = document.createElement("div");
        el.className = "marker-for-search-location";
        el.style.backgroundImage =
            "url(https://cdn.babysits.com/global/icons/maps/location-dot.svg)";
        el.style.backgroundSize = "cover";
        el.style.width = 23.5 + "px";
        el.style.height = 32 + "px";
        el.style.zIndex = 2;

        new maplibregl.Marker({ element: el })
            .setLngLat([lng, lat])
            .addTo(mapSearch);
    };

    const map = document.getElementById("map");
    const mapLoader = document.getElementById("map-loader");

    var setMarkerForUserLocation = function (mapSearch) {
        /* Set user home address on the map */
        const lat = map.dataset.forUserLat.trim();
        const lng = map.dataset.forUserLng.trim();

        if (!lng || !lat) {
            return;
        }

        // create a DOM element for the marker
        var markerLocation = document.createElement("div");
        markerLocation.className = "marker-for-user-location";
        markerLocation.style.backgroundImage =
            "url(https://cdn.babysits.com/global/icons/maps/home.svg)";
        markerLocation.style.backgroundSize = "cover";
        markerLocation.style.width = 36 + "px";
        markerLocation.style.height = 36 + "px";
        markerLocation.style.zIndex = 1;

        new maplibregl.Marker({ element: markerLocation })
            .setLngLat([lng, lat])
            .addTo(mapSearch);
    };

    var initialize_search_map = function () {
        const loadingSpinner = map.querySelector(".loading-spinner");

        loadingSpinner.style.display = "block";

        const lat = parseFloat(map.dataset.lat.trim());
        const lng = parseFloat(map.dataset.lng.trim());
        const zoom = parseInt(map.dataset.zoom.trim());

        const queryBuilder = new URLSearchParams(
            window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D"),
        );

        var center = [lng, lat];

        if (
            queryBuilder.has("ne_lat") &&
            queryBuilder.has("ne_lng") &&
            queryBuilder.has("sw_lat") &&
            queryBuilder.has("sw_lng")
        ) {
            var sw = new maplibregl.LngLat(
                queryBuilder.get("sw_lng"),
                queryBuilder.get("sw_lat"),
            );
            var ne = new maplibregl.LngLat(
                queryBuilder.get("ne_lng"),
                queryBuilder.get("ne_lat"),
            );

            // Assuming that coordinates cross the 180th meridian
            // Source https://github.com/mapbox/mapbox-gl-js/issues/3250
            // This is working as designed - if you need the line to cross the antimeridian, use a closer longitude beyond 180 - e.g. 179 to 188 instead of -172.
            if (
                queryBuilder.get("ne_lng") < 0 &&
                queryBuilder.get("sw_lng") > 0
            ) {
                ne = new maplibregl.LngLat(
                    360 + parseFloat(queryBuilder.get("ne_lng")),
                    queryBuilder.get("ne_lat"),
                );
            }

            var lngLatBounds = new maplibregl.LngLatBounds(sw, ne);

            center = lngLatBounds.getCenter();
        }

        const mapStyleLight = "a0930824-77bc-4c8b-a44d-a3860938876b";
        const mapStyleDark = "1342944f-2c70-4bca-83ff-f67e5c03409a";

        let mediaQueryObj = window.matchMedia("(prefers-color-scheme: dark)");
        let isDarkMode = mediaQueryObj.matches;
        let activeMode = isDarkMode ? "dark" : "light";

        function getStyleByMode(mode) {
            return mode === "dark" ? mapStyleDark : mapStyleLight;
        }

        var mapSearch = new maplibregl.Map({
            container: "map-search",
            style:
                "https://api.maptiler.com/maps/" +
                getStyleByMode(activeMode) +
                "/style.json?key=YJdaaNursTUUjcPnRyA5",
            center: center,
            zoom: zoom,
            scrollZoom: true,
            minZoom: 5,
            maxZoom: 15,
            dragRotate: false,
            touchRotate: false,
        });
        mapSearch.touchZoomRotate.disableRotation();

        if (lngLatBounds) {
            mapSearch.fitBounds(
                lngLatBounds,
                {
                    padding: { top: 0, bottom: 0, left: 0, right: 0 },
                    linear: true,
                    maxZoom: 15,
                },
                { initialFitBounds: true },
            );
        }

        mapSearch.addControl(
            new maplibregl.NavigationControl({
                showCompass: false,
            }),
        );

        mapSearch.addControl(
            new maplibregl.GeolocateControl({
                fitBoundsOptions: {
                    maxZoom: 13,
                },
            }),
        );

        mapSearch.on("load", function () {
            setMarkers();

            if (queryBuilder.get("lat") && queryBuilder.get("lng")) {
                setMarkerForSearchLocation(
                    mapSearch,
                    queryBuilder.get("lat"),
                    queryBuilder.get("lng"),
                );
            }

            setMarkerForUserLocation(mapSearch);

            loadingSpinner.style.display = "none";
        });

        var unsetGeohashQuery = function () {
            const queryBuilder = new URLSearchParams(
                window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D"),
            );

            if (!queryBuilder.has("geohash")) {
                // No geohash in request query, void
                return;
            }

            queryBuilder.delete("geohash");

            const pathname = window.location.pathname;
            const deeplinkUrl = pathname + "?" + queryBuilder.toString();

            searchAction(deeplinkUrl);
        };

        /* Onclick map: remove geohash request query */
        mapSearch.on("click", function () {
            unsetGeohashQuery();
        });

        window.mapSearch = mapSearch;
    };

    let markersList = [];

    // Removes the markers from the map
    var clearMarkers = function () {
        if (markersList) {
            markersList.forEach((marker) => {
                marker.remove();
            });
            markersList = [];
        }
    };

    var setMarkers = function () {
        var mapSearch = window.mapSearch;

        // Set url
        const userTypes = map.dataset.userTypes.trim().split(",");
        const typesBabysit = map.dataset.typesBabysit.trim().split(",");

        const queryBuilderMarkers = new URLSearchParams(
            window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D"),
        );

        queryBuilderMarkers.delete("lat");
        queryBuilderMarkers.delete("lng");
        queryBuilderMarkers.delete("geohash");
        queryBuilderMarkers.delete("page");
        queryBuilderMarkers.delete("city_id");
        queryBuilderMarkers.delete("distance");
        queryBuilderMarkers.delete("location_search");

        if (!queryBuilderMarkers.has("user_type[]") && userTypes) {
            queryBuilderMarkers.delete("user_type[]");
            userTypes.forEach(function (userType) {
                if (userType) {
                    queryBuilderMarkers.append("user_type[]", userType);
                }
            });
        }

        if (!queryBuilderMarkers.has("type_babysit[]") && typesBabysit) {
            queryBuilderMarkers.delete("type_babysit[]");
            typesBabysit.forEach(function (typeBabysit) {
                if (typeBabysit) {
                    queryBuilderMarkers.append("type_babysit[]", typeBabysit);
                }
            });
        }

        const ne = mapSearch.getBounds().getNorthEast().wrap(); // Returns a new LngLat object whose longitude is wrapped to the range (-180, 180).
        const sw = mapSearch.getBounds().getSouthWest().wrap(); // Returns a new LngLat object whose longitude is wrapped to the range (-180, 180).

        queryBuilderMarkers.set("ne_lat", ne.lat);
        queryBuilderMarkers.set("ne_lng", ne.lng);
        queryBuilderMarkers.set("sw_lat", sw.lat);
        queryBuilderMarkers.set("sw_lng", sw.lng);

        const markersApiUrl =
            "/website-api/user/search/markers/?" +
            queryBuilderMarkers.toString();

        mapLoader.classList.remove("d-none");

        debounceGetMarkers(markersApiUrl);

        return markersList;
    };

    const debounceGetMarkers = debounce(getMarkers, 500);

    function getMarkers(markersApiUrl) {
        fetch(markersApiUrl, {
            credentials: "same-origin",
            method: "get",
        })
            .then((response) => response.json())
            .then(function (jsonResponse) {
                const queryBuilderGeohash = new URLSearchParams(
                    window.location.search.replace(
                        /(%5B)[[\d]+(%5D)/g,
                        "%5B%5D",
                    ),
                );
                queryBuilderGeohash.delete("page");
                queryBuilderGeohash.delete("distance");

                /* First clear previous markers */
                clearMarkers();

                jsonResponse.data.result.forEach(function (resultMarker) {
                    const geohash = resultMarker.geohash;
                    const amount = resultMarker.amount;

                    const lng = resultMarker.lng;
                    const lat = resultMarker.lat;

                    let markerIcon = {};
                    let markerIconInactive = {};
                    let markerIconActive = {};

                    let active = false;

                    if (queryBuilderGeohash.get("geohash") === geohash) {
                        active = true;
                    }

                    if (amount === 1) {
                        markerIconInactive =
                            "https://cdn.babysits.com/global/icons/maps/marker-1.svg";
                        markerIconActive =
                            "https://cdn.babysits.com/global/icons/maps/marker-active-1.svg";
                    } else {
                        let amount_marker = amount;
                        // If amount is bigger than 9, display the "9plus marker"
                        if (amount > 9) {
                            amount_marker = "9plus";
                        }

                        markerIconInactive =
                            "https://cdn.babysits.com/global/icons/maps/marker-" +
                            amount_marker +
                            ".svg";
                        markerIconActive =
                            "https://cdn.babysits.com/global/icons/maps/marker-active-" +
                            amount_marker +
                            ".svg";
                    }

                    markerIcon = markerIconInactive;

                    // create a DOM element for the marker
                    const el = document.createElement("div");

                    el.activeMarker = false;
                    if (active) {
                        markerIcon = markerIconActive;
                        el.activeMarker = true;
                    }

                    el.className = "marker";
                    el.style.backgroundImage = "url(" + markerIcon + ")";
                    el.style.backgroundSize = "cover";
                    el.style.width = mapMarkerWidth;
                    el.style.height = mapMarkerHeight;
                    el.style.cursor = "pointer";
                    el.style.zIndex = 0;

                    el.geohash = geohash; // store for later re-use
                    el.markerIconActive = markerIconActive; // store for later re-use
                    el.markerIconInactive = markerIconInactive; // store for later re-use
                    el.markerIcon = markerIcon; // store for later re-use

                    el.addEventListener("mouseenter", function () {
                        el.style.backgroundImage =
                            "url(" + markerIconActive + ")";
                        el.style.zIndex = 4;
                        el.style.width = mapMarkerActiveWidth;
                        el.style.height = mapMarkerActiveHeight;
                    });

                    el.addEventListener("mouseout", function () {
                        el.style.backgroundImage = "url(" + markerIcon + ")";
                        el.style.zIndex = 0;
                        el.style.width = mapMarkerWidth;
                        el.style.height = mapMarkerHeight;
                    });

                    // Add eventlistener on map
                    mapSearch.on("defaultallmarkersstatus", function () {
                        el.style.backgroundImage =
                            "url(" + markerIconInactive + ")";
                        el.style.zIndex = 0;
                        el.style.width = mapMarkerWidth;
                        el.style.height = mapMarkerHeight;
                        el.markerIcon = markerIconInactive; // store for later re-use
                        markerIcon = markerIconInactive;

                        el.activeMarker = false;

                        // Keep this marker colored inactive
                        el.addEventListener("mouseout", function () {
                            el.style.backgroundImage =
                                "url(" + markerIconInactive + ")";
                            el.style.zIndex = 0;
                            el.style.width = mapMarkerWidth;
                            el.style.height = mapMarkerHeight;
                        });
                    });

                    el.addEventListener("click", function (e) {
                        /* Important: stop propagation onclick marker. Otherwise onclick map event will also be fired, which again unsets the geohash. */
                        e.stopPropagation();

                        if (e.currentTarget.activeMarker === true) {
                            // Deactivate
                            e.currentTarget.activeMarker = false;

                            e.currentTarget.style.backgroundImage =
                                "url(" + markerIconInactive + ")";
                            e.currentTarget.style.zIndex = 0;
                            e.currentTarget.style.width = mapMarkerWidth;
                            e.currentTarget.style.height = mapMarkerHeight;
                            e.currentTarget.markerIcon = markerIconInactive; // store for later re-use
                            markerIcon = markerIconInactive;

                            // Keep this marker colored inactive
                            e.currentTarget.addEventListener(
                                "mouseout",
                                function (e) {
                                    e.currentTarget.style.backgroundImage =
                                        "url(" + markerIconInactive + ")";
                                    e.currentTarget.style.zIndex = 0;
                                    e.currentTarget.style.width =
                                        mapMarkerWidth;
                                    e.currentTarget.style.height =
                                        mapMarkerHeight;
                                },
                            );

                            queryBuilderGeohash.delete("geohash");

                            const pathname = window.location.pathname;
                            const deeplinkUrl =
                                pathname + "?" + queryBuilderGeohash.toString(); // Load result page

                            searchAction(deeplinkUrl, false);
                        } else {
                            // Trigger custom event defaultmarkerstatus: to set all other markers to defaultmarkerstatus
                            mapSearch.fire("defaultallmarkersstatus");

                            // Activate
                            e.currentTarget.activeMarker = true;

                            e.currentTarget.style.backgroundImage =
                                "url(" + markerIconActive + ")";
                            e.currentTarget.style.zIndex = 3;
                            e.currentTarget.style.width = mapMarkerActiveWidth;
                            e.currentTarget.style.height =
                                mapMarkerActiveHeight;
                            e.currentTarget.markerIcon = markerIconActive; // store for later re-use
                            markerIcon = markerIconActive;

                            // Keep this marker colored active
                            e.currentTarget.addEventListener(
                                "mouseout",
                                function (e) {
                                    e.currentTarget.style.backgroundImage =
                                        "url(" + markerIconActive + ")";
                                    e.currentTarget.style.zIndex = 3;
                                    e.currentTarget.style.width =
                                        mapMarkerActiveWidth;
                                    e.currentTarget.style.height =
                                        mapMarkerActiveHeight;
                                },
                            );

                            queryBuilderGeohash.set("geohash", geohash);

                            const pathname = window.location.pathname;
                            const deeplinkUrl =
                                pathname + "?" + queryBuilderGeohash.toString(); // Load result page

                            searchAction(deeplinkUrl, false);
                        }
                    });

                    var marker = new maplibregl.Marker({ element: el })
                        .setLngLat([lng, lat])
                        .addTo(mapSearch);
                    markersList.push(marker);
                });

                mapLoader.classList.add("d-none");
            });
    }

    var reload_after_map_move = function (e) {
        // Map initialised for the first time, return
        if (e.initialFitBounds) {
            return;
        }

        if (
            window.getComputedStyle(map).getPropertyValue("display") === "none"
        ) {
            return;
        }

        const queryBuilder = new URLSearchParams(
            window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D"),
        );

        var ne = mapSearch.getBounds().getNorthEast().wrap(); // Returns a new LngLat object whose longitude is wrapped to the range (-180, 180).
        var sw = mapSearch.getBounds().getSouthWest().wrap(); // Returns a new LngLat object whose longitude is wrapped to the range (-180, 180).
        queryBuilder.set("ne_lat", ne.lat);
        queryBuilder.set("ne_lng", ne.lng);
        queryBuilder.set("sw_lat", sw.lat);
        queryBuilder.set("sw_lng", sw.lng);

        queryBuilder.delete("lat");
        queryBuilder.delete("lng");
        queryBuilder.delete("geohash");
        queryBuilder.delete("page");
        queryBuilder.delete("city_id");
        queryBuilder.delete("distance");
        queryBuilder.delete("location_search");

        const filterDistance = document.querySelector(
            "#searchfilter .distance",
        );
        if (filterDistance) {
            filterDistance.style.display = "none";
        }

        const pathname = window.location.pathname;
        const deeplinkUrl = pathname + "?" + queryBuilder.toString(); // Load result page

        searchAction(deeplinkUrl);
    };

    let mapLoaded = false;

    var initialize_maps = function () {
        /* Bots can affecting the mapbox billing by 60%. So we do not load the map for these bots anymore. */
        const listOfBots = [""];

        if (listOfBots.includes(navigator.userAgent)) {
            loadingSpinner.style.display = "block";

            return;
        }

        /* Prevent map being loaded for a second time */
        if (mapLoaded) {
            return;
        }

        initialize_search_map(); // Display map and save map variable

        var mapSearch = window.mapSearch;

        mapSearch.on("zoomend", function (e) {
            reload_after_map_move(e);
        });

        mapSearch.on("dragend", function (e) {
            reload_after_map_move(e);
        });

        mapLoaded = true;
    };

    if (window.getComputedStyle(map).getPropertyValue("display") !== "none") {
        initialize_maps(); // Load maps
    }

    const filterAvailability = document.getElementById("filter-availability");
    if (filterAvailability) {
        const filterAvailabilityInputs =
            filterAvailability.querySelectorAll("input");
        filterAvailabilityInputs.forEach(function (filterAvailabilityInput) {
            filterAvailabilityInput.addEventListener("change", (event) => {
                const queryBuilder = new URLSearchParams(
                    window.location.search.replace(
                        /(%5B)[[\d]+(%5D)/g,
                        "%5B%5D",
                    ),
                );

                queryBuilder.delete(
                    filterAvailabilityInput.getAttribute("name"),
                );
                if (filterAvailabilityInput.checked) {
                    queryBuilder.set(
                        filterAvailabilityInput.getAttribute("name"),
                        true,
                    );
                }

                queryBuilder.delete("page");

                const pathname = window.location.pathname;
                const deeplinkUrl = pathname + "?" + queryBuilder.toString(); // Load result page

                searchAction(deeplinkUrl);
            });
        });
    }

    const buttonShowAllLanguages = search.querySelector(
        "button.show-all-languages",
    );
    if (buttonShowAllLanguages) {
        buttonShowAllLanguages.addEventListener("click", (event) => {
            const allLanguages = search.querySelector(".all-languages");
            allLanguages.style.display = "block";

            buttonShowAllLanguages.style.display = "none";
        });
    }

    const filtersAge = search.querySelectorAll(".filter-age");
    filtersAge.forEach(function (filterAge) {
        const minAge = filterAge.querySelector(".min-age");
        const maxAge = filterAge.querySelector(".max-age");

        minAge.addEventListener("input", (event) => {
            const textMinAge = filterAge.querySelector(".text-min");
            textMinAge.textContent = minAge.value;

            clearTimeout(minTypingTimer);
            minTypingTimer = setTimeout(doneAgeChange, 400, minAge, maxAge);
        });
        maxAge.addEventListener("input", (event) => {
            const textMaxAge = filterAge.querySelector(".text-max");
            textMaxAge.textContent =
                maxAge.value === "95" ? "95+" : maxAge.value;

            clearTimeout(maxTypingTimer);
            maxTypingTimer = setTimeout(doneAgeChange, 400, minAge, maxAge);
        });
    });

    function doneAgeChange(minAge, maxAge) {
        const queryBuilder = new URLSearchParams(
            window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D"),
        );

        queryBuilder.delete("min_age");
        queryBuilder.delete("max_age");

        if (minAge.value) {
            queryBuilder.set("min_age", minAge.value);
        }

        if (maxAge.value) {
            queryBuilder.set("max_age", maxAge.value);
        }

        if (queryBuilder.get("min_age") === minAge.min) {
            queryBuilder.delete("min_age");
        }

        if (queryBuilder.get("max_age") === maxAge.max) {
            queryBuilder.delete("max_age");
        }

        queryBuilder.delete("page");

        const pathname = window.location.pathname;
        const deeplinkUrl = pathname + "?" + queryBuilder.toString(); // Load result page

        searchAction(deeplinkUrl);
    }

    const filtersExperience = search.querySelectorAll(".filter-experience");
    filtersExperience.forEach(function (filterExperience) {
        const minExperience = filterExperience.querySelector(".min-experience");
        const maxExperience = filterExperience.querySelector(".max-experience");

        minExperience.addEventListener("input", (event) => {
            const textMinExperience =
                filterExperience.querySelector(".text-min");
            textMinExperience.textContent = minExperience.value;

            clearTimeout(minTypingTimer);
            minTypingTimer = setTimeout(
                doneExperienceChange,
                400,
                minExperience,
                maxExperience,
            );
        });
        maxExperience.addEventListener("input", (event) => {
            const textMaxExperience =
                filterExperience.querySelector(".text-max");
            textMaxExperience.textContent = maxExperience.value;

            clearTimeout(maxTypingTimer);
            maxTypingTimer = setTimeout(
                doneExperienceChange,
                400,
                minExperience,
                maxExperience,
            );
        });
    });

    function doneExperienceChange(minExperience, maxExperience) {
        const queryBuilder = new URLSearchParams(
            window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D"),
        );

        queryBuilder.delete("min_experience");
        queryBuilder.delete("max_experience");

        if (minExperience.value) {
            queryBuilder.set("min_experience", minExperience.value);
        }

        if (maxExperience.value) {
            queryBuilder.set("max_experience", maxExperience.value);
        }

        if (queryBuilder.get("min_experience") === minExperience.min) {
            queryBuilder.delete("min_experience");
        }

        if (queryBuilder.get("max_experience") === maxExperience.max) {
            queryBuilder.delete("max_experience");
        }

        queryBuilder.delete("page");

        const pathname = window.location.pathname;
        const deeplinkUrl = pathname + "?" + queryBuilder.toString(); // Load result page

        searchAction(deeplinkUrl);
    }

    const filtersChildren = search.querySelectorAll(".filter-children");
    filtersChildren.forEach(function (filterChildren) {
        const minChildren = filterChildren.querySelector(".min-children");
        const maxChildren = filterChildren.querySelector(".max-children");

        minChildren.addEventListener("input", (event) => {
            const textMinChildren = filterChildren.querySelector(".text-min");
            textMinChildren.textContent = minChildren.value;

            clearTimeout(minTypingTimer);
            minTypingTimer = setTimeout(
                doneChildrenChange,
                400,
                minChildren,
                maxChildren,
            );
        });

        maxChildren.addEventListener("input", (event) => {
            const textMaxChildren = filterChildren.querySelector(".text-max");
            textMaxChildren.textContent = maxChildren.value;

            clearTimeout(maxTypingTimer);
            maxTypingTimer = setTimeout(
                doneChildrenChange,
                400,
                minChildren,
                maxChildren,
            );
        });
    });

    function doneChildrenChange(minChildren, maxChildren) {
        const queryBuilder = new URLSearchParams(
            window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D"),
        );

        queryBuilder.delete("min_children");
        queryBuilder.delete("max_children");

        if (minChildren.value) {
            queryBuilder.set("min_children", minChildren.value);
        }

        if (maxChildren.value) {
            queryBuilder.set("max_children", maxChildren.value);
        }

        if (queryBuilder.get("min_children") === minChildren.min) {
            queryBuilder.delete("min_children");
        }

        if (queryBuilder.get("max_children") === maxChildren.max) {
            queryBuilder.delete("max_children");
        }

        queryBuilder.delete("page");

        const pathname = window.location.pathname;
        const deeplinkUrl = pathname + "?" + queryBuilder.toString(); // Load result page

        searchAction(deeplinkUrl);
    }

    /*
     * Rate filter
     */
    var minTypingTimer;
    var maxTypingTimer;

    const minRateAmount = document.getElementById("min-rate-amount");
    if (minRateAmount) {
        minRateAmount.addEventListener("keyup", (event) => {
            clearTimeout(minTypingTimer);
            minTypingTimer = setTimeout(doneMinRateTyping, 400);
        });
    }

    const maxRateAmount = document.getElementById("max-rate-amount");
    if (maxRateAmount) {
        maxRateAmount.addEventListener("keyup", (event) => {
            clearTimeout(minTypingTimer);
            maxTypingTimer = setTimeout(doneMaxRateTyping, 400);
        });
    }

    //User is "finished typing," do something
    var doneMinRateTyping = function () {
        const queryBuilder = new URLSearchParams(
            window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D"),
        );

        queryBuilder.delete("min_rate_amount");

        if (minRateAmount.value) {
            const currencyAmount = minRateAmount.value;
            // All API requests expect amounts to be provided in a currency’s smallest unit. For 10 USD, provide an amount value of 1000 (that is, 1000 cents). For 10 JPY, provide an amount value of 10 (that is, 10 yen as yen does not have cents).
            const currencyIso = minRateAmount.dataset.currency;
            const currencyFormatted = new Intl.NumberFormat("en-US", {
                currencyDisplay: "code",
                style: "currency",
                currency: currencyIso,
            }).format(currencyAmount); // Return EUR 1.00 and JPY 1
            const currencyAmountSmallestUnit = parseInt(
                currencyFormatted.replace(/\D/g, ""),
            ); // HACK: strips of any non-digits. Returns: 100 (for EUR) and 1 (for JPY)

            queryBuilder.set("min_rate_amount", currencyAmountSmallestUnit);
        }

        queryBuilder.delete("page");

        const pathname = window.location.pathname;
        const deeplinkUrl = pathname + "?" + queryBuilder.toString(); // Load result page

        searchAction(deeplinkUrl);
    };

    //User is "finished typing," do something
    var doneMaxRateTyping = function () {
        const queryBuilder = new URLSearchParams(
            window.location.search.replace(/(%5B)[[\d]+(%5D)/g, "%5B%5D"),
        );

        queryBuilder.delete("max_rate_amount");

        if (maxRateAmount.value) {
            const currencyAmount = maxRateAmount.value;
            // All API requests expect amounts to be provided in a currency’s smallest unit. For 10 USD, provide an amount value of 1000 (that is, 1000 cents). For 10 JPY, provide an amount value of 10 (that is, 10 yen as yen does not have cents).
            const currencyIso = maxRateAmount.dataset.currency;
            const currencyFormatted = new Intl.NumberFormat("en-US", {
                currencyDisplay: "code",
                style: "currency",
                currency: currencyIso,
            }).format(currencyAmount); // Return EUR 1.00 and JPY 1
            const currencyAmountSmallestUnit = parseInt(
                currencyFormatted.replace(/\D/g, ""),
            ); // HACK: strips of any non-digits. Returns: 100 (for EUR) and 1 (for JPY)

            queryBuilder.set("max_rate_amount", currencyAmountSmallestUnit);
        }

        queryBuilder.delete("page");

        const pathname = window.location.pathname;
        const deeplinkUrl = pathname + "?" + queryBuilder.toString(); // Load result page

        searchAction(deeplinkUrl);
    };
});
