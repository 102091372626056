document.addEventListener("DOMContentLoaded", () => {
    
    const specialNeedsInput = document.getElementById('special-needs-btn');
    const specialNeedsTypes = document.querySelector(".special-needs-types");

    if (!specialNeedsInput) {
        return;
    }

    specialNeedsInput.addEventListener("change", (event) => {
        if (specialNeedsInput.checked) {
            specialNeedsTypes.classList.remove("d-none");

            return;
        }

        specialNeedsTypes.classList.add("d-none");
    });
});
