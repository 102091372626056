import {
    goToStep,
    hideAllErrors,
    sendData,
    showError,
    showGroupError,
} from "./wizzard-utils";

document.addEventListener("DOMContentLoaded", () => {
    const sitterWizard = document.getElementById("sitter-wizard");

    if (!sitterWizard) {
        return;
    }

    const nextBtn = sitterWizard.querySelector(".next-step-btn");

    const aboutYourself = sitterWizard.querySelector(
        "#sitters-wizard-about-yourself",
    );
    const experienceAndSkills = sitterWizard.querySelector(
        "#sitters-wizard-experience-and-skills",
    );
    const yourWorkingConditions = sitterWizard.querySelector(
        "#sitters-wizard-your-working-conditions",
    );
    const moreAboutYou = sitterWizard.querySelector(
        "#sitters-wizard-more-about-you",
    );
    const photo = sitterWizard.querySelector("#sitters-wizard-photo");
    const yourPreferences = sitterWizard.querySelector(
        "#sitters-wizard-your-preferences",
    );
    const suggestions = sitterWizard.querySelector(
        "#sitters-wizard-suggestions",
    );

    if (suggestions) {
        const inactiveFavorites =
            suggestions.querySelectorAll(".favorite.inactive");
        inactiveFavorites.forEach((inactiveFavorite) => {
            const defaultIcon = inactiveFavorite.querySelector(".icon");
            const spinner = inactiveFavorite.querySelector(".spinner");

            defaultIcon.classList.add("d-none");
            spinner.classList.remove("d-none");

            setTimeout(function () {
                defaultIcon.classList.remove("d-none");
                spinner.classList.add("d-none");
            }, 5000);
        });
    }

    if (aboutYourself) {
        nextBtn.addEventListener("click", (event) => {
            event.preventDefault();

            hideAllErrors();

            const firstName = sitterWizard.querySelector("#first_name").value;

            if (!firstName) {
                showError();

                return;
            }

            const selectedAddress = aboutYourself
                .querySelector(".edit-address-wrapper")
                .getAttribute("data-address-selected-value");
            const selectedCity = aboutYourself
                .querySelector("#city-input")
                .getAttribute("data-city-selected-value");

            if (selectedAddress.length === 0) {
                showError();

                return;
            }

            if (selectedCity.length === 0) {
                showError();

                return;
            }

            const birthdate = sitterWizard.querySelector("#birthdate").value;

            if (!birthdate) {
                showError();

                return;
            }

            const regex = new RegExp("\\d{4}-\\d{2}-\\d{2}");

            if (!regex.test(birthdate)) {
                alert("Enter your date of birth like: yyyy-mm-dd");
                return;
            }

            const selectedLanguages =
                aboutYourself.querySelectorAll(".languages ul li");

            if (selectedLanguages.length === 0) {
                showError();

                return;
            }

            //Append data to the form
            const formData = new FormData();

            const characteristicsCheckboxes = aboutYourself.querySelectorAll(
                ".characteristics input[type=checkbox]:checked",
            );

            if (characteristicsCheckboxes.length < 3) {
                const characteristicsWrapper = aboutYourself.querySelector(
                    "#profile-edit-characteristics",
                );
                showGroupError(characteristicsWrapper);

                return;
            }

            characteristicsCheckboxes.forEach((checkbox) => {
                formData.append("characteristics[]", checkbox.value);
            });

            formData.append("first_name", firstName);
            formData.append("birthdate", birthdate);

            sendData("/website-api/profile/", formData, () => {
                goToStep(nextBtn.dataset.url);
            });
        });
    }

    if (experienceAndSkills) {
        const groupWrapper = experienceAndSkills.querySelector(
            ".age-groups-wrapper",
        );
        const skillsWrapper =
            experienceAndSkills.querySelector(".skills-wrapper");

        const ageGroupCheckboxes = experienceAndSkills.querySelectorAll(
            ".age-groups input[type=checkbox]",
        );
        const experience = experienceAndSkills.querySelector(".experience");

        nextBtn.addEventListener("click", (event) => {
            event.preventDefault();

            hideAllErrors();

            const formData = new FormData();

            //Experience and Age groups
            let ageGroupsChecked = false;

            ageGroupCheckboxes.forEach((checkbox) => {
                if (checkbox.checked) {
                    formData.append("age_groups[]", checkbox.value);
                    ageGroupsChecked = true;
                }
            });

            if (experience && !ageGroupsChecked) {
                showGroupError(groupWrapper);

                return;
            }

            //Special needs
            const hasSpecialNeedsBtn =
                sitterWizard.querySelector("#special-needs-btn");
            formData.append("special_needs", hasSpecialNeedsBtn.checked);

            const specialNeedsTypes = experienceAndSkills.querySelectorAll(
                ".special-needs-types input[type=checkbox]",
            );

            specialNeedsTypes.forEach((checkbox) => {
                if (checkbox.checked) {
                    formData.append("special_needs_types[]", checkbox.value);
                }
            });

            formData.append("experience", experience.value);

            //Applies to you
            const appliesToYou = experienceAndSkills.querySelectorAll(
                ".applies-to-you input.form-check-input",
            );

            appliesToYou.forEach((button) => {
                formData.append(button.value, button.checked);
            });

            //Skills
            const skills = experienceAndSkills.querySelectorAll(
                ".skills input.form-check-input",
            );
            let atLeastOneSkillChecked = false;

            skills.forEach((button) => {
                formData.append(button.value, button.checked);

                if (button.checked) {
                    atLeastOneSkillChecked = true;
                }
            });

            if (!atLeastOneSkillChecked) {
                showGroupError(skillsWrapper);

                return;
            }

            sendData("/website-api/profile/", formData, () => {
                goToStep(nextBtn.dataset.url);
            });
        });
    }

    if (yourWorkingConditions) {
        nextBtn.addEventListener("click", (event) => {
            event.preventDefault();

            hideAllErrors();

            const formData = new FormData();

            //Availability
            const availabilityFormData = new FormData();
            const availabilities = yourWorkingConditions.querySelectorAll(
                ".availability input",
            );
            let atLeastOneDayChecked = false;

            availabilities.forEach((checkbox) => {
                availabilityFormData.append(checkbox.name, checkbox.checked);

                if (checkbox.checked) {
                    atLeastOneDayChecked = true;
                }
            });

            if (!atLeastOneDayChecked) {
                showError();

                return;
            }

            //Rate
            const rateInput = sitterWizard.querySelector("#rate_amount");

            if (!rateInput.value) {
                showError();

                return;
            }

            const rateFormData = new FormData();
            rateFormData.append("rate_amount", rateInput.value);

            //Comfortable with
            const checkboxes = sitterWizard.querySelectorAll(
                "input.comfortable-with",
            );

            checkboxes.forEach((checkbox) => {
                formData.append(checkbox.dataset.type, checkbox.checked);
            });

            //Location
            const atFamilyBtn = sitterWizard.querySelector(
                "#babysits-location-at-family",
            );
            const atMyPlace = sitterWizard.querySelector(
                "#babysits-location-at-my-place",
            );

            if (!atFamilyBtn.checked && !atMyPlace.checked) {
                showError();

                return;
            }

            const locationValue = atFamilyBtn.checked
                ? atFamilyBtn.dataset.type
                : atMyPlace.dataset.type;

            formData.append("babysit_location", locationValue);

            sendData("/website-api/profile/", formData, () => {
                sendData(
                    "/website-api/users/availability/",
                    availabilityFormData,
                    () => {
                        sendData(
                            "/website-api/users/rate/",
                            rateFormData,
                            () => {
                                goToStep(nextBtn.dataset.url);
                            },
                        );
                    },
                );
            });
        });
    }

    if (moreAboutYou) {
        nextBtn.addEventListener("click", (event) => {
            event.preventDefault();

            hideAllErrors();

            const descriptionInput = sitterWizard.querySelector("#description");
            const value = descriptionInput.value.trim();
            const formData = new FormData();

            if (value.length < 200) {
                showError();

                return;
            }

            formData.append("description", value);

            sendData("/website-api/profile/", formData, () => {
                goToStep(nextBtn.dataset.url);
            });
        });
    }

    if (photo) {
        nextBtn.addEventListener("click", (event) => {
            event.preventDefault();

            hideAllErrors();

            const profilePhotoAdded =
                document.getElementById("profilePhotoSelect").dataset
                    .profilePhotoAdded;

            if (profilePhotoAdded === "false") {
                showError();

                return;
            }

            goToStep(nextBtn.dataset.url);
        });
    }

    if (yourPreferences) {
        nextBtn.addEventListener("click", (event) => {
            event.preventDefault();

            hideAllErrors();

            const prevStepBtn = sitterWizard.querySelector(".prev-step-btn");

            //Notifications
            const notifyYes = sitterWizard.querySelector("#notify-yes");
            const notifyNo = sitterWizard.querySelector("#notify-no");

            // Visibility
            const publicBtn = sitterWizard.querySelector("#visibility-public");
            const platformBtn = sitterWizard.querySelector(
                "#visibility-platform",
            );

            if (!notifyYes.checked && !notifyNo.checked) {
                showError();

                return;
            }

            if (!publicBtn.checked && !platformBtn.checked) {
                showError();

                return;
            }

            const formDataVisibility = new FormData();
            formDataVisibility.append(
                "visibility",
                publicBtn.checked ? "public" : "platform",
            );

            sendData(
                "/website-api/users/visibility/",
                formDataVisibility,
                () => {
                    if (notifyYes.checked) {
                        // Check if a user has already persisted saved search
                        fetch("/website-api/saved-search/", {
                            credentials: "same-origin",
                            method: "get",
                        })
                            .then((res) => res.json())
                            .then(function (json) {
                                if (json.metadata.code !== 200) {
                                    goToStep(nextBtn.dataset.url);

                                    return;
                                }

                                if (json.data.length > 0) {
                                    goToStep(nextBtn.dataset.url);

                                    return;
                                }

                                // User does not have persisted savedSearch.
                                const formDataNotifications = new FormData();
                                formDataNotifications.append(
                                    "saved_search_for_parents",
                                    !!notifyYes.checked,
                                );

                                sendData(
                                    "/website-api/users/saved-search/",
                                    formDataNotifications,
                                    () => {
                                        goToStep(nextBtn.dataset.url);
                                    },
                                );
                            });
                    } else {
                        goToStep(nextBtn.dataset.url);
                    }
                },
                () => {
                    goToStep(prevStepBtn.href);
                },
            );
        });
    }

    const toggleVideos = sitterWizard.querySelectorAll(".toggle-video");
    toggleVideos.forEach(function (toggleVideo) {
        manageToggleVideo(toggleVideo);
    });

    function manageToggleVideo(toggleVideo) {
        toggleVideo.addEventListener("click", () => {
            const videoId = toggleVideo.dataset.videoId;
            const video = document.getElementById(videoId);

            const iconPlay = toggleVideo.querySelector(".icon-play");
            const iconPause = toggleVideo.querySelector(".icon-pause");

            if (video.paused) {
                video.play();
                video.style.display = "block";

                iconPlay.style.display = "none";
                iconPause.style.display = "block";
            } else {
                video.pause();
                video.style.display = "none";

                iconPlay.style.display = "block";
                iconPause.style.display = "none";
            }

            video.onended = function () {
                iconPlay.style.display = "block";
                iconPause.style.display = "none";
            };
        });
    }
});
