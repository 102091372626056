document.addEventListener("DOMContentLoaded", () => {

    if (!document.getElementById("add-user-languages")) {
        return;
    }

    const selectInputs = document.querySelectorAll(".languages .form-select");

    function languageSelected(e) {
        e.preventDefault();

        const languageWrapper = e.target.closest(".languages");
        const spinner = languageWrapper.querySelector(".spinner");
        const listContainer = languageWrapper.querySelector(".list");
        const languageCode = this.value;
        const name = this.selectedOptions[0].label;

        /* Prevent setting language that has been set already. */
        let itemAlreadySet = false;
        const selectedItems = languageWrapper.querySelectorAll(".list li");

        selectedItems.forEach(function (selectedItem) {
            if (selectedItem.dataset.code === languageCode) {
                itemAlreadySet = true;

                selectedItem.style.fontWeight = "bold";
                selectedItem.style.backgroundColor = "#59BEC9";

                setTimeout(function () {
                    selectedItem.style.fontWeight = null;
                    selectedItem.style.backgroundColor = null;
                }, 400);
            }
        });

        if (!itemAlreadySet) {
            spinner.classList.remove("d-none");

            const sliderWrapper =
                languageWrapper.querySelector(".slider-wrapper");
            sliderWrapper.scrollLeft = sliderWrapper.scrollWidth;

            fetch(`/website-api/user/language/${languageCode}/`, {
                credentials: "same-origin",
                method: "put",
            })
                .then((response) => response.json())
                .then(function (json) {
                    if (json.metadata.code === 401) {
                        //unauthorized
                        window.location.href = "/";
                    }

                    spinner.classList.add("d-none");

                    const li =
                        '<li data-code="' +
                        languageCode +
                        '">' +
                        name +
                        '<span class="remove"><i class="fa-solid fa-xmark fa-fw"></i></span></li>';

                    listContainer.insertAdjacentHTML("beforeend", li);

                    sliderWrapper.scrollLeft = sliderWrapper.scrollWidth;
                });
        }

        this.selectedOptions[0].disabled = true;
        this.selectedOptions[0].classList.add("d-none");

        this.selectedIndex = 0;
    }

    //Select language
    selectInputs.forEach(function (select, index) {
        select.addEventListener("change", languageSelected);
        select.addEventListener("select", languageSelected);
    });

    //Remove language
    const removeItem = (e) => {
        e.preventDefault();

        if (!e.target.closest(".remove")) {
            return;
        }

        const languageWrapper = e.target.closest(".languages");
        const listContainer = languageWrapper.querySelector("ul");
        const selectBox = languageWrapper.querySelector("select");
        const spinner = languageWrapper.querySelector(".spinner");
        const itemContainer = e.target.closest("li");
        const languageCode = itemContainer.dataset.code;

        spinner.classList.remove("d-none");

        itemContainer.style.opacity = "0.5";

        fetch(`/website-api/user/language/${languageCode}/`, {
            credentials: "same-origin",
            method: "delete",
        })
            .then((response) => response.json())
            .then(function (json) {
                if (json.metadata.code === 401) {
                    //unauthorized
                    window.location.href = "/";
                }

                spinner.classList.add("d-none");

                listContainer.removeChild(itemContainer);

                selectBox.childNodes.forEach(function (option, index) {
                    if (option.value === languageCode) {
                        option.disabled = false;
                        option.classList.remove("d-none");
                    }
                });

                selectBox.selectedIndex = 0;
            });
    };

    const removeItemsLists = document.querySelectorAll(".languages .list");

    removeItemsLists.forEach(function (removeItemsList, index) {
        removeItemsList.addEventListener("click", removeItem, true);
    });
});
