import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";
import { showElement, hideElement } from "./utils";

export default class extends Controller {
    static values = { user: String, selected: String, country: String };
    static targets = [
        "input",
        "loader",
        "error",
        "cityCancelBtn",
        "cityEditBtn",
        "resultsContainer",
    ];

    cancel(e) {
        if (!this.selectedValue) {
            return;
        }

        this.inputTarget.disabled = true;
        this.inputTarget.value = this.selectedValue;
        this.inputTarget.classList.remove("error-displayed");

        hideElement(this.cityCancelBtnTarget);
        hideElement(this.errorTarget);
        showElement(this.cityEditBtnTarget);
    }

    edit(e) {
        this.inputTarget.disabled = false;
        this.inputTarget.value = "";

        if (this.selectedValue) {
            showElement(this.cityCancelBtnTarget);
        } else {
            hideElement(this.cityCancelBtnTarget);
        }

        hideElement(this.cityEditBtnTarget);
    }

    search(e) {
        const query = e.currentTarget.value;
        const controller = new AbortController();
        const signal = controller.signal;

        if (!query || !query.trim()) {
            return;
        }

        if (this.prevController) {
            this.prevController.abort();
        }

        const url = `/website-api/area/city/?q=${query}&country_iso=${this.countryValue}&view_id=${this.userValue}`;
        this.prevController = controller;

        showElement(this.loaderTarget);

        let debounceFetch = debounce(this.fetchData, 400);

        debounceFetch(url, signal, this);
    }

    showResults(data) {
        showElement(this.resultsContainerTarget);

        let linkCollections = "";

        for (const city of data) {
            /* Add tabindex to a element to make relatedTarget work in Safari */
            linkCollections += `<a data-id="${city.id}" data-title="${city.title}" data-type="href-action" data-action="click->city#addCity" tabindex="0">${city.title}</a>`;
        }

        this.resultsContainerTarget.innerHTML = linkCollections;
    }

    hideResults(e) {
        if (e.relatedTarget && e.relatedTarget.dataset.type === "href-action") {
            return;
        }

        hideElement(this.resultsContainerTarget);

        showElement(this.errorTarget);
    }

    addCity(e) {
        e.preventDefault();

        hideElement(this.resultsContainerTarget);
        showElement(this.loaderTarget);

        const formData = new FormData();
        formData.append("view_id", this.userValue); // For admin edit purposes
        formData.append("area_id", e.target.dataset.id);

        fetch("/website-api/user/city/", {
            credentials: "same-origin",
            method: "post",
            body: formData,
        })
            .then((response) => {
                if (response.status === 401) {
                    window.location.href = "/";
                }

                if (response.status === 429) {
                    throw new Error(
                        "Too many requests. Please try again later."
                    );
                }

                if (response.status === 500) {
                    throw new Error(
                        "Service unavailable. Please try again or contact customer service."
                    );
                }

                return response.json();
            })
            .then((data) => {
                hideElement(this.loaderTarget);

                if (data.metadata.code === 422) {
                    // Feature id does not have city or country.
                    alert("Input errors.");

                    this.inputTarget.disabled = true;
                    this.inputTarget.value = this.selectedValue;

                    return;
                }

                if (data.metadata.code === 400) {
                    // HTTP_BAD_REQUEST: something went wrong geocoding
                    alert(data.metadata.error_message);

                    this.inputTarget.disabled = true;
                    this.inputTarget.value = this.selectedValue;

                    return;
                }

                if (data.metadata.code === 200) {
                    hideElement(this.errorTarget);

                    this.inputTarget.value = e.target.dataset.title;
                    this.inputTarget.disabled = true;
                    this.inputTarget.dataset.id = e.target.dataset.id;
                    this.inputTarget.classList.remove("error-displayed");

                    this.selectedValue = this.inputTarget.value;

                    hideElement(this.cityCancelBtnTarget);
                    showElement(this.cityEditBtnTarget);
                }
            })
            .catch((error) => {
                alert(error);
            });
    }

    fetchData(url, signal, self) {
        //hideElement(self.resultsContainerTarget);

        fetch(url, { signal })
            .then((response) => {
                if (response.status === 401) {
                    window.location.href = "/";
                }
                if (response.status === 429) {
                    hideElement(self.loaderTarget);
                    alert("We're sorry, but you have sent too many requests to us recently. Please try again later.");

                    throw new Error("Too many requests");
                }
                if (response.status === 403) {
                    hideElement(self.loaderTarget);
                    alert("Request forbidden. If you are using an anonymous proxy or VPN you'll need to disable it to fully access Babysits.");

                    throw new Error("Anonymous proxy or VPN");
                }

                if (response.status >= 500) {
                    hideElement(self.loaderTarget);
                    alert("We ran into a problem. Please try again.");

                    throw new Error("500 problem.");
                }

                return response.json();
            })
            .then((json) => {
                hideElement(self.loaderTarget);

                if (!json.data.length) {
                    return;
                }

                self.showResults(json.data);
            })
            .catch((e) => {});
    }
}
