document.addEventListener("DOMContentLoaded", () => {
    const profileVideo = document.getElementById("profile-video");

    if (profileVideo) {
        const preview = document.getElementById("preview");
        const description = document.getElementById("description");
        const browserSupport = profileVideo.querySelector(".browser-support");
        const recording = document.getElementById("recording");
        const openCameraButton = document.getElementById("openCameraButton");
        const startRecordingButton = document.getElementById(
            "startRecordingButton"
        );
        const stopRecordingButton = document.getElementById(
            "stopRecordingButton"
        );

        const acceptDeclineButtons = document.getElementById("acceptDeclineButtons");
        const tryAgainVideoButton = document.getElementById("tryAgainVideoButton");
        const acceptVideoButton = document.getElementById("acceptVideoButton");

        const recordingProgressBar = document.getElementById(
            "recording-progress-bar"
        );
        const recordingProgressBarStatus = document.getElementById(
            "recording-progress-bar-status"
        );

        let mediaRecorder;

        const maximalVideoLengthInMS = 30000; // 1000 = 1 second. Set to 30 seconds instead of 40 seconds, because setTimeout may add some seconds/buggy.

        const loader = document.getElementById("loader");
        const uploader = document.getElementById("uploader");
        const success = profileVideo.querySelector(".success");

        /**
         * Check browser support for recording videos
         * @returns {boolean}
         */
        function hasGetUserMedia() {
            return !!(
                navigator.mediaDevices &&
                navigator.mediaDevices.getUserMedia &&
                window.MediaRecorder
            );
        }

        if (!hasGetUserMedia()) {
            browserSupport.classList.remove('d-none');
        } else {
            description.classList.remove('d-none');
        }

        /**
         * Example from: https://github.com/webrtc/samples/blob/gh-pages/src/content/getusermedia/record/js/main.js
         */
        openCameraButton.addEventListener("click", openCamera);

        function openCamera() {
            const mediaConstraints = {
                video: {
                    width: { max: 1280 },
                    height: { max: 720 },
                    facingMode: "user"
                },
                audio: true,
            };

            navigator.mediaDevices
                .getUserMedia(mediaConstraints)
                .then((stream) => {
                    description.classList.add('d-none');
                    loader.classList.remove('d-none');

                    preview.srcObject = stream;
                    preview.captureStream =
                        preview.captureStream || preview.mozCaptureStream;

                    /**
                     * Here we check the captureStream support. This is currently not supported in iOS 14.4
                     */
                    if (!preview.captureStream) {
                        loader.classList.add('d-none');
                        browserSupport.classList.remove('d-none');

                        return;
                    }

                    return new Promise(
                        (resolve) => (preview.onplaying = resolve)
                    ).then(() => {
                        loader.classList.add('d-none');
                        startRecordingButton.style.display = "block";
                    });
                })
                .catch(function (err) {
                    /* handle the error */
                    alert(err);
                });
        }

        startRecordingButton.addEventListener("click", (e) => {
            startRecording(preview.captureStream(), maximalVideoLengthInMS)
                .then((recordedChunks) => showRecordedVideo(recordedChunks))
                .catch(function (err) {
                    /* handle the error */
                    alert(err);
                });
        });

        tryAgainVideoButton.addEventListener("click", (e) => {
            acceptDeclineButtons.classList.add('d-none');

            preview.style.display = "block";
            recording.style.display = "none";
            startRecordingButton.style.display = "block";

            recording.pause();

            openCamera();
        });

        acceptVideoButton.addEventListener("click", (e) => {
            const userId = profileVideo.dataset.userId;

            const uri = "/website-api/users/" + userId + "/video/";
            const xhr = new XMLHttpRequest();
            const fd = new FormData();

            const uploadStatusPercentage = uploader.querySelector(
                ".upload-status-percentage"
            );
            const uploadStatusChecking = uploader.querySelector(
                ".upload-status-checking"
            );

            uploadStatusPercentage.textContent = "";

            recording.pause();
            recording.style.display = "none";

            acceptDeclineButtons.classList.add('d-none');

            uploader.classList.remove('d-none');
            uploadStatusChecking.classList.add('d-none');
            uploadStatusPercentage.classList.remove('d-none');

            xhr.upload.addEventListener(
                "progress",
                function (e) {
                    if (e.lengthComputable) {
                        const percentage = Math.round(
                            (e.loaded * 100) / e.total
                        );

                        uploadStatusPercentage.textContent = percentage + "%";

                        if (percentage === 100) {
                            uploadStatusPercentage.classList.add('d-none');
                            uploadStatusChecking.classList.remove('d-none');
                        }
                    }
                },
                false
            );

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4) {
                    uploader.classList.add('d-none');
                }

                if (xhr.readyState === 4 && xhr.status === 413) {
                    acceptDeclineButtons.classList.remove('d-none');

                    alert(
                        "Sorry, the video you are trying to upload is too large. Please contact customer service."
                    );

                    return;
                }

                if (xhr.readyState === 4 && xhr.status === 422) {
                    preview.style.display = "block";
                    recording.style.display = "none";
                    startRecordingButton.style.display = "block";

                    openCamera();

                    const jsonResponse = JSON.parse(xhr.response);

                    alert(jsonResponse.metadata.errors[0].error);

                    return;
                }

                if (xhr.readyState === 4 && xhr.status === 409) {
                    acceptDeclineButtons.classList.remove('d-none');

                    const jsonResponse = JSON.parse(xhr.response);

                    alert(jsonResponse.metadata.error_message);

                    return;
                }

                if (xhr.readyState === 4 && xhr.status === 200) {
                    acceptDeclineButtons.classList.add('d-none');

                    success.classList.remove('d-none');
                }
            };

            xhr.open("POST", uri);
            fd.append("file", recording.file, "recording.webm");
            // Initiate a multipart/form-data upload
            xhr.send(fd);
        });

        function startRecording(stream, stopLengthInMS) {
            startRecordingButton.style.display = "none";
            stopRecordingButton.style.display = "block";
            recordingProgressBarStatus.style.width = "0%";
            recordingProgressBar.style.display = "block";

            // var options;
            // if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
            //     options = {mimeType: 'video/webm; codecs=vp9'};
            // } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
            //     options = {mimeType: 'video/webm; codecs=vp8'};
            // } else {
            //     alert('Browser lacks support.');
            // }

            mediaRecorder = new MediaRecorder(stream);

            const data = [];

            mediaRecorder.ondataavailable = (event) => data.push(event.data);
            mediaRecorder.start();

            let elapsedTime = 0;

            const counter = setInterval(function () {
                if (elapsedTime < stopLengthInMS) {
                    elapsedTime = elapsedTime + 100;

                    recordingProgressBarStatus.style.width =
                        (elapsedTime / stopLengthInMS) * 100 + "%";
                } else {
                    mediaRecorder.stop();
                    stop(preview.srcObject);

                    clearInterval(counter);
                }
            }, 100);

            const stopped = new Promise((resolve, reject) => {
                mediaRecorder.onstop = resolve;
                mediaRecorder.onerror = (event) => reject(event.name);
            }).then(() => {
                clearInterval(counter);
            });

            return Promise.all([stopped]).then(() => data);
        }

        stopRecordingButton.addEventListener("click", (e) => {
            mediaRecorder.stop();

            stop(preview.srcObject);
        });

        function showRecordedVideo(recordedChunks) {
            stopRecordingButton.style.display = "none";
            recordingProgressBar.style.display = "none";

            preview.style.display = "none";
            recording.style.display = "block";

            acceptDeclineButtons.classList.remove('d-none');

            const recordedBlob = new Blob(recordedChunks, {
                type: "video/webm",
            });
            recording.src = URL.createObjectURL(recordedBlob);
            recording.file = recordedBlob;
        }
    }

    const profileVideoRemove = document.getElementById("profileVideoRemove");
    const profileVideoAdd = document.getElementById("profileVideoAdd");
    const profileVideoPreview = document.getElementById("profileVideoPreview");

    if (profileVideoRemove && profileVideoAdd && profileVideoPreview) {
        profileVideoRemove.addEventListener(
            "click",
            function (e) {
                const userId = profileVideoRemove.dataset.userId;

                const iconRemove =
                    profileVideoRemove.querySelector(".icon-remove");
                const iconSpinner =
                    profileVideoRemove.querySelector(".icon-spinner");

                iconRemove.style.display = "none";
                iconSpinner.style.display = "block";

                const uri = "/website-api/users/" + userId + "/video/";
                const xhr = new XMLHttpRequest();

                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        profileVideoRemove.style.display = "none";
                        profileVideoPreview.style.display = "none";
                        profileVideoAdd.style.display = "flex";

                        iconRemove.style.display = "block";
                        iconSpinner.style.display = "none";
                    }
                };

                xhr.open("DELETE", uri, true);
                xhr.send();
            },
            false
        );
    }

    function stop(stream) {
        stream.getTracks().forEach((track) => track.stop());
    }
});
