document.addEventListener("DOMContentLoaded", () => {
    if (!document.getElementById("profile-edit-characteristics")) {
        return;
    }

    const characteristics = document.querySelector('.characteristics');
    const characteristicsButtons = characteristics.querySelectorAll('.characteristic');
    const characteristicsError = characteristics.querySelector('.wizard-group-error');

    characteristicsButtons.forEach((checkbox) => {
        checkbox.addEventListener('click', (event) => {

            let checkedNum = 0;

            characteristicsButtons.forEach((checkbox) => {
                if (checkbox.checked) {
                    checkedNum++;
                }
            });

            characteristicsError.classList.add('d-none');
            if (checkedNum !== 3) {
                characteristicsError.classList.remove('d-none');
            }

            if (checkedNum > 3) {
                event.currentTarget.checked = false;
            }
        });
    });
});
