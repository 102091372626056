document.addEventListener("DOMContentLoaded", () => {
    if (!document.getElementById("babysits-for-work")) {
        return;
    }

    const changeAccountType = (e) => {
        const userId = e.target.dataset.userId;
    };

    const manageAccountActivation = (e) => {
        const memberId = e.target.dataset.memberId;
        const activationAction = e.target.dataset.activationAction;

        fetch(`/work/member/premium/${memberId}/${activationAction}/`, {
            credentials: "same-origin",
            method: "put",
        })
            .then((response) => response.json())
            .then(function (json) {
                // TODO: show error if it is an error
                //TODO: change status text if success
            });
    };

    const changeAccountTypesButtons = document.querySelectorAll(
        ".change-account-type"
    );
    const deactivateAccountButtons = document.querySelectorAll(
        ".change-account-type"
    );

    changeAccountTypesButtons.forEach((button) => {
        button.addEventListener("click", changeAccountType);
    });

    deactivateAccountButtons.forEach((button) => {
        button.addEventListener("click", manageAccountActivation);
    });
});
