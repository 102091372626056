import { Controller } from "@hotwired/stimulus";
import debounce from "lodash/debounce";
import { showElement, hideElement } from "./utils";

export default class extends Controller {
    static values = {
        url: String,
        lat: String,
        lng: String,
        nearbyTitle: String,
        nearbyError: String,
        nearbyErrorPermission: String,
    };
    static targets = [
        "input",
        "loader",
        "nearbyLink",
        "resultsContainer",
        "addressResults",
    ];

    showNearbyOption(e) {
        if (navigator) {
            showElement(this.nearbyLinkTarget);
        }
        showElement(this.resultsContainerTarget);
    }

    gotToNearby(e) {
        e.preventDefault();

        navigator.geolocation.getCurrentPosition(
            (position) => {
                document.location.href = `${this.urlValue}?lat=${position.coords.latitude}&lng=${position.coords.longitude}&query=${this.nearbyTitleValue}&location_search=nearby`;
            },
            (error) => {
                error.code === error.PERMISSION_DENIED
                    ? alert(this.nearbyErrorPermissionValue)
                    : alert(this.nearbyErrorValue);
            }
        );
    }

    performSearch(e) {
        const query = e.currentTarget.value.trim();
        const controller = new AbortController();
        const signal = controller.signal;

        if (!query) {
            return;
        }

        if (this.prevController) {
            this.prevController.abort();
        }

        const url = `/website-api/area/places/?q=${query}&proximity=${
            this.latValue + "," + this.lngValue
        }`;
        this.prevController = controller;

        showElement(this.loaderTarget);

        let debounceFetch = debounce(this.fetchData, 400);

        debounceFetch(url, signal, this);
    }

    goToSearchPage(e) {
        e.preventDefault();

        if (this.inputTarget.value && this.inputTarget.value.trim()) {
            hideElement(this.resultsContainerTarget);

            this.setSearchwordCookie(this.inputTarget.value);

            document.location.href =
                this.urlValue + "?searchword=" + this.inputTarget.value;

            return;
        }

        document.location.href = this.urlValue;
    }

    saveQuery(e) {
        // Just save the query to a cookie and don't do anything further
        this.setSearchwordCookie(e.target.innerText);
    }

    hideResults(e) {
        if (e.relatedTarget && e.relatedTarget.dataset.type === "href-action") {
            return;
        }

        hideElement(this.resultsContainerTarget);
    }

    showResults(data) {
        showElement(this.resultsContainerTarget);

        let linkCollections = "";

        for (const address of data) {
            let url = `${this.urlValue}?query=${address.title}&lat=${address.lat}&lng=${address.lng}&location_search=address`;

            /* Add tabindex to a element to make relatedTarget work in Safari */
            linkCollections += `<a href="${url}" data-type="href-action" tabindex="0" data-action="autocomplete#saveQuery">${address.title}</a>`;
        }

        this.addressResultsTarget.innerHTML = linkCollections;
    }

    fetchData(url, signal, self) {
        //hideElement(self.resultsContainerTarget);

        fetch(url, { signal })
            .then((response) => {
                if (response.status === 401) {
                    window.location.href = "/";
                }
                if (response.status === 429) {
                    hideElement(self.loaderTarget);
                    alert("We're sorry, but you have sent too many requests to us recently. Please try again later.");

                    throw new Error("Too many requests");
                }
                if (response.status === 403) {
                    hideElement(self.loaderTarget);
                    alert("Request forbidden. If you are using an anonymous proxy or VPN you'll need to disable it to fully access Babysits.");

                    throw new Error("Anonymous proxy or VPN");
                }

                if (response.status >= 500) {
                    hideElement(self.loaderTarget);
                    alert("We ran into a problem. Please try again.");

                    throw new Error("500 problem.");
                }

                return response.json();
            })
            .then((jsonResponse) => {
                hideElement(self.loaderTarget);

                if (!jsonResponse.data.length) {
                    return;
                }

                self.showResults(jsonResponse.data);
            })
            .catch((e) => {});
    }

    setSearchwordCookie(searchword) {
        const hostnameBase = window.location.hostname.replace(/^[^.]*./, "");

        document.cookie =
            "searchword=" +
            searchword +
            "; max-age=31536000; path=/; domain=" +
            hostnameBase +
            "; secure; samesite=lax";
    }
}
