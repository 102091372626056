document.addEventListener("DOMContentLoaded", () => {
    const forms = document.querySelectorAll("form");

    forms.forEach((form) => {
        form.addEventListener("submit", (e) => {
            let isValid = validate(e.target, true);

            if (!isValid) {
                e.preventDefault();
            }
        });
    });
});

const validate = (form, showSpinner) => {
    let validationError = false;

    const items = form.querySelectorAll("[data-validation]");
    const itemsArray = Array.prototype.slice.call(items).reverse();
    const submitButton = form.querySelector('[type="submit"]');

    itemsArray.forEach((item) => {
        const validation = item.dataset.validation;
        const errorMessage = item.dataset.error;
        const disabled = item.disabled;
        let value = item.value;

        /* Skip any disabled fields */
        if (disabled) {
            errorHide(item, errorMessage);
            return;
        }

        switch (validation) {
            case "empty":
                if (value === "") {
                    errorDisplay(item, errorMessage);
                    validationError = true;
                } else {
                    errorHide(item, errorMessage);
                }
                break;
            case "checked":
                if (item.checked === false) {
                    errorDisplay(item, errorMessage);
                    validationError = true;
                } else {
                    errorHide(item, errorMessage);
                }
                break;
            case "radio":
                let radioChecked = item.querySelector("input:checked");

                if (!radioChecked) {
                    errorDisplay(item, errorMessage);
                    validationError = true;
                } else {
                    errorHide(item, errorMessage);
                }
                break;
            case "uploadimage":
                let filename = document.getElementById("upload").value;
                let ext = filename.split(".").pop().toLowerCase();
                if (filename === "") {
                    errorDisplay(item, errorMessage);
                    validationError = true;
                } else if (!ext.match(/(gif|jpeg|jpg|png)/)) {
                    errorDisplay(item, errorMessage);
                    validationError = true;
                } else {
                    errorHide(item, errorMessage);
                }
                break;
            case "minLength":
                let length = item.getAttribute("minlength");
                if (value.length < length) {
                    errorDisplay(item, errorMessage);
                    validationError = true;
                } else {
                    errorHide(item, errorMessage);
                }
                break;
            case "selectedId":
                let selectedId = item.dataset.id;
                if (selectedId === "") {
                    errorDisplay(item, errorMessage);
                    validationError = true;
                } else {
                    errorHide(item, errorMessage);
                }
                break;
            case "email":
                if (
                    !value.match(
                        /^[_\.0-9a-zA-Z-]+@([0-9a-zA-Z][0-9a-zA-Z-]+\.)+[a-zA-Z]{2,6}$/i
                    )
                ) {
                    errorDisplay(item, errorMessage);
                    validationError = true;
                } else {
                    errorHide(item, errorMessage);
                }
                break;
            case "numeric":
                value = value.replace(/\,/g, "."); // replace dots with comma's
                if (isNaN(value)) {
                    errorDisplay(item, errorMessage);
                    validationError = true;
                } else {
                    errorHide(item, errorMessage);
                }
                break;
        }
    });

    if (validationError) {
        let pos = form
            .querySelector(".error-displayed")
            .getBoundingClientRect();
        let scrollToPosition = pos.top + window.scrollY - 133; // Scroll to this element minus 100 pixels, so also the label is displayed on mobile.

        scroll({
            top: scrollToPosition,
            behavior: "smooth",
        });

        return false;
    }

    if (!validationError) {
        if (showSpinner && submitButton) {
            // Do not post form twice
            if (form.beenSubmitted) {
                return false;
            }

            submitButton.disabled = true; // disable button

            submitButton.innerHTML =
                '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>\n' +
                '  <span class="visually-hidden">Loading...</span>';
            form.beenSubmitted = true;
        }

        return true;
    }

    return false;
};

function errorDisplay(box, errorMessage) {
    box.classList.add("error-displayed");

    const validationWrapper = box.closest(".validation-wrapper");

    if (validationWrapper) {
        validationWrapper.classList.add("error"); // add error class

        if (errorMessage) {
            // if error message is set
            let errorsMessages =
                validationWrapper.querySelectorAll(".form-text.error");

            if (!errorsMessages.length) {
                // add error message if not yet added
                let span = document.createElement("span");
                span.classList.add("form-text", "error");
                span.innerText = errorMessage;

                validationWrapper.appendChild(span);
            } else {
                // change error message if already added
                errorsMessages.forEach((error) => {
                    error.innerText = errorMessage;
                });
            }
        }
        return;
    }
}

function errorHide(box, errorMessage) {
    box.classList.remove("error-displayed");

    const validationWrapper = box.closest(".validation-wrapper");

    if (validationWrapper) {
        validationWrapper.classList.remove("error");

        let displayedErrors = validationWrapper.querySelectorAll(".error-displayed");

        if (errorMessage) {
            // if error message is set
            if (!displayedErrors.length) {
                // remove error message if no items in this row have errors
                let errorsMessages =
                    validationWrapper.querySelectorAll(".form-text.error");

                errorsMessages.forEach((error) => {
                    error.remove();
                });
            }
        }
        return;
    }
}

export default validate;
