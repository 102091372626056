document.addEventListener("DOMContentLoaded", () => {
    const phoneVerification = document.getElementById("phone-verification");

    if (!phoneVerification) {
        return;
    }

    const resultDiv = phoneVerification.querySelector(
        ".verifications-form-phone-result"
    );

    const phoneVerified = phoneVerification.querySelector(".phone-verified");
    const phoneVerify = phoneVerification.querySelector(".phone-verify");
    const phoneNotVerified = phoneVerification.querySelector(
        ".phone-not-verified"
    );

    const spinner = resultDiv.querySelector(".spinner");

    // Phone enter
    const phoneEnterWrapper = phoneVerification.querySelector(".phone-enter");

    // Phone verification
    const verificationCodeSent = phoneVerification.querySelector(
        ".verification-code-sent"
    );
    const verificationCodeSentAgain = phoneVerification.querySelector(
        ".verification-code-sent-again"
    );

    // Errors
    const errorsWrapper = phoneVerification.querySelector(".errors");
    const unavailableServiceError = errorsWrapper.querySelector(
        ".errors .error-phone-unavailable-service"
    );
    const limitVerificationError = errorsWrapper.querySelector(
        ".errors .error-limit-verifications"
    );

    const hideSiblingsElements = (element) => {
        const descendants = element.parentNode.children;
        const siblings = Array.prototype.filter.call(
            descendants,
            function (sibling) {
                return sibling !== element;
            }
        );

        siblings.forEach((element) => {
            element.classList.add("d-none");
        });
    };

    const hideAllMessages = () => {
        spinner.classList.add("d-none");
        errorsWrapper.classList.add("d-none");
        phoneVerified.classList.add("d-none");
        phoneVerify.classList.add("d-none");
        phoneNotVerified.classList.add("d-none");
    };

    const setPhoneNumber = (phoneNumber) => {
        const phoneNumberCollection =
            phoneVerification.querySelectorAll(".phone-number");

        phoneNumberCollection.forEach((element) => {
            element.innerText = phoneNumber;
        });
    };

    function addPhone() {
        const phone =
            phoneVerification.querySelector(".phone-enter input").value;
        const phonePrefix = phoneVerification.querySelector(
            ".phone-enter .phone_prefix"
        ).value;

        const formData = new FormData();
        formData.append("phone", phone);
        formData.append("phone_prefix", phonePrefix);

        fetch("/website-api/phone/", {
            credentials: "same-origin",
            method: "post",
            body: formData,
        })
            .then((response) => {
                if (response.status === 401) {
                    spinner.classList.add("d-none");

                    // Unauthorized
                    window.location.href = "/";

                    throw new Error("Unauthorized");
                }

                if (response.status === 429) {
                    spinner.classList.add("d-none");

                    alert(
                        "We're sorry, but you have sent too many requests to us recently. Please try again later."
                    );

                    throw new Error("Too many requests");
                }

                if (response.status === 500) {
                    spinner.classList.add("d-none");

                    // HTTP_INTERNAL_SERVER_ERROR: tell user service is unavailable
                    errorsWrapper.classList.remove("d-none");
                    hideSiblingsElements(errorsWrapper);

                    unavailableServiceError.classList.remove("d-none");
                    hideSiblingsElements(unavailableServiceError);

                    throw new Error("Service is unavailable");
                }

                return response.json();
            })
            .then((jsonResponse) => {
                if (jsonResponse.metadata.code >= 400) {
                    spinner.classList.add("d-none");
                    
                    if (jsonResponse.metadata.errors) {
                        alert(jsonResponse.metadata.errors[0].error);

                        return;
                    }

                    if (jsonResponse.metadata.error_message) {
                        alert(jsonResponse.metadata.error_message);

                        return;
                    }

                    alert(
                        "Something went wrong. Please contact customer service."
                    );

                    return;
                }

                phoneEnterWrapper.classList.add("d-none");

                fetch("/website-api/phone/verification/", {
                    credentials: "same-origin",
                    method: "get",
                })
                    .then((response) => {
                        if (response.status === 401) {
                            window.location.href = "/";

                            throw new Error("Unauthorized");
                        }

                        return response.json();
                    })
                    .then((jsonResponse) => {
                        spinner.classList.add("d-none");

                        setPhoneNumber(jsonResponse.data.phone);

                        /* Phone number is verified, display phone number is verified */
                        if (jsonResponse.data.verified === true) {
                            phoneVerified.classList.remove("d-none");
                            hideSiblingsElements(phoneNotVerified);

                            return;
                        }

                        phoneVerify.classList.remove("d-none");
                        hideSiblingsElements(phoneVerify);

                        if (jsonResponse.data.attempts === 1) {
                            verificationCodeSent.classList.remove("d-none"); // first message
                            verificationCodeSentAgain.classList.add("d-none");

                            return;
                        }

                        verificationCodeSentAgain.classList.remove("d-none"); // retry message
                        verificationCodeSent.classList.add("d-none");
                    })
                    .catch((error) => {
                    });
            })
            .catch((error) => {
            });
    }

    function verifyNumber() {
        resultDiv.classList.remove("d-none");
        spinner.classList.remove("d-none");
        hideSiblingsElements(spinner);

        const formData = new FormData();

        fetch("/website-api/phone/verification/", {
            credentials: "same-origin",
            method: "post",
            body: formData,
        })
            .then((response) => {

                if (response.status === 401) {
                    //Unauthorized
                    window.location.href = "/";

                    throw new Error("Unauthorized");
                }

                if (response.status === 429) {
                    resultDiv.classList.remove("d-none");
                    phoneNotVerified.classList.remove("d-none");
                    spinner.classList.add("d-none");

                    alert(
                        "We're sorry, but you have sent too many requests to us recently. Please try again later."
                    );

                    throw new Error("Too many requests");
                }

                if (response.status === 500) {
                    errorsWrapper.classList.remove("d-none");
                    hideSiblingsElements(errorsWrapper);

                    unavailableServiceError.classList.remove("d-none");
                    hideSiblingsElements(unavailableServiceError);

                    throw new Error("Service is unavailable");
                }

                return response.json();
            })
            .then((jsonResponse) => {

                fetch("/website-api/phone/verification/", {
                    credentials: "same-origin",
                    method: "get",
                })
                    .then((response) => {
                        if (response.status === 401) {
                            //Unauthorized
                            window.location.href = "/";

                            throw new Error("Unauthorized");
                        }

                        return response.json();
                    })
                    .then((jsonResponse) => {
                        spinner.classList.add("d-none");
                        phoneEnterWrapper.classList.add("d-none");

                        setPhoneNumber(jsonResponse.data.phone);

                        /* Phone number is verified, display phone number is verified */
                        if (jsonResponse.data.verified === true) {
                            phoneVerified.classList.remove("d-none");
                            hideSiblingsElements(phoneNotVerified);

                            return;
                        }

                        phoneVerify.classList.remove("d-none");
                        hideSiblingsElements(phoneVerify);

                        // Type SMS:
                        if (jsonResponse.data.attempts === 1) {
                            verificationCodeSent.classList.remove("d-none"); // first message
                            verificationCodeSentAgain.classList.add("d-none");

                            return;
                        }

                        verificationCodeSentAgain.classList.remove("d-none"); // retry message
                        verificationCodeSent.classList.add("d-none");
                    })
                    .catch((error) => {
                    });
            })
            .catch((error) => {
            });
    }

    const phoneAddBtn = phoneVerification.querySelector(".phone-add");
    phoneAddBtn.addEventListener("click", (e) => {
        e.preventDefault();

        resultDiv.classList.remove("d-none");

        spinner.classList.remove("d-none");
        hideSiblingsElements(spinner);

        addPhone();
    });

    const phoneVerifySms = phoneVerification.querySelector(".phone-verify-sms");
    phoneVerifySms.addEventListener("click", (e) => {
        e.preventDefault();

        verifyNumber();
    });

    const phoneRemoveCollection = document.querySelectorAll(".phone-remove");
    phoneRemoveCollection.forEach((element) => {
        element.addEventListener("click", phoneRemoveHandler);
    });

    function phoneRemoveHandler(e) {
        spinner.classList.remove("d-none");

        fetch("/website-api/user/phone/", {
            credentials: "same-origin",
            method: "delete",
        })
            .then((response) => {
                if (response.status === 401) {
                    window.location.href = "/";

                    throw new Error("Unauthorized");
                }

                if (response.status === 429) {
                    spinner.classList.add("d-none");

                    alert(
                        "We're sorry, but you have sent too many requests to us recently. Please try again later."
                    );

                    throw new Error("Too many attempts");
                }

                return response.json();
            })
            .then((jsonResponse) => {
                spinner.classList.add("d-none");
                resultDiv.classList.add("d-none");

                phoneVerification.querySelector(".phone-enter input").value =
                    "";
                phoneEnterWrapper.classList.remove("d-none");
            })
            .catch((error) => {
            });
    }

    const phoneVerifyEnterCode = phoneVerification.querySelector(
        ".phone-verify-enter-code"
    );
    phoneVerifyEnterCode.addEventListener("click", (e) => {
        const verificationPhoneCode = document.querySelector(
            "input.verification_phone_code"
        ).value;

        spinner.classList.remove("d-none");

        const formData = `verification_phone_code=${verificationPhoneCode}`;

        fetch("/website-api/phone/verification/", {
            credentials: "same-origin",
            method: "PATCH",
            body: formData,
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded; charset=UTF-8",
            },
        })
            .then((response) => {
                if (response.status === 401) {
                    // Unauthorized
                    window.location.href = "/";

                    throw new Error("Unauthorized");
                }

                if (response.status === 429) {
                    spinner.classList.add("d-none");

                    alert(
                        "We're sorry, but you have sent too many requests to us recently. Please try again later."
                    );

                    throw new Error("Too many requests");
                }

                return response.json();
            })
            .then((jsonResponse) => {

                if (jsonResponse.metadata.code >= 400) {
                    spinner.classList.add("d-none");
                    if (jsonResponse.metadata.errors) {
                        alert(jsonResponse.metadata.errors[0].error);

                        return;
                    }

                    if (jsonResponse.metadata.error_message) {
                        alert(jsonResponse.metadata.error_message);

                        return;
                    }

                    alert(
                        "Something went wrong. Please contact customer service."
                    );

                    return;
                }

                hideAllMessages();

                if (jsonResponse.metadata.code === 200) {
                    fetch("/website-api/phone/verification/", {
                        credentials: "same-origin",
                        method: "get",
                    })
                        .then((response) => {
                            if (response.status === 401) {
                                window.location.href = "/";

                                throw new Error("Unauthorized");
                            }

                            return response.json();
                        })
                        .then((jsonResponse) => {
                            spinner.classList.add("d-none");

                            setPhoneNumber(jsonResponse.data.phone);

                            phoneVerified.classList.remove("d-none");
                        })
                        .catch((error) => {
                        });

                    setPhoneNumber(jsonResponse.data.phone);

                    phoneVerified.classList.remove("d-none");
                }

                phoneEnterWrapper.classList.add("d-none");
            })
            .catch((error) => {
            });
    });

    const cancelBtn = document.querySelector(".phone-verify .cancel");
    cancelBtn.addEventListener("click", (e) => {
        spinner.classList.remove("d-none");

        fetch("/website-api/phone/verification/", {
            credentials: "same-origin",
            method: "get",
        })
            .then((response) => {
                if (response.status === 401) {
                    window.location.href = "/";

                    throw new Error("Unauthorized");
                }

                return response.json();
            })
            .then((jsonResponse) => {
                spinner.classList.add("d-none");

                if (jsonResponse.data.verified === true) {
                    phoneVerified.classList.remove("d-none");
                    hideSiblingsElements(phoneNotVerified);

                    return;
                }

                if (jsonResponse.data.attempts >= 3) {
                    // Limit of verification code requests reached, display message:
                    //Sorry, you reached your daily amount of SMS verifications. Try again tomorrow.

                    errorsWrapper.classList.remove("d-none");
                    hideSiblingsElements(errorsWrapper);

                    limitVerificationError.classList.remove("d-none");
                    hideSiblingsElements(limitVerificationError);

                    return;
                }

                phoneNotVerified.classList.remove("d-none");
                hideSiblingsElements(phoneNotVerified);
            })
            .catch((error) => {
            });
    });
});
