import autosize from "autosize";

document.addEventListener("DOMContentLoaded", () => {
    const profileDescription = document.getElementById('profile-description');

    if (!profileDescription) {
        return;
    }

    const descriptionTextarea = profileDescription.querySelector(
        "textarea"
    );
    const textareaCountdown = profileDescription.querySelector(
        ".countdown"
    );

    /* Profile Edit description countdown */
    descriptionTextarea.addEventListener("keyup", (event) => {
        displayCharactersRemaining();
    });

    function displayCharactersRemaining() {
        const length = descriptionTextarea.value.length;
        const minlength = descriptionTextarea.getAttribute("minlength");
        const remainingLength = minlength - length;

        if (remainingLength > 0) {
            textareaCountdown.textContent = length + "/200";
        } else {
            textareaCountdown.textContent = "";
        }
    }

    const viewDescriptionExamples = document.getElementById(
        "viewDescriptionExamples"
    );

    const descriptionExamples = document.getElementById(
        "descriptionExamples"
    );

    if (viewDescriptionExamples && descriptionExamples) {
        viewDescriptionExamples.addEventListener("click", (e) => {
            descriptionExamples.classList.toggle("d-none");

            if (!descriptionExamples.classList.contains("d-none")) {
                viewDescriptionExamples.scrollIntoView({block: "center"});
            }
        });
    }


    const suggestDescriptionButton = document.getElementById(
        "suggestDescription"
    );

    if (suggestDescriptionButton) {
        suggestDescriptionButton.addEventListener("click", (e) => {
            e.preventDefault();

            const suggestDescriptionIcon = suggestDescriptionButton.querySelector(
                ".icon"
            );

            const suggestDescriptionSpinner = suggestDescriptionButton.querySelector(
                ".spinner"
            );

            const suggestDescriptionIconTyping = suggestDescriptionButton.querySelector(
                ".icon-typing"
            );

            //show spinner
            suggestDescriptionIcon.classList.add("d-none");
            suggestDescriptionSpinner.classList.remove("d-none");
            suggestDescriptionIconTyping.classList.add("d-none");
            suggestDescriptionButton.disabled = true;

            fetch(
                "/website-api/profile/suggest-description/",
                {
                    credentials: "same-origin",
                    method: "post",
                }
            )
                .then((res) => res.json())
                .then(function (json) {
                    if (json.metadata.code === 406) {
                        // Target language invalid
                        alert(json.metadata.error_message);
                        return;
                    }

                    suggestDescriptionIcon.classList.add("d-none");
                    suggestDescriptionSpinner.classList.add("d-none");
                    suggestDescriptionIconTyping.classList.remove("d-none");

                    // Start type like a human
                    descriptionTextarea.value = '';
                    const description = json.data.description;
                    let n = 0;

                    (function loop() {
                        descriptionTextarea.value += description[n];
                        autosize.update(descriptionTextarea);

                        if (++n < description.length) {
                            setTimeout(loop, 8);
                            return;
                        }


                        displayCharactersRemaining();

                        suggestDescriptionIcon.classList.remove("d-none");
                        suggestDescriptionSpinner.classList.add("d-none");
                        suggestDescriptionIconTyping.classList.add("d-none");
                        suggestDescriptionButton.disabled = false;
                    })();
                    // End type like a human
                });
        });

    }


});
