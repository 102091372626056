document.addEventListener("DOMContentLoaded", () => {
    const subnav = document.querySelector(".subnav");

    if (!subnav) {
        return;
    }

    var subnavNav = subnav.querySelector("nav");

    const subnavActive = subnav.querySelector(".active");
    if (subnavActive) {
        /* Scroll to active horizontal menu position */
        subnavNav.scrollLeft =
            subnavActive.offsetLeft +
            subnavActive.clientWidth / 2 -
            subnavNav.clientWidth / 2;
    }

    const buttonScrollLeft = subnav.querySelector("button.scroll-left");
    buttonScrollLeft.addEventListener("click", (event) => {
        subnavNav.scrollLeft = 0;
    });

    const buttonScrollRight = subnav.querySelector("button.scroll-right");
    buttonScrollRight.addEventListener("click", (event) => {
        const navList = subnav.querySelector("ul");

        subnavNav.scrollLeft = navList.clientWidth;
    });

    let options = {
        root: subnavNav, // relative to document viewport
        rootMargin: "0px", // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: 0.9, // visible amount of item shown in relation to root
    };

    function onChangeFirstChild(changes, observer) {
        changes.forEach((change) => {
            if (change.intersectionRatio > 0.9) {
                buttonScrollLeft.style.display = "none";
            } else {
                buttonScrollLeft.style.display = "block";
            }
        });
    }

    var observerFirstChild = new IntersectionObserver(
        onChangeFirstChild,
        options
    );

    var firstChild = subnav.querySelector("nav li:first-child");
    observerFirstChild.observe(firstChild);

    function onChangeLastChild(changes, observer) {
        changes.forEach((change) => {
            if (change.intersectionRatio > 0.9) {
                buttonScrollRight.style.display = "none";
            } else {
                buttonScrollRight.style.display = "block";
            }
        });
    }

    var observerLastChild = new IntersectionObserver(
        onChangeLastChild,
        options
    );

    var lastChild = subnav.querySelector("nav li:last-child");
    observerLastChild.observe(lastChild);
});
