document.addEventListener("DOMContentLoaded", () => {
    const profile = document.getElementById("profile");

    if (!profile) {
        return;
    }

    const descriptionWrapper = profile.querySelector(".description-wrapper");

    if (!descriptionWrapper) {
        return;
    }

    const description = descriptionWrapper.querySelector(".description");
    const descriptionFull =
        descriptionWrapper.querySelector(".description-full");
    const buttonReadMore = descriptionWrapper.querySelector(".read-more");
    const buttonReadLess = descriptionWrapper.querySelector(".read-less");

    if (
        !description ||
        !descriptionFull ||
        !buttonReadMore ||
        !buttonReadLess
    ) {
        return;
    }

    buttonReadMore.addEventListener("click", (e) => {
        e.preventDefault();

        description.classList.add("d-none");
        buttonReadMore.classList.add("d-none");

        descriptionFull.classList.remove("d-none");
        buttonReadLess.classList.remove("d-none");
    });

    buttonReadLess.addEventListener("click", (e) => {
        e.preventDefault();

        description.classList.remove("d-none");
        buttonReadMore.classList.remove("d-none");

        descriptionFull.classList.add("d-none");
        buttonReadLess.classList.add("d-none");
    });
});
