document.addEventListener("DOMContentLoaded", () => {
    const referenceInvite = document.getElementById("reference-invite");

    if (!referenceInvite) {
        return;
    }
    const blockInviteByName = document.getElementById("block-invite-by-name");
    const blockGeneratedInvite = document.getElementById(
        "block-generated-invite"
    );

    const createInviteForm = blockInviteByName.querySelector(
        ".create-invite-form"
    );

    const buttonSubmit = createInviteForm.querySelector(".button-submit");

    const buttonReset = blockGeneratedInvite.querySelector(".button-reset");

    const inputUserId = blockInviteByName.querySelector(".user-id");
    const inputFirstName = blockInviteByName.querySelector(".first-name");

    const descriptionPreview =
        blockGeneratedInvite.querySelector(".description");

    const shareOnDevice = blockGeneratedInvite.querySelector(".share-on-device");

    createInviteForm.addEventListener("submit", (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("first_name", inputFirstName.value);

        fetch(
            "/website-api/user/" + inputUserId.value + "/references/invite/",
            {
                credentials: "same-origin",
                method: "post",
                body: formData,
            }
        )
            .then((response) => response.json())
            .then(function (jsonResponse) {
                if (jsonResponse.metadata.code !== 201) {
                    if (jsonResponse.metadata.errors) {
                        alert(jsonResponse.metadata.errors[0].error);

                        restForm();

                        return;
                    }

                    if (jsonResponse.metadata.error_message) {
                        alert(jsonResponse.metadata.error_message);

                        restForm();

                        return;
                    }

                    alert(
                        "Something went wrong. Please contact customer service."
                    );

                    restForm();
                }
                const description = jsonResponse.data.description;
                const url = jsonResponse.data.url;

                descriptionPreview.textContent = description + "\n" + url;

                shareOnDevice.dataset.shareText = description;
                shareOnDevice.dataset.shareUrl = url;

                blockInviteByName.style.display = "none";
                blockGeneratedInvite.style.display = "block";
            });
    });

    buttonReset.addEventListener("click", (e) => {
        restForm();
    });

    function restForm() {
        inputFirstName.value = "";
        descriptionPreview.textContent = "";

        buttonSubmit.disabled = false;

        buttonSubmit.textContent = buttonSubmit.dataset.text;

        blockInviteByName.style.display = "block";
        blockGeneratedInvite.style.display = "none";
    }
});
