import dialogPolyfill from "dialog-polyfill";

document.addEventListener("DOMContentLoaded", () => {
    /**
     * Register all dialogs using dialogPolyfill.registerDialog(), passing it one node at a time. This polyfill won't replace native support.
     * https://github.com/GoogleChrome/dialog-polyfill
     */
    const dialogs = document.querySelectorAll(".babysits-dialog");
    dialogs.forEach((dialog) => {
        dialogPolyfill.registerDialog(dialog);

        const closeButtons = dialog.querySelectorAll(".close-dialog");
        closeButtons.forEach((closeButton) => {
            closeButton.addEventListener(
                "click",
                (e) => {
                    dialog.close();

                    addClosingBackdrop();
                },
                { passive: true },
            );
        });

        /* Let's open the dialog automatically if it has the class active */
        if (dialog.classList.contains("active")) {
            dialog.showModal();

            closeDialogOnClickBackdrop(dialog);
        }
    });

    // Search page HTML is loaded dynamically: workaround for auth dialog open-buttons.
    const searchPageDiv = document.getElementById("search");
    if (searchPageDiv) {
        searchPageDiv.addEventListener(
            "click",
            (e) => {
                if (
                    !e.target.dataset.target ||
                    e.target.dataset.target !== "dialog-auth"
                ) {
                    return;
                }

                // Listener may not be passive for this to work on anchor-links
                e.preventDefault();

                const target = e.target.dataset.target;
                const dialog = document.getElementById(target);

                if (!dialog.open) {
                    // Create custom backdrop
                    const backdrop = document.createElement("div");
                    backdrop.classList.add("backdrop");
                    dialog.after(backdrop);

                    /**
                     * We cannot open auth dialogs with method .showModal(), because Google Recaptcha does not render the captcha within the dialog:
                     * https://github.com/google/recaptcha/issues/318
                     */
                    dialog.show();

                    closeDialogOnClickBackdrop(dialog);
                }
            },
            { passive: false },
        );
    }

    const showDialogs = document.querySelectorAll(".show-dialog");
    showDialogs.forEach((showDialog) => {
        showDialog.addEventListener(
            "click",
            (e) => {
                const target = showDialog.dataset.target;
                const dialog = document.getElementById(target);

                if (!dialog.open) {
                    dialog.showModal();

                    closeDialogOnClickBackdrop(dialog);
                }
            },
            { passive: true },
        );
    });

    const showDialogsSignup = document.querySelectorAll(".show-dialog-auth");
    showDialogsSignup.forEach((showDialog) => {
        showDialog.addEventListener(
            "click",
            (e) => {
                const target = showDialog.dataset.target;
                const dialog = document.getElementById(target);

                if (!dialog.open) {
                    // Create custom backdrop
                    const backdrop = document.createElement("div");
                    backdrop.classList.add("backdrop");
                    dialog.after(backdrop);

                    /**
                     * We cannot open auth dialogs with method .showModal(), because Google Recaptcha does not render the captcha within the dialog:
                     * https://github.com/google/recaptcha/issues/318
                     */
                    dialog.show();

                    closeDialogOnClickBackdrop(dialog);
                }
            },
            { passive: true },
        );
    });

    function closeDialogOnClickBackdrop(dialog) {
        // This does not work in Chrome and Safari..
        const backdrops = document.querySelectorAll(
            ".babysits-dialog::backdrop, .babysits-dialog + .backdrop",
        );
        backdrops.forEach((backdrop) => {
            backdrop.addEventListener(
                "click",
                (e) => {
                    dialog.close();
                    backdrop.remove();
                    addClosingBackdrop();
                },
                { passive: true },
            );
        });

        /**
         * Hack to close dialog onclick backdrop from:
         * https://stackoverflow.com/questions/25864259/how-to-close-the-new-html-dialog-tag-by-clicking-on-its-backdrop
         */
        dialog.addEventListener(
            "click",
            (event) => {
                /**
                 * Select boxes are in firefox positions outside the dialog.
                 * Kludge: to prevent the dialog being closed after clicking on a select option inside the dialog, check
                 * if the target is selected. If selected, than return.
                 */
                if (event.target.selected || event.target.selectedOptions) {
                    return;
                }

                if (event.target === dialog) {
                    dialog.close();

                    addClosingBackdrop();
                }
            },
            { passive: true },
        );
    }

    function addClosingBackdrop() {
        const backdrops = document.querySelectorAll(
            ".babysits-dialog::backdrop, .babysits-dialog + .backdrop",
        );
        backdrops.forEach((backdrop) => {
            backdrop.remove();
        });

        const body = document.querySelector("body");
        // Set closing class on backdrop, so it will transition to opacity=0
        const closingBackdrop = document.createElement("div");
        closingBackdrop.classList.add("closing-backdrop");
        body.append(closingBackdrop);

        setTimeout(function () {
            closingBackdrop.remove();
        }, 400);
    }
});
