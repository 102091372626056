import dayjs from 'dayjs'

document.addEventListener("DOMContentLoaded", () => {
    const profileEditBirthdate = document.querySelector(".profile-edit-birthdate");

    if (!profileEditBirthdate) {
        return;
    }

    const ageRestrictions = profileEditBirthdate.querySelector('.age-restriction-warning');

    if (!ageRestrictions) {
        return;
    }

    const birthdateInput = profileEditBirthdate.querySelector('#birthdate');

    birthdateInput.addEventListener('change', (e) => {
        const dayjsBirthday = dayjs(e.currentTarget.value, 'YYYY-MM-DD');

        const ageInYears = dayjs().diff(dayjsBirthday, 'year');

        ageInYears >= 18 ? ageRestrictions.classList.add('d-none') : ageRestrictions.classList.remove('d-none');
    })
});
