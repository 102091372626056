document.addEventListener("DOMContentLoaded", () => {
    const buttonsShareOnDevice = document.querySelectorAll(".share-on-device");

    if (!buttonsShareOnDevice) {
        return;
    }

    buttonsShareOnDevice.forEach((buttonShareOnDevice) => {
        buttonShareOnDevice.addEventListener("click", (e) => {
            const shareData = {
                title: buttonShareOnDevice.dataset.shareTitle,
                text: buttonShareOnDevice.dataset.shareText,
                url: buttonShareOnDevice.dataset.shareUrl
            }

            // Check if browser support Sharing
            if (navigator.canShare && navigator.canShare(shareData)) {
                try {
                    navigator.share(shareData);
                } catch (err) {
                }
                return;
            }

            let clipboardText = '';
            if (buttonShareOnDevice.dataset.shareTitle) {
                clipboardText = buttonShareOnDevice.dataset.shareTitle + ': ';
            }
            if (buttonShareOnDevice.dataset.shareText) {
                clipboardText = clipboardText + buttonShareOnDevice.dataset.shareText + ' ';
            }
            if (buttonShareOnDevice.dataset.shareUrl) {
                clipboardText = clipboardText + buttonShareOnDevice.dataset.shareUrl;
            }

            navigator.clipboard.writeText(clipboardText.trim()).then(
                () => {
                    /* clipboard successfully set */
                    alert(buttonShareOnDevice.dataset.shareClipboardSuccessText);
                },
                () => {
                    /* clipboard write failed */
                    alert('Copying to clipboard failed. Please manually copy the text.');
                }
            );
        });

    });
});
