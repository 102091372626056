const lottie = require("lottie-web");

document.addEventListener("DOMContentLoaded", () => {
    const lottieAddVideo = document.getElementById("lottie-add-video");
    if (lottieAddVideo) {
        lottie.loadAnimation({
            container: lottieAddVideo,
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "https://cdn.babysits.com/global/lottie/add-video.json", // the path to the animation json
        });
    }

    const lottieHello = document.getElementById("lottie-hello");
    if (lottieHello) {
        lottie.loadAnimation({
            container: lottieHello,
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "https://cdn.babysits.com/global/lottie/hello.json", // the path to the animation json
        });
    }

    const lottieNoAccess = document.getElementById("lottie-no-access");
    if (lottieNoAccess) {
        lottie.loadAnimation({
            container: lottieNoAccess,
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "https://cdn.babysits.com/global/lottie/no-access.json", // the path to the animation json
        });
    }

    const lottieNoBookings = document.getElementById("lottie-no-bookings");
    if (lottieNoBookings) {
        lottie.loadAnimation({
            container: lottieNoBookings,
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "https://cdn.babysits.com/global/lottie/no-bookings.json", // the path to the animation json
        });
    }

    const lottieNoConversations = document.getElementById(
        "lottie-no-conversations",
    );
    if (lottieNoConversations) {
        lottie.loadAnimation({
            container: lottieNoConversations,
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "https://cdn.babysits.com/global/lottie/no-conversations.json", // the path to the animation json
        });
    }

    const lottieNoFavorites = document.getElementById("lottie-no-favorites");
    if (lottieNoFavorites) {
        lottie.loadAnimation({
            container: lottieNoFavorites,
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "https://cdn.babysits.com/global/lottie/no-favorites.json", // the path to the animation json
        });
    }

    const lottieNoInternet = document.getElementById("lottie-no-internet");
    if (lottieNoInternet) {
        lottie.loadAnimation({
            container: lottieNoInternet,
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "https://cdn.babysits.com/global/lottie/no-internet.json", // the path to the animation json
        });
    }

    const lottieOops = document.getElementById("lottie-oops");
    if (lottieOops) {
        lottie.loadAnimation({
            container: lottieOops,
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "https://cdn.babysits.com/global/lottie/oops.json", // the path to the animation json
        });
    }

    const lottieLoaderMarkers = document.getElementById(
        "lottie-loader-markers",
    );
    if (lottieLoaderMarkers) {
        lottie.loadAnimation({
            container: lottieLoaderMarkers,
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "https://cdn.babysits.com/global/lottie/loader-markers.json",
        });
    }
});
