document.addEventListener("DOMContentLoaded", () => {
    const availabilityCalendar = document.getElementById("availability-calendar");

    if (!availabilityCalendar) {
        return;
    }

    const partOfDays = availabilityCalendar.querySelectorAll(
        "tr th:first-child"
    );
    const days = availabilityCalendar.querySelectorAll(
        "tr:first-child th"
    );

    partOfDays.forEach((item) => {
        item.addEventListener("click", (event) => {
            const rowIndex = item.closest("tr").rowIndex + 1;
            const inputs = availabilityCalendar.querySelectorAll(
                "tr:nth-child(" + rowIndex + ") input"
            );

            let allAreChecked = true;

            inputs.forEach((input) => {
                if (!input.checked) {
                    allAreChecked = false;
                }
            });

            // Uncheck all
            if (allAreChecked) {
                inputs.forEach((input) => {
                    input.checked = false;
                });
                return;
            }

            // Check all
            inputs.forEach((input) => {
                input.checked = true;
            });

        });
    });

    days.forEach((item) => {
        item.addEventListener("click", (event) => {
            const cellIndex = item.cellIndex + 1;
            const inputs = availabilityCalendar.querySelectorAll(
                "td:nth-child(" + cellIndex + ") input"
            );

            let allAreChecked = true;

            inputs.forEach((input) => {
                if (!input.checked) {
                    allAreChecked = false;
                }
            });

            // Uncheck all
            if (allAreChecked) {
                inputs.forEach((input) => {
                    input.checked = false;
                });
                return;
            }

            // Check all
            inputs.forEach((input) => {
                input.checked = true;
            });
        });
    });

});
