document.addEventListener("DOMContentLoaded", () => {
    const ratingRadios = document.querySelectorAll(".rating-radio");

    if (!ratingRadios) {
        return;
    }

    const setLabels = (ratingRadio, value) => {
        const unselectedValue = value + 1;
        const selectedLabels = ratingRadio.querySelectorAll(
            "label:nth-child(-n+" + value + ")"
        );
        const unselectedLabels = ratingRadio.querySelectorAll(
            "label:nth-child(n+" + unselectedValue + ")"
        );

        selectedLabels.forEach((selectedLabel) => {
            const icon = selectedLabel.querySelector(".icon");
            const iconActive = selectedLabel.querySelector(".iconActive");

            icon.classList.add("d-none");
            iconActive.classList.remove("d-none");
        });

        unselectedLabels.forEach((unselectedLabel) => {
            const icon = unselectedLabel.querySelector(".icon");
            const iconActive = unselectedLabel.querySelector(".iconActive");

            icon.classList.remove("d-none");
            iconActive.classList.add("d-none");
        });
    };

    const removeReviewValue = (e) => {
        const ratingRadio = e.currentTarget.closest(".rating-radio");
        const checkedInput = ratingRadio.querySelector("input:checked");
        let value = 0;

        if (checkedInput) {
            value = parseInt(checkedInput.value, 10);
        }

        setLabels(ratingRadio, value);
    };

    const addReviewValue = (e) => {
        const ratingRadio = e.currentTarget.closest(".rating-radio");

        let value = parseInt(e.currentTarget.dataset.value, 10);

        setLabels(ratingRadio, value);
    };



    ratingRadios.forEach((ratingRadio) => {
        const ratingLabels = ratingRadio.querySelectorAll(".labels label");

        ratingLabels.forEach((ratingLabel) => {
            ratingLabel.addEventListener("mouseleave", removeReviewValue);
        });

        ratingLabels.forEach((ratingLabel) => {
            ratingLabel.addEventListener("mouseenter", addReviewValue);
            ratingLabel.addEventListener("touchstart", addReviewValue, {
                passive: true,
            });
            ratingLabel.addEventListener("click", addReviewValue);
        });
    });
});
