document.addEventListener("DOMContentLoaded", () => {

    if (!document.querySelector(".profile-edit-properties")) {
        return;
    }

    const selectInputs = document.querySelectorAll(".profile-edit-properties .form-select");

    const toggleSuggestProperty = (select, makeVisible) => {
        const addWrapper = select
            .closest(".add")
            .querySelector(".suggest-property");

        addWrapper.classList.remove("d-none");
    };

    function propertySelected(e) {
        e.preventDefault();

        const propertyWrapper = e.target.closest(".profile-edit-properties");
        const spinner = propertyWrapper.querySelector(".spinner");
        const listContainer = propertyWrapper.querySelector(".list");
        const type = propertyWrapper.dataset.type;
        const propertyId = this.value;
        const name = this.selectedOptions[0].label;

        /* Toggle suggest property input visibility */
        if (this.value === "other") {
            toggleSuggestProperty(e.target);

            return;
        }

        /* Prevent setting property that has been set already. */
        let itemAlreadySet = false;
        const selectedItems = propertyWrapper.querySelectorAll(".list li");

        selectedItems.forEach(function (selectedItem) {
            if (selectedItem.dataset.id === propertyId) {
                itemAlreadySet = true;

                selectedItem.style.fontWeight = "bold";

                setTimeout(function () {
                    selectedItem.style.fontWeight = "normal";
                }, 400);
            }
        });

        if (!itemAlreadySet && propertyId > 0) {
            spinner.classList.remove("d-none");

            const sliderWrapper =
                propertyWrapper.querySelector(".slider-wrapper");
            sliderWrapper.scrollLeft = sliderWrapper.scrollWidth;

            fetch(`/website-api/user/property/${type}/${propertyId}/`, {
                credentials: "same-origin",
                method: "put",
            })
                .then((response) => response.json())
                .then(function (json) {
                    if (json.metadata.code === 401) {
                        //unauthorized
                        window.location.href = "/";
                    }

                    spinner.classList.add("d-none");

                    const li =
                        '<li data-id="' +
                        propertyId +
                        '">' +
                        name +
                        '<span class="remove"><i class="fa-solid fa-xmark fa-fw"></i></i></span></li>';

                    listContainer.insertAdjacentHTML("beforeend", li);

                    sliderWrapper.scrollLeft = sliderWrapper.scrollWidth;
                });
        }

        this.selectedOptions[0].disabled = true;
        this.selectedOptions[0].classList.add("d-none");

        this.selectedIndex = 0;
    }

    selectInputs.forEach(function (select, index) {
        select.addEventListener("change", propertySelected);
        select.addEventListener("select", propertySelected);
    });

    const propertyAdded = (e) => {
        e.preventDefault();

        const propertyWrapper = e.target.closest(".profile-edit-properties");
        const listContainer = propertyWrapper.querySelector(".list");
        const spinner = propertyWrapper.querySelector(".spinner");
        const input = propertyWrapper.querySelector("input");
        const type = propertyWrapper.dataset.type;
        const user = propertyWrapper.dataset.user;
        const name = input.value;

        if (!name.trim()) {
            input.classList.add("error-displayed");

            return;
        }

        spinner.classList.remove("d-none");

        const sliderWrapper = propertyWrapper.querySelector(".slider-wrapper");
        sliderWrapper.scrollLeft = sliderWrapper.scrollWidth;

        const formData = new FormData();
        formData.append("user", user);
        formData.append("name", name);

        fetch(`/website-api/user/property/${type}/`, {
            credentials: "same-origin",
            method: "post",
            body: formData,
        })
            .then((response) => response.json())
            .then(function (res) {
                if (res.metadata.code === 401) {
                    //unauthorized
                    window.location.href = "/";
                }

                let propertyId = res.metadata.id;

                fetch(`/website-api/user/property/${type}/${propertyId}/`, {
                    credentials: "same-origin",
                    method: "put",
                })
                    .then((response) => response.json())
                    .then(function (json) {
                        if (json.metadata.code === 401) {
                            //unauthorized
                            window.location.href = "/";
                        }

                        if (json.metadata.code === 409) {
                            input.value = "";
                            input.classList.add("error-displayed");

                            spinner.classList.add("d-none");

                            return;
                        }

                        spinner.classList.add("d-none");

                        const li =
                            '<li data-id="' +
                            propertyId +
                            '">' +
                            name +
                            '<button class="remove"><i class="fa-solid fa-xmark fa-fw"></i></button></li>';

                        listContainer.insertAdjacentHTML("beforeend", li);

                        sliderWrapper.scrollLeft = sliderWrapper.scrollWidth;

                        input.value = "";
                        input.classList.remove("error-displayed");
                    });
            });
    };

    const addButtons = document.querySelectorAll(".profile-edit-properties button");

    addButtons.forEach(function (select, index) {
        select.addEventListener("click", propertyAdded);
    });

    const removeItem = (e) => {
        e.preventDefault();

        if (!e.target.closest(".remove")) {
            return;
        }

        const propertyWrapper = e.target.closest(".profile-edit-properties");
        const listContainer = propertyWrapper.querySelector("ul");
        const selectBox = propertyWrapper.querySelector("select");
        const spinner = propertyWrapper.querySelector(".spinner");
        const itemContainer = e.target.closest("li");
        const propertyId = itemContainer.dataset.id;

        spinner.classList.remove("d-none");

        itemContainer.style.opacity = "0.5";

        fetch(`/website-api/user/property/${propertyId}/`, {
            credentials: "same-origin",
            method: "delete",
        })
            .then((response) => response.json())
            .then(function (json) {
                if (json.metadata.code === 401) {
                    //unauthorized
                    window.location.href = "/";
                }

                spinner.classList.add("d-none");

                listContainer.removeChild(itemContainer);

                selectBox.childNodes.forEach(function (option, index) {
                    if (option.value === propertyId) {
                        option.disabled = false;
                        option.classList.remove("d-none");
                    }
                });

                selectBox.selectedIndex = 0;
            });
    };

    const removeItemsLists = document.querySelectorAll(".profile-edit-properties .list");

    removeItemsLists.forEach(function (removeItemsList, index) {
        removeItemsList.addEventListener("click", removeItem, true);
    });
});
