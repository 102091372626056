document.addEventListener("DOMContentLoaded", () => {
    const paymentsPayoutsPage = document.getElementById("payments-payouts");

    if (!paymentsPayoutsPage) {
        return;
    }

    const dateFilterForm =
        paymentsPayoutsPage.querySelector(".date-filter-form");
    const dateFromFilter = paymentsPayoutsPage.querySelector(
        ".date-selector-from"
    );
    const dateToFilter = paymentsPayoutsPage.querySelector(".date-selector-to");
    const loadOverlay = paymentsPayoutsPage.querySelector(".loader-overlay");

    const updateAction = () => {
        loadOverlay.classList.remove("d-none");

        dateFilterForm.submit();

        dateFromFilter.readonly = true;
        dateToFilter.readonly = true;
    };

    dateFromFilter.addEventListener("change", (e) => {
        updateAction();
    });

    dateToFilter.addEventListener("change", (e) => {
        updateAction();
    });
});
