document.addEventListener("DOMContentLoaded", () => {
    const dialogResearchOptIn = document.getElementById(
        "dialog-research-opt-in",
    );

    if (!dialogResearchOptIn) {
        return;
    }

    const introduction = dialogResearchOptIn.querySelector(".introduction");
    const question = dialogResearchOptIn.querySelector(".question");
    const finish = dialogResearchOptIn.querySelector(".finish");

    const hostnameBase = window.location.hostname.replace(/^[^.]*./, "");

    const closeDialogButtons =
        dialogResearchOptIn.querySelectorAll(".close-dialog");
    closeDialogButtons.forEach((closeDialogButton) => {
        closeDialogButton.addEventListener("click", (e) => {
            document.cookie =
                "research_opt_in=false; max-age=2592000; path=/; domain=" +
                hostnameBase +
                "; secure; samesite=lax";
        });
    });

    const buttonApply = introduction.querySelector(".button-apply");

    buttonApply.addEventListener("click", (e) => {
        introduction.classList.add("d-none");
        question.classList.remove("d-none");
        finish.classList.add("d-none");
    });

    const buttonSubmit = question.querySelector(".button-submit");
    const buttonSubmitSpinner = buttonSubmit.querySelector(".spinner");
    const textarea = question.querySelector("textarea");

    buttonSubmit.addEventListener("click", (e) => {
        if (!textarea.value) {
            alert("Please write your experience.");
            return;
        }

        buttonSubmitSpinner.classList.remove("d-none");

        const formData = new FormData();
        formData.append("experience", textarea.value);

        fetch("/website-api/research-opt-in/", {
            credentials: "same-origin",
            method: "post",
            body: formData,
        })
            .then((response) => {
                if (response.status === 401) {
                    throw new Error("Unauthorized");
                }
                if (response.status === 429) {
                    throw new Error(
                        "We're sorry, but you have sent too many requests to us recently. Please try again later.",
                    );
                }
                if (response.status === 500) {
                    throw new Error("Service is unavailable");
                }

                return response.json();
            })
            .then(function (jsonResponse) {
                if (jsonResponse.metadata.code >= 400) {
                    buttonSubmitSpinner.classList.add("d-none");

                    if (jsonResponse.metadata.errors) {
                        alert(jsonResponse.metadata.errors[0].error);

                        return;
                    }

                    if (jsonResponse.metadata.error_message) {
                        alert(jsonResponse.metadata.error_message);

                        return;
                    }

                    alert(
                        "Something went wrong. Please contact customer service.",
                    );

                    return;
                }

                introduction.classList.add("d-none");
                question.classList.add("d-none");
                finish.classList.remove("d-none");

                document.cookie =
                    "research_opt_in=true; max-age=2592000; path=/; domain=" +
                    hostnameBase +
                    "; secure; samesite=lax";
            })
            .catch((error) => {
                buttonSubmitSpinner.classList.add("d-none");
                alert(error);
            });
    });
});
