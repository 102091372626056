document.addEventListener("DOMContentLoaded", () => {
    const accountBlocks = document.querySelectorAll(".account-block");
    accountBlocks.forEach(function (accountBlock) {
        const toggle = accountBlock.querySelector(".toggle");
        const content = accountBlock.querySelector(".content");
        const editor = accountBlock.querySelector(".editor");
        if (toggle) {
            toggle.addEventListener("click", function () {
                content.classList.toggle("active");
                editor.classList.toggle("active");
            });
        }
    });
});
