import dayjs from "dayjs";

document.addEventListener("DOMContentLoaded", () => {
    /* Display bookings with localised times */
    const bookingCollection = document.getElementById("booking-collection");

    if (!bookingCollection) {
        return;
    }

    const bookingCollectionResources =
        bookingCollection.querySelectorAll(".resource");

    if (!bookingCollectionResources) {
        return;
    }

    bookingCollectionResources.forEach(function (resource) {
        const start = resource.querySelector(".start").value;
        const duration = resource.querySelector(".duration").value;

        const fromDateTime = dayjs(start);
        const toDateTime = dayjs(start)
            .startOf("second")
            .add(duration, "minute");

        resource.querySelector(".start-date").textContent =
            new Intl.DateTimeFormat(undefined, {
                dateStyle: "full",
            }).format(fromDateTime.toDate());

        resource.querySelector(".start-time").textContent =
            new Intl.DateTimeFormat(undefined, {
                timeStyle: "short",
            }).format(fromDateTime.toDate());

        resource.querySelector(".end-time").textContent =
            new Intl.DateTimeFormat(undefined, {
                timeStyle: "short",
            }).format(toDateTime.toDate());
    });
});
