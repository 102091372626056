document.addEventListener("DOMContentLoaded", () => {
    const goToUrls = document.querySelectorAll(".go-to-url");

    if (!goToUrls) {
        return;
    }

    goToUrls.forEach(function (goToUrl) {
        goToUrl.addEventListener("click", function () {
            const url = goToUrl.dataset.url;

            if (!url) {
                return;
            }

            if (goToUrl.classList.contains('go-to-blank')) {
                window.open(url, '_blank');
                return;
            }
            window.location.href = url;
        });
    });
});
