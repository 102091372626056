document.addEventListener("DOMContentLoaded", () => {
    const premiumReview = document.getElementById("premium-review");

    if (!premiumReview) {
        return;
    }

    const review1step3Button = premiumReview.querySelector(
        ".write-review-1 .step-3-button"
    );
    const review2BackButton = premiumReview.querySelector(
        ".write-review-2 .back-button"
    );
    const review2continueButton = premiumReview.querySelector(
        ".write-review-2 .continue"
    );
    const review3BackButton = premiumReview.querySelector(
        ".write-review-3 .back-button"
    );

    const cancelSteps = premiumReview.querySelectorAll(
        ".subscription-cancel-step"
    );
    const review1Step = premiumReview.querySelector(".write-review-1");
    const review2Step = premiumReview.querySelector(".write-review-2");
    const review3Step = premiumReview.querySelector(".write-review-3");

    const hideCancelSteps = () => {
        cancelSteps.forEach((step) => {
            step.classList.add("d-none");
        });
    };

    if (review1step3Button) {
        review1step3Button.addEventListener("click", (e) => {
            hideCancelSteps();
            review2Step.classList.remove("d-none"); // Shows step 2
        });
    }

    if (review2BackButton) {
        review2BackButton.addEventListener("click", (e) => {
            hideCancelSteps();
            review1Step.classList.remove("d-none"); // Shows step 1
        });
    }

    if (review2continueButton) {
        review2continueButton.addEventListener("click", (e) => {
            e.preventDefault();

            const form = e.currentTarget.closest("form");
            const matchUserInput = form.querySelector(
                "input[name='subscription_cancel_match_user_id']:checked"
            );

            if (!matchUserInput) {
                form.querySelector(".user-list").classList.add(
                    "error-displayed"
                );
                form.querySelector(".user-list .error").classList.remove(
                    "d-none"
                );

                return;
            }

            form.querySelector(".user-list").classList.remove(
                "error-displayed"
            );
            form.querySelector(".user-list .error").classList.add("d-none");

            /* Check if user selected "other", so we can submit the form */
            if (matchUserInput.value === "other") {
                form.submit();

                return;
            }

            /* Check if user already wrote a review for this user, so we can skip the review step */
            if (matchUserInput.dataset.reviewId) {
                form.submit();

                return;
            }

            const userId = matchUserInput.value;
            const name = matchUserInput.dataset.name;
            const photo_src = matchUserInput
                .closest("label")
                .querySelector(".thumb").src;

            review3Step.querySelector(
                ".form-review input[name='subscription_cancel_match_user_id']"
            ).value = userId;
            review3Step.querySelector(
                ".form-skip input[name='subscription_cancel_match_user_id']"
            ).value = userId;
            review3Step.querySelector(".name").innerText = name;

            let reviewDescriptionPlaceholder = review3Step
                .querySelector("textarea")
                .getAttribute("placeholder");
            reviewDescriptionPlaceholder = reviewDescriptionPlaceholder.replace(
                "{first_name}",
                name
            );

            review3Step
                .querySelector("textarea")
                .setAttribute("placeholder", reviewDescriptionPlaceholder);
            review3Step
                .querySelector("textarea")
                .setAttribute("data-error", reviewDescriptionPlaceholder);

            review3Step.querySelector(".thumb").setAttribute("alt", name);
            review3Step.querySelector(".thumb").setAttribute("src", photo_src);

            hideCancelSteps();
            review3Step.classList.remove("d-none"); // Shows step 3
        });
    }

    if (review3BackButton) {
        review3BackButton.addEventListener("click", (e) => {
            hideCancelSteps();
            review2Step.classList.remove("d-none"); // Shows step 2
        });
    }
});
