document.addEventListener("DOMContentLoaded", () => {
    if (!document.getElementById("footer")) {
        return;
    }

    const countryMenuItems = document.querySelectorAll(
        "#footer .country .dropdown-menu li"
    );
    const languageMenuItems = document.querySelectorAll(
        "#footer .language .dropdown-menu li"
    );

    countryMenuItems.forEach((countryMenuItem) => {
        countryMenuItem.addEventListener(
            "click",
            (event) => {
                const countryIso = event.target.dataset.countryIso;

                if (countryIso) {
                    window.location.href = "/country/" + countryIso + "/";
                }
            },
            { passive: true }
        );
    });

    languageMenuItems.forEach((languageMenuItem) => {
        languageMenuItem.addEventListener(
            "click",
            (event) => {
                const language = event.target.dataset.language;

                if (language) {
                    window.location.href = "/language/" + language + "/";
                }
            },
            { passive: true }
        );
    });
});
