document.addEventListener("DOMContentLoaded", () => {
    const auth = document.getElementById("auth");

    if (!auth) {
        return;
    }

    const stepContinue = auth.querySelector(".step-continue");
    const stepLogin = auth.querySelector(".step-login");
    const stepSignup = auth.querySelector(".step-signup");

    const backButtons = auth.querySelectorAll(".back");
    backButtons.forEach((backButton) => {
        backButton.addEventListener("click", (e) => {
            stepLogin.classList.add("d-none");
            stepSignup.classList.add("d-none");
            stepContinue.classList.remove("d-none");
        });
    });

    const stepContinueButton = stepContinue.querySelector(".continue");
    stepContinueButton.addEventListener("click", (e) => {
        e.preventDefault();

        const stepContinueEmail = stepContinue.querySelector(".email");
        const stepContinuePassword = stepContinue.querySelector(".password");
        const stepContinueButtonSpinner =
            stepContinueButton.querySelector(".spinner");

        stepContinueButtonSpinner.classList.remove("d-none");
        stepContinueButton.disabled = true;

        const formData = new FormData();
        formData.append("email", stepContinueEmail.value);

        fetch("/website-api/auth/user-check/", {
            credentials: "same-origin",
            method: "post",
            body: formData,
        })
            .then((response) => {
                if (response.status === 429) {
                    stepContinueButtonSpinner.classList.add("d-none");
                    stepContinueButton.disabled = false;

                    alert(
                        "We're sorry, but you have sent too many requests to us recently. Please try again later.",
                    );

                    throw new Error("Too many requests");
                }
                if (response.status === 403) {
                    stepContinueButtonSpinner.classList.add("d-none");
                    stepContinueButton.disabled = false;

                    alert(
                        "Request forbidden. If you are using an anonymous proxy or VPN you'll need to disable it to fully access Babysits.",
                    );

                    throw new Error("Anonymous proxy or VPN");
                }

                if (response.status >= 500) {
                    stepContinueButtonSpinner.classList.add("d-none");
                    stepContinueButton.disabled = false;

                    alert("We ran into a problem. Please try again.");

                    throw new Error("500 problem.");
                }

                return response.json();
            })
            .then((jsonResponse) => {
                if (jsonResponse.metadata.code >= 400) {
                    stepContinueButtonSpinner.classList.add("d-none");
                    stepContinueButton.disabled = false;

                    if (jsonResponse.metadata.errors) {
                        alert(jsonResponse.metadata.errors[0].error);
                        return;
                    }

                    if (jsonResponse.metadata.error_message) {
                        alert(jsonResponse.metadata.error_message);
                        return;
                    }

                    alert(
                        "Something went wrong. Please contact customer service.",
                    );
                    return;
                }

                if (jsonResponse.data.account_exists === false) {
                    const stepSignupEmail = stepSignup.querySelector(".email");
                    stepSignupEmail.value = stepContinueEmail.value;

                    const stepSignupPassword =
                        stepSignup.querySelector(".password");
                    stepSignupPassword.value = stepContinuePassword.value;

                    stepContinue.classList.add("d-none");
                    stepSignup.classList.remove("d-none");

                    stepContinueButtonSpinner.classList.add("d-none");
                    stepContinueButton.disabled = false;

                    return;
                }

                stepContinueButtonSpinner.classList.add("d-none");
                stepContinueButton.disabled = false;

                const stepLoginEmail = stepLogin.querySelector(".email");
                stepLoginEmail.value = stepContinueEmail.value;
                stepLoginEmail.type = "hidden";

                const stepLoginPassword = stepLogin.querySelector(".password");
                stepLoginPassword.value = stepContinuePassword.value;

                const stepLoginPreviewEmail =
                    stepLogin.querySelector(".preview-email");
                stepLoginPreviewEmail.textContent = stepContinueEmail.value;
                stepLoginPreviewEmail.classList.remove("d-none");

                const stepLoginPreviewPhotoImg =
                    stepLogin.querySelector(".preview-photo img");
                stepLoginPreviewPhotoImg.src =
                    jsonResponse.data.photo.jpg.small;
                const stepLoginPreviewPhoto =
                    stepLogin.querySelector(".preview-photo");
                stepLoginPreviewPhoto.classList.remove("d-none");

                const stepLoginTitle = stepLogin.querySelector(".title");
                stepLoginTitle.classList.add("d-none");
                const stepLoginFirstName =
                    stepLogin.querySelector(".first-name");
                stepLoginFirstName.textContent = jsonResponse.data.first_name;
                const stepLoginTitleFirstName =
                    stepLogin.querySelector(".title-first-name");
                stepLoginTitleFirstName.classList.remove("d-none");

                const socialLogins = stepLogin.querySelector(".social-logins");
                const socialLoginGoogle =
                    socialLogins.querySelector(".button-google");
                const socialLoginFacebook =
                    socialLogins.querySelector(".button-facebook");
                const socialLoginApple =
                    socialLogins.querySelector(".button-apple");

                socialLogins.classList.add("d-none");
                socialLoginGoogle.classList.add("d-none");
                socialLoginFacebook.classList.add("d-none");
                socialLoginApple.classList.add("d-none");

                // Show social logins from this user
                if (jsonResponse.data.suggested_auth_methods.length > 0) {
                    socialLogins.classList.remove("d-none");

                    if (
                        jsonResponse.data.suggested_auth_methods.includes(
                            "google",
                        )
                    ) {
                        socialLoginGoogle.classList.remove("d-none");
                    }
                    if (
                        jsonResponse.data.suggested_auth_methods.includes(
                            "facebook",
                        )
                    ) {
                        socialLoginFacebook.classList.remove("d-none");
                    }
                    if (
                        jsonResponse.data.suggested_auth_methods.includes(
                            "apple",
                        )
                    ) {
                        socialLoginApple.classList.remove("d-none");
                    }
                }

                stepContinue.classList.add("d-none");
                stepLogin.classList.remove("d-none");
            })
            .catch((error) => {});
    });
});
