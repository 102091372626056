document.addEventListener("DOMContentLoaded", () => {
    const header = document.getElementById("header");

    if (!header) {
        return;
    }

    const headerSearchExpandButton = header.querySelector(".search-expand-button");
    const headerSearchBar = header.querySelector(".search-bar");
    const searchBarMask = document.getElementById("search-bar-mask");

    if (!headerSearchExpandButton || !headerSearchBar || !searchBarMask) {
        return;
    }

    headerSearchExpandButton.addEventListener("click", (e) => {
        e.preventDefault();

        headerSearchExpandButton.classList.remove("active");
        headerSearchBar.classList.add("active");
        searchBarMask.classList.remove("d-none");

        focusOnInput(headerSearchBar);
    });

    searchBarMask.addEventListener("click", (e) => {
        headerSearchExpandButton.classList.add("active");
        headerSearchBar.classList.remove("active");
        searchBarMask.classList.add("d-none");
    });


    // We have multiples searchbars on the homepage
    const searchBars = document.querySelectorAll(".search-bar");

    searchBars.forEach(function (searchBar) {
        const searchTabs = searchBar.querySelectorAll(".search-tabs .tab");
        const autocomplete = searchBar.querySelector(".autocomplete");
        const labelBabysitterSearch = autocomplete.querySelector(
            "label.babysitter-search"
        );
        const labelParentSearch = autocomplete.querySelector("label.parent-search");

        searchTabs.forEach(function (searchTab) {
            searchTab.addEventListener("click", (e) => {
                e.preventDefault();
                resetTabs(searchTabs);
                searchTab.classList.add("active");

                autocomplete.dataset.autocompleteUrlValue =
                    searchTab.dataset.searchUrl;

                if (searchTab.dataset.searchType === "babysitter") {
                    labelBabysitterSearch.classList.remove("d-none");
                    labelParentSearch.classList.add("d-none");
                } else {
                    labelBabysitterSearch.classList.add("d-none");
                    labelParentSearch.classList.remove("d-none");
                }

                /* Put attention to this action by focussing on input */
                focusOnInput(searchBar);
            });
        });

    });

    function resetTabs(searchTabs) {
        searchTabs.forEach(function (searchTab) {
            searchTab.classList.remove("active");
        });
    }

    function focusOnInput(searchBar) {
        const searchBarInput = searchBar.querySelector("input");

        // Wait 300ms until the element becomes visible and click+focus
        setTimeout(function () {
            searchBarInput.click();
            searchBarInput.focus();
        }, 300);

        // const header = document.getElementById("header");
        //
        // const searchExpandable = header.querySelector(".search-expandable");
        // /**
        //  *  Fix for opening keyboard when focus on element in iOS 13:
        //  *  - Create temp element
        //  *  - Focus and click on this element: it will open the keyboard
        //  *  - Last focus+click on desired element
        //  *
        //  *  https://stackoverflow.com/questions/54424729/ios-show-keyboard-on-input-focus
        //  **/
        // let tempElement = document.createElement("input");
        // tempElement.style.position = "absolute";
        // tempElement.style.top = searchExpandable.offsetTop + 215 + "px";
        // tempElement.style.left = searchExpandable.offsetLeft + "px";
        // tempElement.style.height = 0;
        // tempElement.style.opacity = 0;
        //
        // searchExpandable.appendChild(tempElement);
        // tempElement.focus();
        //
        // setTimeout(function () {
        //     searchBarInput.focus();
        //     searchBarInput.click();
        //
        //     searchExpandable.removeChild(tempElement);
        // }, 300);
    }
});
