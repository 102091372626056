import {default as Tooltip} from 'bootstrap/js/dist/tooltip';

document.addEventListener("DOMContentLoaded", () => {
    const conversation = document.getElementById("conversation");

    if (!conversation) {
        return;
    }

    const createBookingTip = conversation.querySelector(".create-booking-tip");

    if (createBookingTip) {
        const tooltip = Tooltip.getOrCreateInstance(createBookingTip);
        tooltip.show();

        conversation.addEventListener("click", (e) => {
            console.log('hide tip');
        });
    }
});
