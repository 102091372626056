document.addEventListener("DOMContentLoaded", () => {
    const doubleSliderContainers = document.querySelectorAll(
        ".double-slider-container",
    );

    if (!doubleSliderContainers) {
        return;
    }

    doubleSliderContainers.forEach((doubleSliderContainer) => {
        const sliderOne = doubleSliderContainer.querySelector(".slider-1");
        const sliderTwo = doubleSliderContainer.querySelector(".slider-2");
        const minGap = 1;
        const sliderTrack =
            doubleSliderContainer.querySelector(".slider-track");
        const sliderMinValue = sliderOne.min;
        const sliderMaxValue = sliderOne.max;

        slideOne();
        slideTwo();

        sliderOne.addEventListener("input", (event) => {
            slideOne();
        });
        sliderTwo.addEventListener("input", (event) => {
            slideTwo();
        });

        function slideOne() {
            if (
                parseInt(sliderTwo.value) - parseInt(sliderOne.value) <=
                minGap
            ) {
                sliderOne.value = parseInt(sliderTwo.value) - minGap;
            }
            fillColor();
        }
        function slideTwo() {
            if (
                parseInt(sliderTwo.value) - parseInt(sliderOne.value) <=
                minGap
            ) {
                sliderTwo.value = parseInt(sliderOne.value) + minGap;
            }
            fillColor();
        }
        function fillColor() {
            let percent1 =
                ((sliderOne.value - sliderMinValue) /
                    (sliderMaxValue - sliderMinValue)) *
                100;
            let percent2 =
                ((sliderTwo.value - sliderMinValue) /
                    (sliderMaxValue - sliderMinValue)) *
                100;
            sliderTrack.style.background = `linear-gradient(to right, var(--bs-gray-300) ${percent1}% , var(--bs-primary) ${percent1}% , var(--bs-primary) ${percent2}%, var(--bs-gray-300) ${percent2}%)`;
        }
    });
});
