document.addEventListener("DOMContentLoaded", () => {
    const homepage = document.getElementById("homepage");

    if (!homepage) {
        return;
    }

    const payoff = homepage.querySelector(".payoff");

    if (!payoff) {
        return;
    }

    const swapWords = payoff.querySelectorAll(".swap-text .word");

    if (!swapWords) {
        return;
    }

    function handlePayoffHeight() {
        /**
         * START Set swap-text height to the height of displaying the longest word
         * Otherwise the height of the payoff may increase and decrease every time the swapWord changes, which causes the page to flip
         */
        let maxWidth = 0;
        let maxWidthWord = null;

        payoff.style.minHeight = null;

        // Hide all words
        swapWords.forEach(function (swapWord) {
            swapWord.classList.add("d-none");
        });

        // Check which swapWord is the longest
        swapWords.forEach(function (swapWord) {
            swapWord.classList.remove("d-none");

            if (swapWord.offsetWidth > maxWidth) {
                maxWidth = swapWord.offsetWidth;
                maxWidthWord = swapWord;
            }

            swapWord.classList.add("d-none");
        });

        maxWidthWord.classList.remove("d-none");

        payoff.style.minHeight = payoff.clientHeight + "px";

        // Reset: show first swapWord
        swapWords.forEach(function (swapWord) {
            swapWord.classList.add("d-none");
        });
        swapWords[0].classList.remove("d-none");
    }

    const body = document.querySelector("html");

    // Set payoff height
    if (window.ResizeObserver) {
        // Check if browser supports ResizeObserver
        let observer = new ResizeObserver(handlePayoffHeight);
        observer.observe(body);
    }

    // Set payoff height again after all CSS has loaded
    window.addEventListener("load", () => {
        handlePayoffHeight();
    });

    /**
     * END Set swap-text height to the height of displaying the longest word
     */

    let counter = 0;
    setInterval(changeWord, 1000);

    function changeWord() {
        swapWords.forEach(function (swapWord) {
            swapWord.classList.add("d-none");
        });

        swapWords[counter].classList.remove("d-none");

        counter++;
        if (counter >= swapWords.length) {
            counter = 0;
        }
    }
});
