document.addEventListener("DOMContentLoaded", () => {
    const copyPaths = document.querySelectorAll(".copy-to-clipboard");

    if (!copyPaths) {
        return;
    }

    copyPaths.forEach((copyPath) => {
        copyPath.addEventListener("click", (e) => {
            navigator.clipboard.writeText(copyPath.dataset.clipboardText).then(
                () => {
                    /* clipboard successfully set */
                    alert(copyPath.dataset.successText);
                },
                () => {
                    /* clipboard write failed */
                    alert('Copying to clipboard failed. Please manually copy the text.');
                }
            );
        });

    });
});
