import debounce from "lodash/debounce";

document.addEventListener("DOMContentLoaded", () => {
    const profileEdit = document.getElementById("profile-edit");
    if (!profileEdit) {
        return;
    }

    const allIndividualCheckboxes = profileEdit.querySelectorAll('.checkbox-cloud.individual-input input[type=checkbox]');
    const debounceSendDataAllIndividualCheckboxes = debounce(sendData, 800);

    allIndividualCheckboxes.forEach((checkbox) => {
        checkbox.addEventListener('click', (event) => {
            const formData = new FormData();

            allIndividualCheckboxes.forEach((item) => {
                formData.append(item.name, item.checked);
            });

            debounceSendDataAllIndividualCheckboxes('/website-api/profile/', formData);
        });
    });

    const characteristicsCheckboxes = profileEdit.querySelectorAll('.checkbox-cloud.characteristics input[type=checkbox]');
    const debounceSendDataCharacteristicsCheckboxes = debounce(sendData, 800);

    characteristicsCheckboxes.forEach((checkbox) => {
        checkbox.addEventListener('click', (event) => {
            const formData = new FormData();
            let oneChecked = false;

            characteristicsCheckboxes.forEach((item) => {
                if (item.checked) {
                    formData.append(item.name, item.value);
                    oneChecked = true
                }
            });

            if (!oneChecked) {
                formData.append('characteristics', []);
            }

            debounceSendDataCharacteristicsCheckboxes('/website-api/profile/', formData);
        });
    });

    const ageGroupsCheckboxes = profileEdit.querySelectorAll('.checkbox-cloud.age-groups input[type=checkbox]');
    const debounceSendDataAgeGroupsCheckboxes = debounce(sendData, 800);

    ageGroupsCheckboxes.forEach((checkbox) => {
        checkbox.addEventListener('click', (event) => {
            const formData = new FormData();
            let oneChecked = false;

            ageGroupsCheckboxes.forEach((item) => {
                if (item.checked) {
                    formData.append(item.name, item.value);
                    oneChecked = true
                }
            });

            if (!oneChecked) {
                formData.append('age_groups', []);
            }

            debounceSendDataAgeGroupsCheckboxes('/website-api/profile/', formData);
        });
    });

    const typeBabysitCheckboxes = profileEdit.querySelectorAll('.checkbox-cloud.type-babysit input[type=checkbox]');
    const debounceSendDataTypeBabysitCheckboxes = debounce(sendData, 800);

    typeBabysitCheckboxes.forEach((checkbox) => {
        checkbox.addEventListener('click', (event) => {
            const formData = new FormData();
            let oneChecked = false;

            typeBabysitCheckboxes.forEach((item) => {
                if (item.checked) {
                    formData.append(item.name, item.value);
                    oneChecked = true
                }
            });

            if (!oneChecked) {
                formData.append('type_babysit', []);
            }

            debounceSendDataTypeBabysitCheckboxes('/website-api/profile/', formData);
        });
    });

    function sendData(url, formData) {
        fetch(url, {
            credentials: "same-origin",
            method: "post",
            body: formData,
        })
            .then((response) => {
                if (response.status === 429) {
                    alert("We're sorry, but you have sent too many requests to us recently. Please try again later.");

                    throw new Error("Too many requests");
                }

                return response.json();
            })
            .then(function (data) {

                if (data.metadata.code !== 200 && data.metadata.code !== 201) {
                    alert(data.metadata.error_message);
                    return;
                }
            });
    }
});
