import Choices from "choices.js";

document.addEventListener("DOMContentLoaded", () => {
    const select = document.getElementById("language-select");

    if (!select) {
        return;
    }

    const choicesInstance = new Choices(select, {
        duplicateItemsAllowed: false,
        itemSelectText: "",
        noResultsText: "",
        searchPlaceholderValue: false,
        placeholderValue: "placeholder",
        shouldSort: false,
    });

    select.addEventListener("choice", (e) => {
        setTimeout(() => choicesInstance.setChoiceByValue("placeholder"), 400);
    });
});
