document.addEventListener("DOMContentLoaded", () => {
    const sliders = document.querySelectorAll(".slider");

    if (!sliders) {
        return;
    }

    sliders.forEach((slider) => {
        const sliderItems = slider.querySelector(".slider .slider-items");
        const scrollLeft = slider.querySelector(".scroll-left");
        const scrollRight = slider.querySelector(".scroll-right");

        if (!scrollLeft || !scrollRight) {
            return;
        }

        const firstChild = slider.querySelector(
            ".slider-items .slider-item:first-child"
        );
        const lastChild = slider.querySelector(
            ".slider-items .slider-item:last-child"
        );

        if (!firstChild || !lastChild) {
            return;
        }

        const sliderWrapper = slider.querySelector(".slider-wrapper");

        scrollRight.addEventListener("click", (e) => {
            sliderWrapper.scrollLeft = sliderWrapper.scrollLeft + (sliderItems.scrollWidth / sliderItems.children.length);
        });

        scrollLeft.addEventListener("click", (e) => {
            sliderWrapper.scrollLeft = sliderWrapper.scrollLeft - (sliderItems.scrollWidth / sliderItems.children.length);
        });

        const options = {
            root: sliderWrapper, // relative to document viewport
            rootMargin: "0px", // margin around root. Values are similar to css property. Unitless values not allowed
            threshold: 0.9, // visible amount of item shown in relation to root
        };

        new IntersectionObserver((changes, observer) => {
            manageScrollButtonVisibility(changes, scrollLeft);
        }, options).observe(firstChild);

        new IntersectionObserver((changes, observer) => {
            manageScrollButtonVisibility(changes, scrollRight);
        }, options).observe(lastChild);
    });

    const manageScrollButtonVisibility = (changes, element) => {
        changes.forEach((change) => {
            if (change.intersectionRatio > 0.9) {
                element.classList.remove("fade-in");
                element.classList.add("fade-out");
            } else {
                element.classList.remove("fade-out");
                element.classList.add("fade-in");
            }
        });
    };
});
