document.addEventListener("DOMContentLoaded", () => {
    const notificationsUpdateButton = document.getElementById(
        "notificationsUpdate"
    );

    if (!notificationsUpdateButton) {
        return;
    }

    notificationsUpdateButton.addEventListener("click", (e) => {
        e.preventDefault();

        // For visibility in child scopes
        const currentTarget = e.currentTarget;

        let body = "";
        const notificationCheckboxes = document.querySelectorAll(
            'input[type="checkbox"]:checked.notification-checkbox'
        );
        const spinner = currentTarget.querySelector(".spinner");

        spinner.classList.remove("d-none");

        notificationCheckboxes.forEach((element) => {
            body +=
                "notification_types[]=" + element.getAttribute("name") + "&";
        });

        fetch(notificationsUpdateButton.dataset.url, {
            credentials: "same-origin",
            method: "put",
            body: body,
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded; charset=UTF-8",
            },
        })
            .then((response) => response.json())
            .then((json) => {
                // Does not work, when use "spinner" variable, need reselect DOM element
                spinner.classList.add("d-none");
                document
                    .getElementById("notification-success-text")
                    .classList.remove("d-none");
            });
    });
});
