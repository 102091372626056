document.addEventListener("DOMContentLoaded", () => {
    const conversation = document.getElementById("conversation");

    if (!conversation) {
        return;
    }

    const messageListsScrollContent = conversation.querySelector(
        ".message-list .scroll-content",
    );

    if (!messageListsScrollContent) {
        return;
    }

    updateScroll(messageListsScrollContent);

    // Submit a message
    const messagesWrapper = conversation.querySelector(".messages-wrapper");
    const scrollElement = conversation.querySelector(".scroll-content");

    const submitMessageForm = document.getElementById("submit-message-form");
    const textarea = submitMessageForm.querySelector("textarea");
    const submitButton = submitMessageForm.querySelector(".message-submit-btn");
    const submitButtonIcon = submitButton.querySelector(".icon");
    const spinnerHtml =
        '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>\n' +
        '  <span class="visually-hidden">Loading...</span>';

    const defaultLimit = APP_PROPS.default_items_limit;
    const inboxId = conversation.dataset.inboxId;
    const id = submitMessageForm.dataset.id;
    const viewId = submitMessageForm.dataset.viewId;
    const csrfToken = submitMessageForm.dataset.csrfToken;

    const messageLoader = conversation.querySelector(".message-loader");
    let nextPaginationResult = `?offset=${defaultLimit}&limit=${defaultLimit}`;

    const cannedResponses = conversation.querySelector(".canned-responses");

    submitMessageForm.addEventListener("submit", (e) => {
        e.preventDefault();

        const content = submitMessageForm.querySelector("textarea").value;

        if (content.length <= 0) {
            return;
        }

        textarea.disabled = true;
        submitButton.innerHTML = spinnerHtml;
        submitButton.disabled = true;

        const formData = new FormData();
        formData.append("content", content);
        formData.append("_csrf_token", csrfToken);

        fetch(`/website-api/inbox/${id}/?view_id=${viewId}`, {
            credentials: "same-origin",
            method: "post",
            body: formData,
        })
            .then((response) => {
                if (response.status === 429) {
                    textarea.disabled = false;
                    submitButton.innerHTML = submitButtonIcon.outerHTML;
                    submitButton.disabled = false;

                    alert(
                        "We're sorry, but you have sent too many requests to us recently. Please try again later.",
                    );

                    throw new Error("Too many requests");
                }
                if (response.status === 403) {
                    textarea.disabled = false;
                    submitButton.innerHTML = submitButtonIcon.outerHTML;
                    submitButton.disabled = false;

                    alert(
                        "Request forbidden. If you are using an anonymous proxy or VPN you'll need to disable it to fully access Babysits.",
                    );

                    throw new Error("Anonymous proxy or VPN");
                }

                if (response.status >= 500) {
                    textarea.disabled = false;
                    submitButton.innerHTML = submitButtonIcon.outerHTML;
                    submitButton.disabled = false;

                    alert("We ran into a problem. Please try again.");

                    throw new Error("500 problem.");
                }

                return response.json();
            })
            .then(function (jsonResponse) {
                if (jsonResponse.metadata.code >= 400) {
                    textarea.disabled = false;
                    submitButton.innerHTML = submitButtonIcon.outerHTML;
                    submitButton.disabled = false;

                    if (jsonResponse.metadata.errors) {
                        alert(jsonResponse.metadata.errors[0].error);
                        return;
                    }

                    if (jsonResponse.metadata.error_message) {
                        alert(jsonResponse.metadata.error_message);
                        return;
                    }

                    alert(
                        "Something went wrong. Please contact customer service.",
                    );
                    return;
                }

                textarea.value = "";
                textarea.disabled = false;
                submitButton.innerHTML = submitButtonIcon.outerHTML;
                submitButton.disabled = false;

                textarea.focus();

                /**
                 * Remove textarea value from cookie, we do not need to display it to the user in the future anymore
                 */
                const hostnameBase = window.location.hostname.replace(
                    /^[^.]*./,
                    "",
                );
                document.cookie =
                    "conversation-" +
                    inboxId +
                    "-textarea=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=" +
                    hostnameBase +
                    "; secure; samesite=lax";

                const hasAlreadyMessage = document.querySelector(
                    ".message-container[data-id='" +
                        jsonResponse.data.id +
                        "']",
                );

                // Check if Ajax already delivered this message
                if (hasAlreadyMessage) {
                    return;
                }

                messagesWrapper.insertAdjacentHTML(
                    "afterbegin",
                    jsonResponse.data.rendered,
                );
                scrollElement.scrollTop = scrollElement.scrollHeight;

                if (cannedResponses) {
                    cannedResponses.classList.add("d-none");
                }
            });
    });

    messageListsScrollContent.addEventListener("scroll", (e) => {
        if (e.target.scrollTop !== 0) {
            return;
        }

        if (!nextPaginationResult) {
            return;
        }

        messageLoader.classList.remove("d-none");

        let url = `/website-api/inbox/${inboxId}/${nextPaginationResult}`;
        const params = new URLSearchParams(window.location.search);

        if (params.has("view_id")) {
            url += `&view_id=${params.get("view_id")}`;
        }

        fetch(url, {
            credentials: "same-origin",
            method: "get",
        })
            .then((res) => res.json())
            .then(function (json) {
                messageLoader.classList.add("d-none");

                if (json.metadata.code !== 200) {
                    // Target language invalid
                    alert(json.metadata.error_message);
                    return;
                }

                if (!json.data.rendered) {
                    nextPaginationResult = null;
                    return;
                }

                messagesWrapper.insertAdjacentHTML(
                    "beforeend",
                    json.data.rendered,
                );
                nextPaginationResult = json.pagination.next_results;

                const firstItemId = getFirstItemDataId(json.data.rendered);
                const firstItem = document.querySelector(
                    ".message-container[data-id='" + firstItemId + "']",
                );

                scrollElement.scrollTop = firstItem.offsetTop;
            });
    });

    function updateScroll(messageListsScrollContent) {
        messageListsScrollContent.scrollTop =
            messageListsScrollContent.scrollHeight;

        // Force a reflow and scroll again
        setTimeout(() => {
            messageListsScrollContent.scrollTop =
                messageListsScrollContent.scrollHeight;
        }, 0);
    }

    function getFirstItemDataId(htmlString) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");

        const items = doc.querySelectorAll(".message-container");

        return items[0].dataset.id;
    }
});
