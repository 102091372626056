document.addEventListener("DOMContentLoaded", () => {
    const contactForm = document.getElementById('contact-form');

    if (!contactForm) {
        return;
    }

    const addMoreFilesBtn = contactForm.querySelector('.add-more-files');
    const attachmentsWrapper = contactForm.querySelector('.attachments-wrapper');
    const firstAttachmentWrapper = contactForm.querySelector('.attachment-item-wrapper');

    addMoreFilesBtn.addEventListener('click', (e) => {
        let attachmentsItemsCount = contactForm.querySelectorAll('.attachment-item-wrapper').length;
        let newAttachmentItem = firstAttachmentWrapper.cloneNode(true);
        let nextAttachmentNum = attachmentsItemsCount + 1;

        if (nextAttachmentNum >= 3) {
            addMoreFilesBtn.classList.add('d-none');
        }

        let newIdentifier = 'contactFormFile' + nextAttachmentNum;

        newAttachmentItem.querySelector('input').value = null;
        newAttachmentItem.querySelector('input').id = newIdentifier;

        attachmentsWrapper.append(newAttachmentItem);
    });
});
