import { Controller } from "@hotwired/stimulus";
import { showElement, hideElement } from "./utils";

export default class extends Controller {
    static values = { userId: Number, reviewId: Number, hasReply: Boolean };
    static targets = [
        "commentButton",
        "replyContent",
        "replyForm",
        "replyTextarea",
        "loader",
        "apiError",
    ];

    edit(e) {
        hideElement(this.replyContentTarget);
        hideElement(this.apiErrorTarget);
        showElement(this.replyFormTarget);
    }

    comment(e) {
        e.preventDefault();

        hideElement(this.commentButtonTarget);
        showElement(this.replyFormTarget);
    }

    replyCancel(e) {
        hideElement(this.replyFormTarget);
        this.hasReplyValue
            ? showElement(this.replyContentTarget)
            : showElement(this.commentButtonTarget);
    }

    reply(e) {
        e.preventDefault();

        const content = this.replyTextareaTarget.value.trim();

        hideElement(this.apiErrorTarget);
        showElement(this.loaderTarget);

        const formData = new FormData();
        formData.append("content", content);

        fetch(
            `/website-api/reviews/${this.userIdValue}/${this.reviewIdValue}/replies/`,
            {
                credentials: "same-origin",
                method: "post",
                body: formData,
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw response;
                }
                return response.text();
            })
            .then((html) => {
                hideElement(this.replyFormTarget);
                hideElement(this.loaderTarget);

                this.element.outerHTML = html;
            })
            .catch((error) => {
                showElement(this.apiErrorTarget);
                hideElement(this.loaderTarget);
            });
    }
}
