document.addEventListener("DOMContentLoaded", () => {
    const profilePhotoSelect = document.getElementById("profilePhotoSelect");
    const profilePhotoInput = document.getElementById("profilePhotoInput");

    const profilePhotoPreview = document.getElementById("profilePhotoPreview");
    const profilePhotoRejected = document.getElementById("profile-photo-rejected");

    const profilePhotoRemove = document.getElementById("profilePhotoRemove");

    if (!profilePhotoSelect || !profilePhotoInput || !profilePhotoPreview || !profilePhotoRejected || !profilePhotoRemove) {
        return;
    }

    const profilePhotoSelectOverlay = profilePhotoSelect.querySelector(".overlay");
    const rejectReason = profilePhotoRejected.querySelector('.reject-reason');

    profilePhotoSelect.addEventListener(
        "click",
        function (e) {
            if (profilePhotoInput) {
                profilePhotoInput.click();
            }
        },
        false
    );

    profilePhotoInput.addEventListener(
        "change",
        function (e) {
            const file = this.files[0];

            if (
                !file.type.startsWith("image/jpeg") &&
                !file.type.startsWith("image/png")
            ) {
                alert(
                    "Sorry, that's an invalid file type. Only JPG and PNG allowed."
                );
                return false;
            }

            previewFile(file);

            uploadFile(file);
        },
        false
    );

    profilePhotoSelect.addEventListener("dragenter", dragenter, false);
    profilePhotoSelect.addEventListener("dragleave", dragleave, false);
    profilePhotoSelect.addEventListener("dragover", dragover, false);
    profilePhotoSelect.addEventListener("drop", drop, false);

    function dragenter(e) {
        profilePhotoSelect.classList.add("active");

        e.stopPropagation();
        e.preventDefault();
    }

    function dragleave(e) {
        profilePhotoSelect.classList.remove("active");

        e.stopPropagation();
        e.preventDefault();
    }

    function dragover(e) {
        profilePhotoSelect.classList.add("active");

        e.stopPropagation();
        e.preventDefault();
    }

    function drop(e) {
        profilePhotoSelect.classList.remove("active");
        e.stopPropagation();
        e.preventDefault();

        const dt = e.dataTransfer;
        const files = dt.files;

        const file = files[0];

        if (
            !file.type.startsWith("image/jpeg") &&
            !file.type.startsWith("image/png")
        ) {
            alert(
                "Sorry, that's an invalid file type. Only JPG and PNG allowed."
            );
            return false;
        }

        previewFile(file);

        uploadFile(file);
    }

    function previewFile(file) {
        profilePhotoPreview.style.display = "block"; // After removing a photo it is set to display=none, so make it block here

        profilePhotoPreview.style.objectFit = "cover";
        profilePhotoPreview.style.height = "100%";

        profilePhotoPreview.src = URL.createObjectURL(file);

        profilePhotoPreview.onload = function () {
            URL.revokeObjectURL(this.src);
        };
    }

    function uploadFile(file) {
        profilePhotoSelectOverlay.classList.remove('d-none');
        profilePhotoRejected.classList.add('d-none');
        profilePhotoRemove.classList.add("d-none");

        /**
         * If profile photo is displayed as part of the profile wizard and there was an error displayed, hide the error on the wizard
         */
        const wizardError = document.querySelector('.wizard-error');
        if (wizardError) {
            wizardError.classList.add('d-none');
        }

        const userId = profilePhotoSelect.dataset.userId;

        const uri = "/website-api/users/" + userId + "/photo/";
        const xhr = new XMLHttpRequest();
        const fd = new FormData();

        const buttonAdd = profilePhotoSelect.querySelector(".button-add");
        const uploadStatusPercentage = profilePhotoSelect.querySelector(
            ".upload-status-percentage"
        );
        const uploadResponseSpinner = profilePhotoSelect.querySelector(
            ".upload-response-spinner"
        );

        uploadStatusPercentage.textContent = "";

        buttonAdd.style.display = "none";
        uploadStatusPercentage.style.display = "block";
        uploadResponseSpinner.style.display = "none";

        xhr.upload.addEventListener(
            "progress",
            function (e) {
                if (e.lengthComputable) {
                    const percentage = Math.round(
                        (e.loaded * 100) / e.total
                    );

                    uploadStatusPercentage.textContent = percentage + "%";

                    if (percentage === 100) {
                        /**
                         * Visually show 100% for 500ms before showing the spinner
                         */
                        setTimeout(() => {
                            uploadStatusPercentage.style.display = "none";
                            uploadResponseSpinner.style.display = "block";
                        }, "500");
                    }
                }
            },
            false
        );

        xhr.onreadystatechange = function () {

            if (xhr.readyState === 4) {
                profilePhotoSelectOverlay.classList.add('d-none');
            }

            if (xhr.readyState === 4 && xhr.status === 413) {
                profilePhotoPreview.style.display = "none";

                buttonAdd.style.display = "block";
                uploadStatusPercentage.style.display = "none";
                uploadResponseSpinner.style.display = "none";

                alert(
                    "Sorry, the file you are trying to add is too large. Upload a file less than 15Mb."
                );

                return;
            }
            if (xhr.readyState === 4 && xhr.status === 422) {
                profilePhotoPreview.style.display = "none";

                buttonAdd.style.display = "block";
                uploadStatusPercentage.style.display = "none";
                uploadResponseSpinner.style.display = "none";

                const jsonResponse = JSON.parse(xhr.response);

                alert(jsonResponse.metadata.errors[0].error);

                return;
            }

            if (xhr.readyState === 4 && xhr.status === 400) {

                profilePhotoPreview.style.display = "none";
                buttonAdd.style.display = "block";
                uploadStatusPercentage.style.display = "none";
                uploadResponseSpinner.style.display = "none";

                const jsonResponse = JSON.parse(xhr.response);

                profilePhotoRejected.classList.remove('d-none');
                rejectReason.textContent = jsonResponse.metadata.error_message;

                return;
            }

            if (xhr.readyState === 4 && xhr.status >= 500) {

                profilePhotoPreview.style.display = "none";
                buttonAdd.style.display = "block";
                uploadStatusPercentage.style.display = "none";
                uploadResponseSpinner.style.display = "none";

                const jsonResponse = JSON.parse(xhr.response);

                alert(xhr.status + ': ' + jsonResponse.metadata.error_message + '. Please try again or contact us.');

                return;
            }

            if (xhr.readyState === 4 && xhr.status === 200) {
                profilePhotoSelect.dataset.profilePhotoAdded = 'true';

                buttonAdd.style.display = "none";
                uploadStatusPercentage.style.display = "none";
                uploadResponseSpinner.style.display = "none";

                profilePhotoRemove.classList.remove("d-none");
            }
        };

        xhr.open("POST", uri);
        fd.append("file", file);
        // Initiate a multipart/form-data upload
        xhr.send(fd);
    }

    profilePhotoRemove.addEventListener(
        "click",
        function (e) {
            profilePhotoRejected.classList.add('d-none');

            const buttonAdd =
                profilePhotoSelect.querySelector(".button-add");

            const userId = profilePhotoRemove.dataset.userId;

            const mediaId = profilePhotoRemove.dataset.mediaId;

            const iconRemove =
                profilePhotoRemove.querySelector(".icon-remove");
            const iconSpinner =
                profilePhotoRemove.querySelector(".icon-spinner");

            iconRemove.classList.add('d-none');
            iconSpinner.classList.remove('d-none');

            let uri = "/website-api/users/" + userId + "/photo/";
            let method = "DELETE";

            if (profilePhotoRemove.dataset.admin === "true") {
                uri = "/admin/media-moderation/" + mediaId + "/decline/";
                method = "POST";
            }

            const xhr = new XMLHttpRequest();

            xhr.onreadystatechange = function () {
                if (xhr.readyState === 4 && xhr.status === 429) {
                    alert("We're sorry, but you have sent too many requests to us recently. Please try again later.");

                    return;
                }

                if (xhr.readyState === 4 && xhr.status === 200) {
                    profilePhotoRemove.classList.add('d-none');
                    iconRemove.classList.remove('d-none');
                    iconSpinner.classList.add('d-none');

                    profilePhotoPreview.style.display = "none";

                    buttonAdd.style.display = "block";

                    profilePhotoInput.value = null;

                    profilePhotoSelect.dataset.profilePhotoAdded = 'false';
                }
            };

            xhr.open(method, uri, true);
            xhr.send();
        },
        false
    );

});
