import dayjs from "dayjs";

document.addEventListener("DOMContentLoaded", () => {
    /* Display booking with localised times */

    const bookingResource = document.getElementById("booking-resource");

    if (!bookingResource) {
        return;
    }

    const start = bookingResource.querySelector(".start");
    const duration = bookingResource.querySelector(".duration");
    const addonMinutes = bookingResource.querySelector(".addonMinutes");

    const dayjsDatetimeFrom = dayjs(start.value);
    const dayjsDatetimeTo = dayjs(start.value)
        .startOf("second")
        .add(duration.value, "minutes");

    const startDate = bookingResource.querySelector(".start-date");
    const startTime = bookingResource.querySelector(".start-time");
    const endTime = bookingResource.querySelector(".end-time");

    const startDateFormat = new Intl.DateTimeFormat(undefined, {
        dateStyle: "full",
    });

    const startTimeFormat = new Intl.DateTimeFormat(undefined, {
        timeStyle: "short",
    });

    const endTimeFormat = new Intl.DateTimeFormat(undefined, {
        timeStyle: "short",
    });

    startDate.textContent = startDateFormat.format(dayjsDatetimeFrom.toDate());
    startTime.textContent = startTimeFormat.format(dayjsDatetimeFrom.toDate());
    endTime.textContent = endTimeFormat.format(dayjsDatetimeTo.toDate());

    if (addonMinutes && addonMinutes.value > 0) {
        const dateTimeToAddon = dayjs(start.value)
            .startOf("second")
            .add(duration.value, "minutes")
            .add(addonMinutes.value, "minutes");

        const addonTimeFormat = new Intl.DateTimeFormat(undefined, {
            timeStyle: "short",
        });

        const endTimeAddon = bookingResource.querySelector(".end-time-addon");
        endTimeAddon.textContent = addonTimeFormat.format(
            dateTimeToAddon.toDate(),
        );
    }
});
