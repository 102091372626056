document.addEventListener("DOMContentLoaded", () => {
    const conversation = document.getElementById("conversation");

    if (!conversation) {
        return;
    }

    const buttonUnlockConversation = conversation.querySelector(
        ".unlock-conversation",
    );

    if (!buttonUnlockConversation) {
        return;
    }

    const spinner = buttonUnlockConversation.querySelector(".spinner");

    buttonUnlockConversation.addEventListener("click", (e) => {
        const conversationId = buttonUnlockConversation.dataset.conversationId;

        spinner.classList.remove("d-none");

        fetch("/website-api/inbox/" + conversationId + "/unlock/", {
            credentials: "same-origin",
            method: "post",
        })
            .then((response) => {
                spinner.classList.add("d-none");

                if (response.status === 401) {
                    throw new Error("Unauthorized");
                }
                if (response.status === 429) {
                    throw new Error(
                        "We're sorry, but you have sent too many requests to us recently. Please try again later.",
                    );
                }
                if (response.status === 500) {
                    throw new Error("Service is unavailable");
                }

                return response.json();
            })
            .then(function (jsonResponse) {
                if (jsonResponse.metadata.code >= 400) {
                    if (jsonResponse.metadata.errors) {
                        alert(jsonResponse.metadata.errors[0].error);

                        return;
                    }

                    if (jsonResponse.metadata.error_message) {
                        alert(jsonResponse.metadata.error_message);

                        return;
                    }

                    alert(
                        "Something went wrong. Please contact customer service.",
                    );

                    return;
                }

                location.reload();
            })
            .catch((error) => {
                alert(error);
            });
    });
});
