document.addEventListener("DOMContentLoaded", () => {
    const profile = document.getElementById("profile");

    if (!profile) {
        return;
    }

    const toggleVideo = profile.querySelector(".toggle-video");

    if (toggleVideo) {
        const iconPlay = toggleVideo.querySelector(".icon-play");
        const iconPause = toggleVideo.querySelector(".icon-pause");

        const profileVideo = profile.querySelector(".profile-video");

        toggleVideo.addEventListener("click", () => {
            if (profileVideo.paused) {
                profileVideo.play();

                iconPlay.style.display = "none";
                iconPause.style.display = "block";

                profileVideo.scrollIntoView({block: "center"});
            } else {
                profileVideo.pause();

                iconPlay.style.display = "block";
                iconPause.style.display = "none";
            }

            profileVideo.onended = function () {
                iconPlay.style.display = "block";
                iconPause.style.display = "none";
            };
        });
    }

    const profilePhoto = profile.querySelector(".header-profile .profile-photo");
    const userBar = profile.querySelector(".user-bar");

    const observer = new IntersectionObserver(
        ([e]) => userBar.classList.toggle("active", e.intersectionRatio === 0)
    );

    observer.observe(profilePhoto);

});
