import { Controller } from "@hotwired/stimulus";
import { showElement, hideElement } from "./utils";

export default class extends Controller {
    static values = { userId: Number };
    static targets = ["loader", "apiError"];

    goToPage(event) {
        event.preventDefault();

        const offset = event.target.dataset.offset;

        showElement(this.loaderTarget);

        fetch(
            `/website-api/user/${this.userIdValue}/references/?offset=${offset}`,
            {
                credentials: "same-origin",
                method: "get",
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw response;
                }
                return response.text();
            })
            .then((html) => {
                this.element.outerHTML = html;

                hideElement(this.loaderTarget);

                this.goToTopRef();
            })
            .catch((error) => {
                showElement(this.apiErrorTarget);
                hideElement(this.loaderTarget);
                this.goToTopRef();
            });
    }

    goToTopRef() {
        const rect = document
            .getElementById("references-wrapper")
            .getBoundingClientRect(); // getting the getBoundingClientRect of a target does not give the right values, thus addressing this by getElementById
        const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
        const scrollToOffset = rect.top + scrollTop - 70;

        window.scrollTo(0, scrollToOffset);
    }
}
