import maplibregl from "maplibre-gl";

document.addEventListener("DOMContentLoaded", () => {
    const mapProfile = document.getElementById("map-profile");

    if (!mapProfile) {
        return;
    }

    maplibregl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        true,
    );

    const setMarkerForUserLocation = function (map) {
        /* Set user home address on the map */
        const lat = mapProfile.dataset.forUserLat.trim();
        const lng = mapProfile.dataset.forUserLng.trim();

        if (!lng || !lat) {
            return;
        }

        // create a DOM element for the marker
        var markerLocation = document.createElement("div");
        markerLocation.className = "marker-for-user-location";
        markerLocation.style.backgroundImage =
            "url(https://cdn.babysits.com/global/icons/maps/home.svg)";
        markerLocation.style.backgroundSize = "cover";
        markerLocation.style.width = 36 + "px";
        markerLocation.style.height = 36 + "px";
        markerLocation.style.zIndex = 1;

        new maplibregl.Marker({ element: markerLocation })
            .setLngLat([lng, lat])
            .addTo(map);
    };

    var initialize_map_profile = function () {
        const spinner = mapProfile.querySelector(".spinner");
        spinner.style.display = "block";

        /* Bots can affecting the mapbox billing by 60%. So we do not load the map for these bots anymore. */
        const listOfBots = [""];

        if (listOfBots.includes(navigator.userAgent)) {
            return;
        }

        const userPublicLat = parseFloat(
            mapProfile.dataset.userPublicLat.trim(),
        );
        const userPublicLng = parseFloat(
            mapProfile.dataset.userPublicLng.trim(),
        );

        const mapStyleLight = "a0930824-77bc-4c8b-a44d-a3860938876b";
        const mapStyleDark = "1342944f-2c70-4bca-83ff-f67e5c03409a";

        let mediaQueryObj = window.matchMedia("(prefers-color-scheme: dark)");
        let isDarkMode = mediaQueryObj.matches;
        let activeMode = isDarkMode ? "dark" : "light";

        function getStyleByMode(mode) {
            return mode === "dark" ? mapStyleDark : mapStyleLight;
        }

        var map = new maplibregl.Map({
            container: "map-profile",
            style:
                "https://api.maptiler.com/maps/" +
                getStyleByMode(activeMode) +
                "/style.json?key=YJdaaNursTUUjcPnRyA5",
            center: [userPublicLng, userPublicLat],
            zoom: 13,
            scrollZoom: false,
            minZoom: 9,
            maxZoom: 15,
            dragRotate: false,
            touchRotate: false,
        });
        map.touchZoomRotate.disableRotation();

        map.addControl(
            new maplibregl.NavigationControl({
                showCompass: false,
            }),
        );

        map.addControl(
            new maplibregl.GeolocateControl({
                fitBoundsOptions: {
                    maxZoom: 13,
                },
            }),
        );

        map.on("load", function () {
            /* https://stackoverflow.com/questions/37599561/drawing-a-circle-with-the-radius-in-miles-meters-with-mapbox-gl-js */
            var createGeoJSONCircle = function (lat, lng, radiusInKm, points) {
                if (!points) points = 64;

                var coords = {
                    latitude: lat,
                    longitude: lng,
                };

                var km = radiusInKm;

                var ret = [];
                var distanceX =
                    km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
                var distanceY = km / 110.574;

                var theta, x, y;
                for (var i = 0; i < points; i++) {
                    theta = (i / points) * (2 * Math.PI);
                    x = distanceX * Math.cos(theta);
                    y = distanceY * Math.sin(theta);

                    ret.push([coords.longitude + x, coords.latitude + y]);
                }
                ret.push(ret[0]);

                return {
                    type: "geojson",
                    data: {
                        type: "FeatureCollection",
                        features: [
                            {
                                type: "Feature",
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [ret],
                                },
                            },
                        ],
                    },
                };
            };

            /**
             * The radiusinKm depends on the geohash length: https://en.wikipedia.org/wiki/Geohash
             */
            map.addSource(
                "polygon",
                createGeoJSONCircle(userPublicLat, userPublicLng, 0.5),
            );

            map.addLayer({
                id: "polygon",
                type: "fill",
                source: "polygon",
                layout: {},
                paint: {
                    "fill-color": "#59BEC9",
                    "fill-opacity": 0.5,
                    "fill-outline-color": "#389BA7",
                },
            });

            setMarkerForUserLocation(map);

            spinner.style.display = "none";
        });
    };

    function handleIntersect(entries, observer) {
        entries.forEach(function (entry) {
            if (entry.intersectionRatio > 0) {
                initialize_map_profile();
                observer.unobserve(entry.target);
            }
        });
    }

    let observer = new IntersectionObserver(handleIntersect);

    observer.observe(mapProfile);
});
